import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase.jsx';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection, query, where, getDocs, updateDoc, arrayUnion, doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';

const Level2 = () => {
  const navigate = useNavigate();
  const [selectedWord, setSelectedWord] = useState(null);
  const [displayedWord, setDisplayedWord] = useState('');
  const [correctLetters, setCorrectLetters] = useState([]);
  const [wrongLetters, setWrongLetters] = useState([]);
  const [gameActive, setGameActive] = useState(true);
  const [pointsAlreadyAdded, setPointsAlreadyAdded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [endMessage, setEndMessage] = useState('');
  const [notification, setNotification] = useState('');
  const [gameInstruction, setGameInstruction] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);

  const [virtualKeyboard, setVirtualKeyboard] = useState([
    ['a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm'],
    ['w', 'x', 'c', 'v', 'b', 'n']
  ]);
  const [language, setLanguage] = useState('fr');

  // Références SVG
  const sailRef = useRef(null);
  const mastRef = useRef(null);
  const hullRef = useRef(null);
  const flag1Ref = useRef(null);
  const flag2Ref = useRef(null);
  const window1Ref = useRef(null);
  const window2Ref = useRef(null);
  const window3Ref = useRef(null);
  const cannonballRef = useRef(null);
  const gameContainerRef = useRef(null);

  const words = ["Sian Ka'an Bak'halal"];

  // Translations...
  const translations = {
    fr: {
      levelNumber: 'NIVEAU 002',
      missionTitle: 'Jeu du voilier qui coule',
      gameInstruction: 'Appuyez sur une lettre pour jouer !',
      congratulations: 'Félicitations ! Vous gagnez 100 points !',
      sunk: 'Oups! Le voilier a coulé.',
      alreadyUsedLetter: 'Vous avez déjà utilisé cette lettre !',
      replay: 'Rejouer',
      userNotFound: "Aucun document d'équipe trouvé pour l'utilisateur.",
      errorOccurred: 'Une erreur est survenue. Veuillez réessayer.',
      userNotLoggedIn: 'Utilisateur non connecté.',
      alreadyCompleted: 'Vous avez déjà complété ce niveau. Les points ne seront pas ajoutés à nouveau.',
      successMessage: 'Félicitations ! Vous avez réussi et votre équipe gagne 100 points supplémentaires. Vous allez maintenant être redirigé vers le prochain niveau...',
      alreadyCompletedRedirect: 'Vous avez déjà complété ce niveau. Redirection vers le niveau suivant...',
    },
    en: {
      levelNumber: 'LEVEL 002',
      missionTitle: 'Sinking Sailboat Game',
      gameInstruction: 'Press a letter to play!',
      congratulations: 'Congratulations! You earn 100 points!',
      sunk: 'Oops! The sailboat sank.',
      alreadyUsedLetter: 'You have already used this letter!',
      replay: 'Replay',
      userNotFound: 'No team document found for the user.',
      errorOccurred: 'An error occurred. Please try again.',
      userNotLoggedIn: 'User not logged in.',
      alreadyCompleted: 'You have already completed this level. Points will not be added again.',
      successMessage: 'Congratulations! You succeeded, and your team earns 100 extra points. You will now be redirected to the next level...',
      alreadyCompletedRedirect: 'You have already completed this level. Redirecting to the next level...',
    },
    es: {
      levelNumber: 'NIVEL 002',
      missionTitle: 'Lucha entre Españoles y Piratas',
      gameInstruction: '¡Presiona una letra para jugar!',
      congratulations: '¡Felicidades! ¡Ganas 100 puntos!',
      sunk: '¡Ups! El velero se hundió.',
      alreadyUsedLetter: '¡Ya has usado esta letra!',
      replay: 'Reintentar',
      userNotFound: 'No se encontró ningún documento del equipo para el usuario.',
      errorOccurred: 'Ocurrió un error. Por favor, inténtalo de nuevo.',
      userNotLoggedIn: 'Usuario no conectado.',
      alreadyCompleted: 'Ya has completado este nivel. Los puntos no se sumarán de nuevo.',
      successMessage: '¡Felicidades! Lo lograste y tu equipo gana 100 puntos adicionales. Ahora serás redirigido al siguiente nivel...',
      alreadyCompletedRedirect: 'Ya has completado este nivel. Redirigiendo al siguiente nivel...',
    },
  };

  // Initialisation
  useEffect(() => {
    const word = words[0].toLowerCase().replace(/'/g, '');
    setSelectedWord(word);
    
    const handleAuth = async (user) => {
      if (user) {
        setCurrentUser(user);
        const userId = user.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();
          setLanguage(teamData.language || 'fr');
          setGameInstruction(translations[teamData.language || 'fr'].gameInstruction);
        }
      } else {
        setCurrentUser(null);
        setGameInstruction(translations[language].userNotLoggedIn);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuth);
    return () => unsubscribe();
  }, []);

  // Gestion mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll sur mobile
  useEffect(() => {
    if (isMobile && gameContainerRef.current) {
      const gameContainer = gameContainerRef.current;
      gameContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isMobile]);

  // Suivi du progrès du jeu
  useEffect(() => {
    if (selectedWord) {
      const innerWord = selectedWord
        .split('')
        .map((letter) =>
          correctLetters.includes(letter) || letter === ' ' || letter === "'" ? letter : '_'
        )
        .join('');

      setDisplayedWord(innerWord);

      if (innerWord === selectedWord) {
        setEndMessage(translations[language].congratulations);
        setGameInstruction('');
        setGameActive(false);

        if (!pointsAlreadyAdded) {
          updateTeamPointsAndLevel();
        }

        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
        setShowContinueButton(true); // Affiche le bouton "Continuer"

      }
    }
  }, [selectedWord, correctLetters]);

  const showNotification = () => {
    setNotification(translations[language].alreadyUsedLetter);
    setTimeout(() => {
      setNotification('');
    }, 2000);
  };

  useEffect(() => {
    if (wrongLetters.length > 0) {
      animateCannonball(wrongLetters.length);
      
      if (wrongLetters.length === 6) {
        setGameActive(false);
        setEndMessage(translations[language].sunk);
        setGameInstruction('');
      }
    }
  }, [wrongLetters, language]);
  
  useEffect(() => {
    setGameInstruction(translations[language].gameInstruction);
  }, [language]);

  // Handler pour le clavier virtuel
  const handleVirtualKeyPress = (letter) => {
    if (!gameActive) return;
    
    if (selectedWord.includes(letter)) {
      if (!correctLetters.includes(letter)) {
        setCorrectLetters(prev => [...prev, letter]);
      } else {
        showNotification();
      }
    } else {
      if (!wrongLetters.includes(letter)) {
        setWrongLetters(prev => [...prev, letter]);
      } else {
        showNotification();
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isMobile) {
        const letter = e.key.toLowerCase();
        if (gameActive && /^[a-zàâçéèêëîïôûùüÿñæœ]$/.test(letter)) {
          if (selectedWord.includes(letter)) {
            if (!correctLetters.includes(letter)) {
              setCorrectLetters(prev => [...prev, letter]);
            } else {
              showNotification();
            }
          } else {
            if (!wrongLetters.includes(letter)) {
              setWrongLetters(prev => [...prev, letter]);
            } else {
              showNotification();
            }
          }
        }
      }
    };
  
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [gameActive, selectedWord, correctLetters, wrongLetters, isMobile]);

  const updateTeamPointsAndLevel = async () => {
    if (currentUser) {
      try {
        const userId = currentUser.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();

          if (teamData.usedLevels && teamData.usedLevels.includes('level2')) {
            setPointsAlreadyAdded(true);
            setEndMessage(translations[language].alreadyCompleted);
            setShowContinueButton(true); // Affiche le bouton même si déjà complété
            
          } else {
            let updatedPoints = teamData.points || 0;
            updatedPoints += 100;

            const teamDocRef = doc(db, 'teams', teamDoc.id);
            await updateDoc(teamDocRef, {
              points: updatedPoints,
              usedLevels: arrayUnion('level2'),
            });

            setEndMessage(translations[language].successMessage);
            setShowContinueButton(true); // Affiche le bouton "Continuer"
          }
        } else {
          setEndMessage(translations[language].userNotFound);
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour des points:', error);
        setEndMessage(translations[language].errorOccurred);
      }
    } else {
      setEndMessage(translations[language].userNotLoggedIn);
    }
  };

  const resetGame = () => {
    setCorrectLetters([]);
    setWrongLetters([]);
    setGameActive(true);
    setEndMessage('');
    setGameInstruction(translations[language].gameInstruction);
    setShowConfetti(false);

    const resetTransform = 'translate(0, 0) rotate(0deg)';
    if (sailRef.current) sailRef.current.style.transform = resetTransform;
    if (mastRef.current) mastRef.current.style.transform = resetTransform;
    if (hullRef.current) hullRef.current.style.transform = resetTransform;
    if (flag1Ref.current) flag1Ref.current.style.transform = resetTransform;
    if (flag2Ref.current) flag2Ref.current.style.transform = resetTransform;
    if (window1Ref.current) window1Ref.current.style.transform = resetTransform;
    if (window2Ref.current) window2Ref.current.style.transform = resetTransform;
    if (window3Ref.current) window3Ref.current.style.transform = resetTransform;

    const word = words[0].toLowerCase().replace(/'/g, '');
    setSelectedWord(word);
  };

  const animateCannonball = (errors) => {
    if (cannonballRef.current) {
      cannonballRef.current.style.display = 'block';
      cannonballRef.current.style.transition = 'none';
      cannonballRef.current.style.transform = 'translate(0px, 0px)';
      cannonballRef.current.style.opacity = '1';

      setTimeout(() => {
        cannonballRef.current.style.transition =
          'transform 1.5s ease-in-out, opacity 1.5s ease-in-out';
        cannonballRef.current.style.transform = 'translate(220px, 40px)';
        cannonballRef.current.style.opacity = '0';

        setTimeout(() => {
          const sinkingAmount = errors * 10;
          const rotationAngle = errors * 3;
          const transformValue = `translate(0, ${sinkingAmount}px) rotate(${rotationAngle}deg)`;

          if (sailRef.current) sailRef.current.style.transform = transformValue;
          if (mastRef.current) mastRef.current.style.transform = transformValue;
          if (hullRef.current) hullRef.current.style.transform = transformValue;
          if (flag1Ref.current) flag1Ref.current.style.transform = transformValue;
          if (flag2Ref.current) flag2Ref.current.style.transform = transformValue;
          if (window1Ref.current) window1Ref.current.style.transform = transformValue;
          if (window2Ref.current) window2Ref.current.style.transform = transformValue;
          if (window3Ref.current) window3Ref.current.style.transform = transformValue;
        }, 1500);
      }, 100);
    }
  };

  return (
    <div className="min-h-screen p-4 bg-[#0e1d35]" ref={gameContainerRef}>
      <div className="max-w-2xl mx-auto">
        {/* Header */}
        <div className="relative mb-8">
          <h1 className="text-6xl md:text-8xl font-black text-center mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
            {translations[language].levelNumber}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        {/* Title */}
        <h2 className="text-2xl font-bold text-center mb-8 relative">
          <span className="bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
            {translations[language].missionTitle}
          </span>
          <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full"></div>
        </h2>

        {/* Instructions */}
<p className="mb-8 text-center text-white text-xl">
  {gameInstruction}
</p>

        {/* Game Container */}
        <div className="bg-[#1a2437]/60 rounded-xl p-4 backdrop-blur-sm border border-white/10">
          {/* Word Display */}
          <div className="text-4xl md:text-5xl text-center font-mono tracking-wider mb-6 text-white">
            {displayedWord.split('').map((letter, index) => (
              <span key={index} className="mx-1">
                {letter}
              </span>
            ))}
          </div>

          {/* Wrong Letters */}
          {wrongLetters.length > 0 && (
            <div className="text-red-400 text-center mb-4">
              {language === 'fr' && 'Lettres utilisées : '}
              {language === 'en' && 'Used letters: '}
              {language === 'es' && 'Letras usadas: '}
              {wrongLetters.join(', ')}
            </div>
          )}

          {/* Game SVG - Ajusté pour être plus responsive */}
          <div className="w-full max-w-md mx-auto overflow-hidden">
            <svg viewBox="0 0 500 400" className="w-full h-auto">
              {/* Fort */}
              <rect x="20" y="100" width="75" height="62.5" fill="#e9c46a" stroke="#000" strokeWidth="2" />
              <rect x="6" y="65" width="25" height="25" fill="#e9c46a" stroke="#000" strokeWidth="2" />
              <rect x="48" y="65" width="25" height="25" fill="#e9c46a" stroke="#000" strokeWidth="2" />
              <rect x="68" y="115" width="25" height="12.5" fill="#000" stroke="#000" strokeWidth="2" />
              
              {/* Cannonball */}
              <circle cx="80" cy="120" r="5" fill="red" ref={cannonballRef} style={{display: 'none'}} />
              
              {/* Waves */}
              <path d="M220 160 Q280 140 340 160 T460 160" stroke="#00CED1" strokeWidth="2" fill="none" />
              <path d="M220 170 Q280 150 340 170 T460 170" stroke="#48D1CC" strokeWidth="2" fill="none" />
              <path d="M220 180 Q280 160 340 180 T460 180" stroke="#AFEEEE" strokeWidth="2" fill="none" />
              
              {/* Boat */}
              <g ref={hullRef}>
                <polygon points="250,120 350,120 300,160" stroke="#000" strokeWidth="2" fill="white" />
              </g>
              <g ref={mastRef}>
                <line x1="300" y1="40" x2="300" y2="120" stroke="#000" strokeWidth="2" />
              </g>
              <g ref={sailRef}>
                <polygon points="300,40 330,100 270,100" stroke="#000" strokeWidth="2" fill="white" />
              </g>
              
              {/* Flags and Windows */}
              <g ref={flag1Ref}>
                <polygon points="300,40 315,25 300,25" fill="#FFD700" />
              </g>
              <g ref={flag2Ref}>
                <polygon points="300,40 315,20 300,20" fill="#48D1CC" />
              </g>
              <g ref={window1Ref}>
                <circle cx="280" cy="130" r="5" stroke="#000" strokeWidth="2" fill="white" />
              </g>
              <g ref={window2Ref}>
                <circle cx="300" cy="130" r="5" stroke="#000" strokeWidth="2" fill="white" />
              </g>
              <g ref={window3Ref}>
                <circle cx="320" cy="130" r="5" stroke="#000" strokeWidth="2" fill="white" />
              </g>
            </svg>
          </div>

          {/* Virtual Keyboard for Mobile */}
          {isMobile && gameActive && (
            <div className="mt-6 space-y-2">
              {virtualKeyboard.map((row, rowIndex) => (
                <div key={rowIndex} className="flex justify-center gap-1">
                  {row.map((letter) => (
                    <button
                      key={letter}
                      onClick={() => handleVirtualKeyPress(letter)}
                      disabled={correctLetters.includes(letter) || wrongLetters.includes(letter)}
                      className={`w-8 h-8 rounded-lg font-bold text-sm
                        ${correctLetters.includes(letter) 
                          ? 'bg-green-500 text-white' 
                          : wrongLetters.includes(letter)
                            ? 'bg-red-500 text-white'
                            : 'bg-blue-400 hover:bg-blue-500 text-white'}
                        disabled:opacity-50 transition-colors duration-200`}
                    >
                      {letter}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          )}

          {/* Messages */}
          {notification && (
            <div className="mt-4 p-3 bg-red-500/20 text-red-300 rounded-lg text-center">
              {notification}
            </div>
          )}

          {endMessage && (
            <div className="mt-4 text-center">
              <p className="text-emerald-400 text-xl mb-4">{endMessage}</p>
{showContinueButton && (
  <button
    onClick={() => navigate('/niveau/3')}
    className="mt-4 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
  >
    Continuer
  </button>
)}

{!gameActive && wrongLetters.length === 6 && (
  <button
    onClick={resetGame}
    className="px-6 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors duration-200"
  >
    {translations[language].replay}
  </button>
)}

            </div>
          )}
        </div>
      </div>

      {/* Confetti Effect */}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
        />
      )}
    </div>
  );
};

export default Level2;