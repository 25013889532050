import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Level9 = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [language, setLanguage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [showDates, setShowDates] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const pointsToAdd = 500;
  const levelIdentifier = 'level9';
  const redirectUrl = '/niveau/10';
  const maxAttempts = 3;

  const historicalData = {
    fr: [
      { id: '1', year: 415, event: "Fondation de la ville maya de Sian Ka'an" },
      { id: '2', year: 1543, event: 'Première arrivée des conquistadors espagnols' },
      { id: '3', year: 1733, event: 'Construction du Fort San Felipe' },
      { id: '4', year: 1848, event: 'Pendant la Guerre des Castes du Yucatán, Bacalar est attaquée et rasée par les Mayas rebelles.' },
      { id: '5', year: 1902, event: "l'armée mexicaine reconquiert Bacalar" }
    ],
    en: [
      { id: '1', year: 415, event: "Foundation of the Mayan city of Sian Ka'an" },
      { id: '2', year: 1543, event: 'First arrival of Spanish conquistadors' },
      { id: '3', year: 1733, event: 'Construction of Fort San Felipe' },
      { id: '4', year: 1848, event: 'During the Caste War of Yucatán, Bacalar was attacked and razed by rebellious Mayas.' },
      { id: '5', year: 1902, event: 'Mexican army reconquers Bacalar' }
    ],
    es: [
      { id: '1', year: 415, event: "Fundación de la ciudad maya de Sian Ka'an" },
      { id: '2', year: 1543, event: 'Primera llegada de los conquistadores españoles' },
      { id: '3', year: 1733, event: 'Construcción del Fuerte San Felipe' },
      { id: '4', year: 1848, event: 'Durante la Guerra de Castas de Yucatán, Bacalar fue atacada y arrasada por los mayas rebeldes.' },
      { id: '5', year: 1902, event: 'El ejército mexicano reconquista Bacalar' }
    ]
  };

  const translations = {
    fr: {
      nextLevel: 'Niveau suivant',
      levelNumber: 'NIVEAU 009',
      title: 'Chronologie de Bacalar',
      instruction: 'Placez ces événements dans l\'ordre chronologique, du plus ancien au plus récent. Les dates seront révélées une fois le bon ordre trouvé !',
      verify: 'Vérifier l\'ordre',
      success: 'Félicitations ! Vous avez trouvé le bon ordre ! Voici les dates de ces événements.',
      error: 'Ce n\'est pas le bon ordre. Essayez encore !',
      attemptsLeft: 'Tentatives restantes :',
      loading: 'Chargement...',
      resetAttempts: 'Trop d\'essais... Les événements ont été mélangés à nouveau.'
    },
    en: {
      nextLevel: 'Next Level',
      levelNumber: 'LEVEL 009',
      title: 'Bacalar Timeline',
      instruction: 'Place these events in chronological order, from oldest to most recent. Dates will be revealed once you find the correct order!',
      verify: 'Check order',
      success: 'Congratulations! You found the correct order! Here are the dates of these events.',
      error: 'That\'s not the right order. Try again!',
      attemptsLeft: 'Attempts left:',
      loading: 'Loading...',
      resetAttempts: 'Too many attempts... Events have been reshuffled.'
    },
    es: {
      nextLevel: 'Siguiente Nivel',
      levelNumber: 'NIVEL 009',
      title: 'Cronología de Bacalar',
      instruction: '¡Coloca estos eventos en orden cronológico, del más antiguo al más reciente. Las fechas se revelarán una vez que encuentres el orden correcto!',
      verify: 'Verificar orden',
      success: '¡Felicitaciones! ¡Encontraste el orden correcto! Aquí están las fechas de estos eventos.',
      error: 'Ese no es el orden correcto. ¡Inténtalo de nuevo!',
      attemptsLeft: 'Intentos restantes:',
      loading: 'Cargando...',
      resetAttempts: 'Demasiados intentos... Los eventos se han mezclado de nuevo.'
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();
          setLanguage(teamData.language || 'fr');
          initializeEvents(teamData.language || 'fr');
        } else {
          setLanguage('fr');
          initializeEvents('fr');
        }
      } else {
        setLanguage('fr');
        initializeEvents('fr');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const initializeEvents = (lang) => {
    const shuffledEvents = [...historicalData[lang]]
      .sort(() => Math.random() - 0.5)
      .map((event, index) => ({
        ...event,
        currentPosition: index,
        correctPosition: historicalData[lang].findIndex(e => e.id === event.id)
      }));
    setEvents(shuffledEvents);
    setShowDates(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(events);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setEvents(items.map((item, index) => ({
      ...item,
      currentPosition: index
    })));
  };

  const verifyOrder = async () => {
    const isCorrect = events.every(event => 
      event.currentPosition === event.correctPosition
    );

    if (isCorrect) {
      setShowConfetti(true);
      setShowDates(true);
      setShowNextButton(true);
      setSuccess(translations[language].success);
      await updateTeamPoints();
    } else {
      setError(translations[language].error);
      setAttempts(prev => prev + 1);
      if (attempts + 1 >= maxAttempts) {
        setError(translations[language].resetAttempts);
        initializeEvents(language);
        setAttempts(0);
      }
    }
  };

  const updateTeamPoints = async () => {
    if (!currentUser) return;

    try {
      const teamsCollection = collection(db, 'teams');
      const q = query(teamsCollection, where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const teamDoc = querySnapshot.docs[0];
        const teamData = teamDoc.data();

        if (teamData.usedLevels?.includes(levelIdentifier)) {
          return;
        }

        const teamRef = doc(db, 'teams', teamDoc.id);
        await updateDoc(teamRef, {
          points: (teamData.points || 0) + pointsToAdd,
          usedLevels: arrayUnion(levelIdentifier)
        });
      }
    } catch (err) {
      console.error('Erreur:', err);
      setError(translations[language].error);
    }
  };

  if (loading || !language) {
    return (
      <div className="min-h-screen p-4 bg-[#0e1d35] flex items-center justify-center">
        <div className="text-white">{translations[language]?.loading || 'Chargement...'}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 bg-[#0e1d35]">
      <div className="max-w-4xl mx-auto">
        <div className="relative mb-8">
          <h1 className="text-6xl md:text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
            {translations[language].levelNumber}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-2xl text-white mb-4">{translations[language].title}</h2>
          <p className="text-white/70 mb-4">{translations[language].instruction}</p>
          <p className="text-white/70">
            {translations[language].attemptsLeft} {maxAttempts - attempts}
          </p>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="events">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-4"
              >
                {events.map((event, index) => (
                  <Draggable key={event.id} draggableId={event.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="bg-[#1a2437]/60 p-6 rounded-xl border border-white/10 backdrop-blur-sm hover:bg-[#1a2437] transition-colors duration-200"
                      >
                        <div className="flex justify-between items-center">
                          {showDates && (
                            <span className="text-emerald-400 font-bold mr-4">{event.year}</span>
                          )}
                          <p className="text-white/90 flex-1">{event.event}</p>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="mt-8 text-center">
          <button
            onClick={verifyOrder}
            className="px-8 py-4 bg-blue-400 text-white rounded-lg hover:bg-emerald-400 transition-colors duration-300"
          >
            {translations[language].verify}
          </button>
        </div>

        {(success || error) && (
          <div className={`mt-4 text-center p-4 rounded-lg ${
            success ? 'bg-emerald-500/20 text-emerald-300' : 'bg-red-500/20 text-red-300'
          }`}>
            {success || error}
          </div>
        )}

        {showNextButton && (
          <div className="mt-4 text-center">
            <button
              onClick={() => navigate(redirectUrl)}
              className="px-8 py-4 bg-emerald-400 text-white rounded-lg 
                        hover:bg-emerald-500 transition-colors duration-300
                        transform hover:scale-105"
            >
              {translations[language].nextLevel}
            </button>
          </div>
        )}
      </div>

      <div className="fixed inset-0 pointer-events-none" style={{ zIndex: 50 }}>
        {showConfetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={200}
          />
        )}
      </div>
    </div>
  );
};

export default Level9;