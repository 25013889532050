import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';

import ConquistadorImage from '../assets/conquistador.jpg';

const Level7 = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState(null); // Initialisé à null pour gérer l'état de chargement
  const [currentUser, setCurrentUser] = useState(null);

  const translations = {
    fr: {
      levelNumber: 'NIVEAU 007',
      missionTitle: 'Casse-tête',
      congratulations: 'Félicitations ! Vous avez complété le puzzle en',
      timeElapsed: 'Temps écoulé :',
      testButton: 'Compléter le Puzzle',
      alreadyCompleted:
        'Vous avez déjà complété ce niveau. Les points ne seront pas ajoutés à nouveau.',
      pointsEarned: (points) => `Félicitations ! Vous avez gagné ${points} points.`,
      userNotLoggedIn: 'Utilisateur non connecté.',
      replayInfo:
        'Vous pouvez rejouer autant de fois que vous le souhaitez, mais les points ne seront comptabilisés qu\'une seule fois.',
      continueButton: 'Continuer'
    },
    en: {
      levelNumber: 'LEVEL 007',
      missionTitle: 'Puzzle',
      congratulations: 'Congratulations! You completed the puzzle in',
      timeElapsed: 'Time elapsed:',
      testButton: 'Complete the Puzzle',
      alreadyCompleted:
        'You have already completed this level. Points will not be added again.',
      pointsEarned: (points) => `Congratulations! You have earned ${points} points.`,
      userNotLoggedIn: 'User not logged in.',
      replayInfo:
        'You can replay as many times as you want, but points will only be awarded once.',
      continueButton: 'Continue'
    },
    es: {
      levelNumber: 'NIVEL 007',
      missionTitle: 'Rompecabezas',
      congratulations: '¡Felicidades! Completaste el rompecabezas en',
      timeElapsed: 'Tiempo transcurrido:',
      testButton: 'Completar el Rompecabezas',
      alreadyCompleted:
        'Ya has completado este nivel. Los puntos no se agregarán de nuevo.',
      pointsEarned: (points) => `¡Felicidades! Has ganado ${points} puntos.`,
      userNotLoggedIn: 'Usuario no conectado.',
      replayInfo:
        'Puedes jugar tantas veces como quieras, pero los puntos solo se contarán una vez.',
      continueButton: 'Continuar'
    },
  };

  const levelToAdd = 'level7';
  const timeLimit = 10 * 60 * 1000;
  const pointsQuick = 1000;
  const pointsRegular = 500;
  const redirectUrl = '/niveau/8';

  const [message, setMessage] = useState('');
  const [pieces, setPieces] = useState([]);
  const [emptyIndex, setEmptyIndex] = useState(15);
  const [startTime, setStartTime] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState('0:00');
  const [gameCompleted, setGameCompleted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setLoading] = useState(true); // État de chargement

  const size = 4;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const teamsCollection = collection(db, 'teams');
          const q = query(teamsCollection, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const teamsDoc = querySnapshot.docs[0];
            const teamsData = teamsDoc.data();
            let userLanguage = teamsData.language || 'fr';

            // Si la langue récupérée n'est pas supportée, utilisez 'fr' par défaut
            if (!translations.hasOwnProperty(userLanguage)) {
              console.warn(`Langue non supportée: ${userLanguage}. Utilisation de 'fr' par défaut.`);
              userLanguage = 'fr';
            }

            setLanguage(userLanguage);
          } else {
            console.warn('Aucun document trouvé dans la collection teams pour cet utilisateur.');
            setLanguage('fr');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de la langue :', error);
          setLanguage('fr');
        }
      } else {
        setCurrentUser(null);
        setLanguage('fr');
      }
      setLoading(false); // Fin du chargement
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (language) {
      initializePuzzle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    let timerInterval = null;
    if (startTime) {
      timerInterval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const minutes = Math.floor(elapsedTime / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        setTimeElapsed(`${minutes}:${seconds.toString().padStart(2, '0')}`);
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [startTime]);

  const initializePuzzle = () => {
    const tempPieces = [];
    for (let i = 0; i < size * size; i++) {
      const piece = {
        index: i,
        backgroundPosition: `${(i % size) * 100 / (size - 1)}% ${Math.floor(i / size) * 100 / (size - 1)}%`,
        hidden: false,
      };
      tempPieces.push(piece);
    }

    const shuffledPieces = shuffleArray(tempPieces.slice(0, -1));
    shuffledPieces.push({ ...tempPieces[tempPieces.length - 1], hidden: true });
    setPieces(shuffledPieces);
    setEmptyIndex(tempPieces.length - 1);
    setStartTime(null);
    setTimeElapsed('0:00');
    setGameCompleted(false);
    setShowConfetti(false);
    setMessage('');
  };

  const shuffleArray = (array) => {
    const arr = array.slice();
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  const isValidMove = (emptyIdx, targetIdx) => {
    const emptyRow = Math.floor(emptyIdx / size);
    const emptyCol = emptyIdx % size;
    const targetRow = Math.floor(targetIdx / size);
    const targetCol = targetIdx % size;

    return (
      (emptyRow === targetRow && Math.abs(emptyCol - targetCol) === 1) ||
      (emptyCol === targetCol && Math.abs(emptyRow - targetRow) === 1)
    );
  };

  const handlePieceClick = (index) => {
    if (!startTime) {
      setStartTime(Date.now());
    }

    if (isValidMove(emptyIndex, index)) {
      const newPieces = pieces.slice();
      [newPieces[emptyIndex], newPieces[index]] = [newPieces[index], newPieces[emptyIndex]];
      setPieces(newPieces);
      setEmptyIndex(index);

      if (isPuzzleComplete(newPieces)) {
        setGameCompleted(true);
        const completionTime = Date.now(); // Capture le temps avant de réinitialiser
        setStartTime(null);
        setMessage(`${translations[language].congratulations} ${timeElapsed}.`);
        setShowConfetti(true);
        updatePointsAndLevel(completionTime - (Date.now() - startTime));
      }
    }
  };

  const isPuzzleComplete = (piecesArray) => {
    return piecesArray.every((piece, idx) => piece.index === idx);
  };

  const handleTestButton = () => {
    const tempPieces = [];
    for (let i = 0; i < size * size; i++) {
      const piece = {
        index: i,
        backgroundPosition: `${(i % size) * 100 / (size - 1)}% ${Math.floor(i / size) * 100 / (size - 1)}%`,
        hidden: false,
      };
      tempPieces.push(piece);
    }
    tempPieces[tempPieces.length - 1].hidden = true;
    setPieces(tempPieces);
    setEmptyIndex(tempPieces.length - 1);
    setGameCompleted(true);
    const completionTime = Date.now(); 
    setStartTime(null);
    setMessage(`${translations[language].congratulations} 0:00.`);
    setShowConfetti(true);
    updatePointsAndLevel(0); 
  };

  const updatePointsAndLevel = async (elapsedTime = Date.now() - startTime) => {
    if (currentUser) {
      try {
        const userId = currentUser.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamsDoc = querySnapshot.docs[0];
          const teamData = teamsDoc.data();

          if (teamData.usedLevels && teamData.usedLevels.includes(levelToAdd)) {
            setMessage(translations[language].alreadyCompleted);
          } else {
            let updatedPoints = teamData.points || 0;
            const pointsToAdd = elapsedTime <= timeLimit ? pointsQuick : pointsRegular;
            updatedPoints += pointsToAdd;

            const teamDocRef = doc(db, 'teams', teamsDoc.id);

            await updateDoc(teamDocRef, {
              points: updatedPoints,
              usedLevels: arrayUnion(levelToAdd),
            });

            setMessage(translations[language].pointsEarned(pointsToAdd));
          }
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour des points:', error);
      }
    } else {
      setMessage(translations[language].userNotLoggedIn);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white">Chargement...</div>
      </div>
    );
  }

  const showContinueButton =
    gameCompleted ||
    message.includes(translations[language].alreadyCompleted) ||
    message.includes(translations[language].userNotLoggedIn) ||
    message.includes(translations[language].pointsEarned(''));

  return (
    <div className="max-w-2xl mx-auto">
      <div className="relative">
        <h1 className="text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
          {translations[language].levelNumber}
        </h1>
        <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
      </div>
      <h2 className="text-2xl font-bold text-center mb-12 relative">
        <span className="bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
          {translations[language].missionTitle}
        </span>
        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full"></div>
      </h2>

      <div className="puzzle-container-wrapper flex flex-col items-center">
        <div
          className="puzzle-container grid gap-0.5"
          style={{
            width: '400px',
            height: '400px',
            gridTemplateColumns: `repeat(${size}, 1fr)`,
            gridTemplateRows: `repeat(${size}, 1fr)`,
          }}
        >
          {pieces.map((piece, index) => (
            <div
              key={index}
              className={`puzzle-piece ${piece.hidden ? 'hidden-piece' : ''}`}
              onClick={() => handlePieceClick(index)}
              style={{
                width: '100%',
                height: '100%',
                backgroundSize: '400px 400px',
                backgroundImage: piece.hidden ? 'none' : `url(${ConquistadorImage})`,
                backgroundPosition: piece.backgroundPosition,
                cursor: piece.hidden ? 'default' : 'pointer',
                transition: 'all 0.3s ease',
              }}
            ></div>
          ))}
        </div>
        {message && (
          <div className="message mt-5 text-center">
            <span className="text-green-500">{message}</span>
          </div>
        )}
        <div className="replay-info mt-3 text-white text-center">
          {translations[language].replayInfo}
        </div>
        <div className="timer mt-3 text-white">
          {translations[language].timeElapsed} {timeElapsed}
        </div>
        {!gameCompleted && (
          <button
            className="test-button mt-5 px-6 py-2 bg-[#0e1d35] text-white rounded-lg hover:bg-[#1a2d50]"
            onClick={handleTestButton}
          >
            {translations[language].testButton}
          </button>
        )}

        {/* Bouton Continuer, affiché si le puzzle est terminé ou déjà complété */}
        {showContinueButton && (
          <button
            className="continue-button mt-5 px-6 py-2 bg-[#0e1d35] text-white rounded-lg hover:bg-[#1a2d50]"
            onClick={() => navigate(redirectUrl)}
          >
            {translations[language].continueButton}
          </button>
        )}
      </div>

      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
    </div>
  );
};

export default Level7;
