import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';

const Level11 = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState('fr');
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [guess, setGuess] = useState('');
  const [currentRow, setCurrentRow] = useState(0);
  const [currentTile, setCurrentTile] = useState(0);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [guesses, setGuesses] = useState([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);

  const pointsToAdd = 700;
  const levelIdentifier = 'level11';
  const redirectUrl = '/niveau/12';

  const words = {
    fr: ["pirates", "mangles", "mexique", "captain", "palapas", "guerres", "cenotes", "jaguars", "voilier", "lagarto"],
    en: ["pirates", "mangle", "mexico", "captain", "palapa", "wars", "cenote", "jaguar", "sailing", "gator"],
    es: ["piratas", "manglar", "mexico", "capitan", "palapa", "guerras", "cenote", "jaguar", "velero", "lagarto"]
  };

  const translations = {
    fr: {
      levelNumber: 'NIVEAU 011',
      title: 'Jeux de mots',
      instruction: 'Trouvez les 10 mots cachés',
      nextWord: 'Prochain mot',
      tryAgain: 'Réessayez',
      success: (count) => `Bravo, vous avez trouvé ${count} mot(s) sur 10. Continuez...`,
      gameComplete: 'Félicitations ! Vous avez trouvé tous les mots !',
      loading: 'Chargement...',
      nextLevel: 'Niveau Suivant'
    },
    en: {
      levelNumber: 'LEVEL 011',
      title: 'Word Game',
      instruction: 'Find the 10 hidden words',
      nextWord: 'Next word',
      tryAgain: 'Try again',
      success: (count) => `Great, you found ${count} word(s) out of 10. Keep going...`,
      gameComplete: 'Congratulations! You found all the words!',
      loading: 'Loading...',
      nextLevel: 'Next Level'
    },
    es: {
      levelNumber: 'NIVEL 011',
      title: 'Juego de palabras',
      instruction: 'Encuentra las 10 palabras ocultas',
      nextWord: 'Siguiente palabra',
      tryAgain: 'Inténtalo de nuevo',
      success: (count) => `¡Excelente, encontraste ${count} palabra(s) de 10. Continúa...`,
      gameComplete: '¡Felicitaciones! ¡Encontraste todas las palabras!',
      loading: 'Cargando...',
      nextLevel: 'Siguiente Nivel'
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const teamsCollection = collection(db, 'teams');
          const q = query(teamsCollection, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const teamDoc = querySnapshot.docs[0];
            const teamData = teamDoc.data();
            setLanguage(teamData.language || 'fr');
          }
        } catch (error) {
          console.error('Error fetching team data:', error);
          setLanguage('fr');
        }
      }
      initializeGuesses();
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const initializeGuesses = () => {
    setGuesses(Array(6).fill().map(() => Array(7).fill({ letter: '', status: '' })));
  };

  const handleKeyPress = (key) => {
    if (gameEnded || currentTile >= 7) return;
    
    const newGuesses = [...guesses];
    newGuesses[currentRow][currentTile] = {
      letter: key,
      status: ''
    };
    
    setGuesses(newGuesses);
    setCurrentTile(currentTile + 1);
    setGuess(guess + key);
  };

  const handleBackspace = () => {
    if (currentTile > 0) {
      const newGuesses = [...guesses];
      newGuesses[currentRow][currentTile - 1] = {
        letter: '',
        status: ''
      };
      
      setGuesses(newGuesses);
      setCurrentTile(currentTile - 1);
      setGuess(guess.slice(0, -1));
    }
  };

  const checkGuess = () => {
    if (guess.length !== 7) return;

    const currentWord = words[language][currentWordIndex];
    const newGuesses = [...guesses];
    let correct = 0;

    // Check each letter
    for (let i = 0; i < 7; i++) {
      if (guess[i] === currentWord[i]) {
        newGuesses[currentRow][i].status = 'correct';
        correct++;
      } else if (currentWord.includes(guess[i])) {
        newGuesses[currentRow][i].status = 'present';
      } else {
        newGuesses[currentRow][i].status = 'absent';
      }
    }

    setGuesses(newGuesses);

    if (correct === 7) {
      handleCorrectGuess();
    } else {
      handleIncorrectGuess();
    }
  };

  const handleCorrectGuess = () => {
    if (currentWordIndex + 1 === words[language].length) {
      setGameEnded(true);
      setShowConfetti(true);
      updateTeamPoints();
    } else {
      setSuccess(translations[language].success(currentWordIndex + 1));
      setCurrentWordIndex(currentWordIndex + 1);
      resetBoard();
    }
  };

  const handleIncorrectGuess = () => {
    if (currentRow < 5) {
      setCurrentRow(currentRow + 1);
      setCurrentTile(0);
      setGuess('');
    } else {
      resetBoard();
    }
  };

  const resetBoard = () => {
    setCurrentRow(0);
    setCurrentTile(0);
    setGuess('');
    initializeGuesses();
  };

  const updateTeamPoints = async () => {
    if (!currentUser) return;

    try {
      const teamsCollection = collection(db, 'teams');
      const q = query(teamsCollection, where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const teamDoc = querySnapshot.docs[0];
        const teamData = teamDoc.data();

        if (teamData.usedLevels?.includes(levelIdentifier)) {
          setShowNextButton(true);
          return;
        }

        const teamRef = doc(db, 'teams', teamDoc.id);
        await updateDoc(teamRef, {
          points: (teamData.points || 0) + pointsToAdd,
          usedLevels: arrayUnion(levelIdentifier)
        });

        setShowNextButton(true);
      }
    } catch (error) {
      console.error('Error updating points:', error);
      setError('Error updating points');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen p-4 bg-[#0e1d35] flex items-center justify-center">
        <div className="text-white">{translations[language]?.loading}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 bg-[#0e1d35]">
      <div className="max-w-4xl mx-auto">
        <div className="relative mb-8">
          <h1 className="text-6xl md:text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
            {translations[language].levelNumber}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-2xl text-white mb-4">{translations[language].title}</h2>
          <p className="text-white/70">{translations[language].instruction}</p>
        </div>

        {/* Word Grid */}
        <div className="grid grid-cols-7 gap-2 mb-8 mx-auto max-w-3xl">
          {guesses.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.map((tile, colIndex) => (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`
                    aspect-square flex items-center justify-center
                    text-2xl font-bold border-2 border-white/20 rounded-lg
                    transition-colors duration-300
                    ${tile.status === 'correct' ? 'bg-emerald-500 text-white' :
                      tile.status === 'present' ? 'bg-yellow-500 text-white' :
                      tile.status === 'absent' ? 'bg-gray-500 text-white' :
                      'bg-[#1a2437]/60'
                    }
                  `}
                >
                  {tile.letter}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>

        {/* Keyboard */}
        <div className="grid grid-cols-10 gap-1 mb-8 mx-auto max-w-3xl">
          {'abcdefghijklmnopqrstuvwxyz'.split('').map((key) => (
            <button
              key={key}
              onClick={() => handleKeyPress(key)}
              className="p-2 bg-blue-400 text-white rounded hover:bg-blue-500 transition-colors"
            >
              {key}
            </button>
          ))}
          <button
            onClick={handleBackspace}
            className="col-span-2 p-2 bg-red-400 text-white rounded hover:bg-red-500 transition-colors"
          >
            ←
          </button>
          <button
            onClick={checkGuess}
            className="col-span-2 p-2 bg-emerald-400 text-white rounded hover:bg-emerald-500 transition-colors"
          >
            ↵
          </button>
        </div>

        {success && (
          <div className="text-center p-4 rounded-lg bg-emerald-500/20 text-emerald-300 mb-4">
            {success}
          </div>
        )}

        {error && (
          <div className="text-center p-4 rounded-lg bg-red-500/20 text-red-300 mb-4">
            {error}
          </div>
        )}

        {showNextButton && (
          <div className="text-center">
            <button
              onClick={() => navigate(redirectUrl)}
              className="px-8 py-4 bg-emerald-400 text-white rounded-lg hover:bg-emerald-500 transition-colors duration-300"
            >
              {translations[language].nextLevel}
            </button>
          </div>
        )}
      </div>

      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
        />
      )}
    </div>
  );
};

export default Level11;