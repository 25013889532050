// src/firebase.jsx

import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Importer le module de stockage

const firebaseConfig = {
  apiKey: 'AIzaSyATaZXj9SP3RvDAoAJnpkV0vuZrnTxmFgk',
  authDomain: 'rallye-bacalar.firebaseapp.com',
  databaseURL: 'https://rallye-bacalar-default-rtdb.firebaseio.com',
  projectId: 'rallye-bacalar',
  storageBucket: 'rallye-bacalar.appspot.com',
  messagingSenderId: '885895977225',
  appId: '1:885895977225:web:1b4d5d37a7fd2717dc1bd8',
  measurementId: 'G-ZG4R0DWWYJ',
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Auth avec persistance de session locale
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

// Initialiser Firestore
const db = getFirestore(app);

// **Initialiser le stockage Firebase**
const storage = getStorage(app);

// **Exporter le stockage avec auth et db**
export { auth, db, storage };
