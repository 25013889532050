import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking, faSailboat } from '@fortawesome/free-solid-svg-icons';

const LevelsList = ({ teamData = {}, t }) => {

  const navigate = useNavigate();
 
  const { currentLevel = 0, usedLevels = [], paymentStatus } = teamData || {};
  

  const handleLevelClick = (level) => {
    if (!paymentStatus || paymentStatus !== 'completed') {
      navigate('/register', { 
        state: { 
          message: t('auth.registerMessage')
        }
      });
      return;
    }

    if (level > currentLevel + 1) {
      return;
    }

    navigate(`/niveau/${level}`);
  };

  return (
    <div className="flex flex-col items-center w-full max-w-6xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 mb-8">
        {t('dashboard.levelProgression')}
      </h2>
      
      <div className="w-full grid gap-4">
        {/* Section Virtuelle - 75 niveaux */}
        <div>
          <h3 className="text-lg font-semibold text-white/80 mb-4">{t('dashboard.virtualLevels')}</h3>
          <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-10 gap-2 md:gap-3">
            {Array.from({ length: 75 }, (_, i) => i + 1).map((level) => {
              const isCompleted = usedLevels.includes(`level${level}`);
              const isCurrentLevel = level === (currentLevel === 0 ? 1 : currentLevel + 1);
              const isAccessible = level <= (currentLevel === 0 ? 1 : currentLevel + 1);
              
              return (
                <div
                  key={level}
                  onClick={() => handleLevelClick(level)}
                  className={`
                    relative group flex items-center justify-center
                    rounded-lg font-bold text-white text-lg shadow-lg
                    transition-all duration-300 aspect-square border border-white/10
                    ${isCompleted 
                      ? 'bg-emerald-400/80 hover:bg-emerald-400' 
                      : isCurrentLevel 
                        ? 'bg-blue-400/80 hover:bg-blue-400'
                        : 'bg-[#1a2437] border-white/5'
                    }
                    ${isAccessible 
                      ? 'hover:scale-105 hover:shadow-xl hover:shadow-emerald-400/10 cursor-pointer' 
                      : 'cursor-not-allowed opacity-50'}
                  `}
                >
                  {level}
                </div>
              );
            })}
          </div>
        </div>

        {/* Section Bacalar - 25 niveaux */}
        <div>
          <h3 className="text-lg font-semibold text-white/80 mb-4 mt-8">{t('dashboard.cityLevels')}</h3>
          <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-10 gap-2 md:gap-3">
            {Array.from({ length: 25 }, (_, i) => i + 76).map((level) => {
              const isCompleted = usedLevels.includes(`level${level}`);
              const isCurrentLevel = level === (currentLevel === 0 ? 1 : currentLevel + 1);
              const isAccessible = level <= (currentLevel === 0 ? 1 : currentLevel + 1);
              
              return (
                <div
                  key={level}
                  onClick={() => handleLevelClick(level)}
                  className={`
                    relative group flex items-center justify-center
                    rounded-lg font-bold text-white text-lg shadow-lg
                    transition-all duration-300 aspect-square border border-white/10
                    ${isCompleted 
                      ? 'bg-emerald-400/80 hover:bg-emerald-400' 
                      : isCurrentLevel 
                        ? 'bg-blue-400/80 hover:bg-blue-400'
                        : 'bg-[#1a2437] border-white/5'
                    }
                    ${isAccessible 
                      ? 'hover:scale-105 hover:shadow-xl hover:shadow-emerald-400/10 cursor-pointer' 
                      : 'cursor-not-allowed opacity-50'}
                  `}
                >
                  <FontAwesomeIcon icon={faPersonWalking} className="mr-1" />
                  {level}
                </div>
              );
            })}
          </div>
        </div>

        {/* Section Voilier - 12 niveaux */}
        <div>
          <h3 className="text-lg font-semibold text-white/80 mb-4 mt-8">{t('dashboard.boatLevels')}</h3>
          <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-10 gap-2 md:gap-3">
            {Array.from({ length: 12 }, (_, i) => i + 101).map((level) => {
              const isCompleted = usedLevels.includes(`level${level}`);
              const isCurrentLevel = level === (currentLevel === 0 ? 1 : currentLevel + 1);
              const isAccessible = level <= (currentLevel === 0 ? 1 : currentLevel + 1);
              
              return (
                <div
                  key={level}
                  onClick={() => handleLevelClick(level)}
                  className={`
                    relative group flex items-center justify-center
                    rounded-lg font-bold text-white text-lg shadow-lg
                    transition-all duration-300 aspect-square border border-white/10
                    ${isCompleted 
                      ? 'bg-emerald-400/80 hover:bg-emerald-400' 
                      : isCurrentLevel 
                        ? 'bg-blue-400/80 hover:bg-blue-400'
                        : 'bg-[#1a2437] border-white/5'
                    }
                    ${isAccessible 
                      ? 'hover:scale-105 hover:shadow-xl hover:shadow-emerald-400/10 cursor-pointer' 
                      : 'cursor-not-allowed opacity-50'}
                  `}
                >
                  <FontAwesomeIcon icon={faSailboat} className="mr-1" />
                  {level}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelsList;