import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';

// Chemins d'images (à adapter selon l'emplacement de vos fichiers)
import SpatuleImage from '../assets/spatule.png';
import CrocodileImage from '../assets/crocodile.png';
import JaguarImage from '../assets/jaguar.png';
import PerroquetImage from '../assets/perroquet.png';
import ToucanImage from '../assets/toucan.png';
import TortueImage from '../assets/tortue.png';

const Level8 = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [moveCount, setMoveCount] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [language, setLanguage] = useState(null);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const pointsToAdd = 300;
  const levelIdentifier = 'level8';
  const redirectUrl = '/niveau/9';

  // Ajout du champ image pour chaque animal
  const wildlifeData = {
    fr: [
      { id: 1, name: 'Spatule Rosée', fact: 'Un oiseau rose élégant avec un bec en forme de spatule', image: SpatuleImage },
      { id: 2, name: 'Crocodile de Morelet', fact: 'Un petit crocodile endémique à la région', image: CrocodileImage },
      { id: 3, name: 'Jaguar', fact: 'Le plus grand félin des Amériques', image: JaguarImage },
      { id: 4, name: 'Perroquet Yucatèque', fact: 'Un perroquet aux couleurs vives de la péninsule', image: PerroquetImage },
      { id: 5, name: 'Toucan', fact: 'Reconnaissable à son grand bec coloré', image: ToucanImage },
      { id: 6, name: 'Tortue de mer', fact: 'Nage gracieusement dans la lagune', image: TortueImage },
    ],
    en: [
      { id: 1, name: 'Roseate Spoonbill', fact: 'An elegant pink bird with a spoon-shaped beak', image: SpatuleImage },
      { id: 2, name: "Morelet's Crocodile", fact: 'A small crocodile endemic to the region', image: CrocodileImage },
      { id: 3, name: 'Jaguar', fact: 'The largest feline in the Americas', image: JaguarImage },
      { id: 4, name: 'Yucatan Parrot', fact: 'A brightly colored parrot from the peninsula', image: PerroquetImage },
      { id: 5, name: 'Toucan', fact: 'Recognizable by its large colorful beak', image: ToucanImage },
      { id: 6, name: 'Sea Turtle', fact: 'Gracefully swimming in the lagoon', image: TortueImage },
    ],
    es: [
      { id: 1, name: 'Espátula Rosada', fact: 'Un elegante pájaro rosa con pico en forma de espátula', image: SpatuleImage },
      { id: 2, name: 'Cocodrilo de Morelet', fact: 'Un pequeño cocodrilo endémico de la región', image: CrocodileImage },
      { id: 3, name: 'Jaguar', fact: 'El felino más grande de las Américas', image: JaguarImage },
      { id: 4, name: 'Loro Yucateco', fact: 'Un loro de colores brillantes de la península', image: PerroquetImage },
      { id: 5, name: 'Tucán', fact: 'Reconocible por su gran pico colorido', image: ToucanImage },
      { id: 6, name: 'Tortuga Marina', fact: 'Nada con gracia en la laguna', image: TortueImage },
    ]
  };

  const translations = {
    fr: {
      levelNumber: 'NIVEAU 008',
      title: 'Mémoire de la Faune de Bacalar',
      instruction: 'Associez les animaux à leurs descriptions',
      moves: 'Coups joués',
      success: 'Félicitations ! Vous gagnez 300 points !',
      error: 'Une erreur est survenue',
      alreadyCompleted: 'Niveau déjà complété',
      loading: 'Chargement...',
      continueButton: 'Continuer'
    },
    en: {
      levelNumber: 'LEVEL 008',
      title: 'Bacalar Wildlife Memory',
      instruction: 'Match the animals with their descriptions',
      moves: 'Moves',
      success: 'Congratulations! You earn 300 points!',
      error: 'An error occurred',
      alreadyCompleted: 'Level already completed',
      loading: 'Loading...',
      continueButton: 'Continue'
    },
    es: {
      levelNumber: 'NIVEL 008',
      title: 'Memoria de la Fauna de Bacalar',
      instruction: 'Asocia los animales con sus descripciones',
      moves: 'Movimientos',
      success: '¡Felicitaciones! ¡Ganas 300 puntos!',
      error: 'Ocurrió un error',
      alreadyCompleted: 'Nivel ya completado',
      loading: 'Cargando...',
      continueButton: 'Continuar'
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();
          setLanguage(teamData.language || 'fr');
        } else {
          setLanguage('fr');
        }
      } else {
        setLanguage('fr');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (language) {
      const setupGame = () => {
        const cardPairs = wildlifeData[language].flatMap(animal => ([
          {
            id: `${animal.id}_name`,
            type: 'name',
            content: animal.image, // On utilisera l'image au lieu du nom
            matchId: animal.id
          },
          {
            id: `${animal.id}_fact`,
            type: 'fact',
            content: animal.fact,
            matchId: animal.id
          }
        ]));

        const shuffledCards = [...cardPairs]
          .sort(() => Math.random() - 0.5)
          .map((card, index) => ({ ...card, position: index }));

        setCards(shuffledCards);
      };

      setupGame();
    }
  }, [language]);

  const handleCardClick = async (clickedCard) => {
    if (flippedCards.length === 2 || flippedCards.includes(clickedCard.id) || matchedPairs.includes(clickedCard.matchId)) {
      return;
    }

    setFlippedCards(prev => [...prev, clickedCard.id]);

    if (flippedCards.length === 1) {
      setMoveCount(prev => prev + 1);
      const firstCard = cards.find(card => card.id === flippedCards[0]);

      if (firstCard.matchId === clickedCard.matchId) {
        setMatchedPairs(prev => [...prev, clickedCard.matchId]);
        setFlippedCards([]);

        if (matchedPairs.length + 1 === wildlifeData[language].length) {
          setGameOver(true);
          setShowConfetti(true);
          await updateTeamPoints();
        }
      } else {
        setTimeout(() => {
          setFlippedCards([]);
        }, 1000);
      }
    }
  };

  const updateTeamPoints = async () => {
    if (!currentUser) return;

    try {
      const teamsCollection = collection(db, 'teams');
      const q = query(teamsCollection, where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const teamDoc = querySnapshot.docs[0];
        const teamData = teamDoc.data();

        if (teamData.usedLevels?.includes(levelIdentifier)) {
          setError(translations[language].alreadyCompleted);
        } else {
          const teamRef = doc(db, 'teams', teamDoc.id);
          await updateDoc(teamRef, {
            points: (teamData.points || 0) + pointsToAdd,
            usedLevels: arrayUnion(levelIdentifier)
          });

          setSuccess(translations[language].success);
        }
      }
    } catch (err) {
      console.error('Erreur:', err);
      setError(translations[language].error);
    }
  };

  const handleContinue = () => {
    navigate(redirectUrl);
  };

  if (loading || !language) {
    return (
      <div className="min-h-screen p-4 bg-[#0e1d35] flex items-center justify-center">
        <div className="text-white">{translations[language]?.loading || 'Chargement...'}</div>
      </div>
    );
  }

  const showContinueButton = gameOver && (success || error === translations[language].alreadyCompleted);

  return (
    <div className="min-h-screen p-4 bg-[#0e1d35]">
      <div className="max-w-4xl mx-auto">
        <div className="relative mb-8">
          <h1 className="text-6xl md:text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
            {translations[language].levelNumber}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-2xl text-white mb-4">{translations[language].moves}: {moveCount}</h2>
          <p className="text-white/70">{translations[language].instruction}</p>
        </div>

        <div className="grid grid-cols-3 md:grid-cols-4 gap-4 mb-8">
          {cards.map(card => (
            <div
              key={card.id}
              onClick={() => handleCardClick(card)}
              className={`
                aspect-square rounded-xl p-4
                flex items-center justify-center
                cursor-pointer transform transition-all duration-300
                ${flippedCards.includes(card.id) || matchedPairs.includes(card.matchId)
                  ? 'bg-blue-400 rotate-0'
                  : 'bg-[#1a2437] rotate-180'
                }
                ${matchedPairs.includes(card.matchId) ? 'bg-emerald-400' : ''}
                hover:scale-105
              `}
            >
              <div className={`
                text-white text-center text-sm md:text-base
                transform transition-all duration-300
                ${flippedCards.includes(card.id) || matchedPairs.includes(card.matchId)
                  ? 'rotate-0'
                  : 'rotate-180'
                }
              `}>
                {(flippedCards.includes(card.id) || matchedPairs.includes(card.matchId)) && (
                  card.type === 'name'
                    ? <img src={card.content} alt="animal" className="max-h-full max-w-full object-contain" />
                    : card.content
                )}
              </div>
            </div>
          ))}
        </div>

        {success && (
          <div className="text-center p-4 bg-emerald-500/20 text-emerald-300 rounded-lg mb-4">
            {success}
          </div>
        )}
        {error && (
          <div className="text-center p-4 bg-red-500/20 text-red-300 rounded-lg mb-4">
            {error}
          </div>
        )}

        {showContinueButton && (
          <div className="text-center mt-6">
            <button
              onClick={handleContinue}
              className="px-6 py-3 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-all duration-300"
            >
              {translations[language].continueButton}
            </button>
          </div>
        )}
      </div>

      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
        />
      )}
    </div>
  );
};

export default Level8;
