import React, { useState } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!email || !password) {
        throw new Error(email ? t('auth.login.passwordRequired') : t('auth.login.emailRequired'));
      }

      await signInWithEmailAndPassword(auth, email, password);
      navigate('/tableaubord');
      
    } catch (error) {
      console.error('Erreur de connexion:', error);
      setError(t('auth.login.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/tableaubord');
    } catch (error) {
      console.error('Erreur de connexion Google:', error);
      setError(t('auth.googleError'));
    }
  };

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-md mx-auto mt-8 md:mt-4">
        <div className="relative">
          <h1 className="text-4xl font-black text-center mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
            {t('auth.login.title')}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        <div className="mt-8 backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10">
          {/* Bouton Google */}
          <button
            onClick={handleGoogleLogin}
            className="w-full py-4 mb-6 bg-white text-gray-800 rounded-lg hover:bg-gray-100 transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-center gap-2"
          >
            <svg className="w-5 h-5" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              />
              <path
                fill="currentColor"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              />
              <path
                fill="currentColor"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              />
              <path
                fill="currentColor"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              />
            </svg>
            {t('auth.login.googleButton')}
          </button>

          <div className="relative mb-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-white/10"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-[#1a2437] text-white/60">{t('auth.login.or')}</span>
            </div>
          </div>

          {/* Formulaire Email/Mot de passe */}
          <form onSubmit={handleEmailLogin} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-white/90 font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-3 rounded-lg bg-[#1a2437]/60 text-white border border-white/10 focus:border-blue-400/50 focus:ring-2 focus:ring-blue-400/20 transition-all duration-200"
                placeholder={t('auth.login.emailPlaceholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-white/90 font-medium mb-2">
                {t('auth.login.passwordLabel')}
              </label>
              <input
                type="password"
                id="password"
                className="w-full p-3 rounded-lg bg-[#1a2437]/60 text-white border border-white/10 focus:border-blue-400/50 focus:ring-2 focus:ring-blue-400/20 transition-all duration-200"
                placeholder={t('auth.login.passwordPlaceholder')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            +           {/* Nouveau lien "Mot de passe oublié" */}
           <div className="text-right">
             <a 
               href="/mot-de-passe-oublie" 
               className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200"
             >
              {t('auth.login.forgotPassword')}
             </a>
          </div>

            {error && (
              <div className="p-4 rounded-lg bg-red-500/20 text-red-300 border border-red-500/20">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-4 bg-blue-400 text-white rounded-lg hover:bg-blue-500 transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                  {t('common.loading')}
                </span>
              ) : (
                t('auth.login.submitButton')
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;