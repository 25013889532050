import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const ProtectedLevelRoute = ({ children }) => {
  const navigate = useNavigate();
  const { level } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const teamDoc = await getDoc(doc(db, 'teams', user.uid));
        if (!teamDoc.exists()) {
          navigate('/register');
          return;
        }

        const teamData = teamDoc.data();
        const levelNumber = parseInt(level, 10);
        const currentLevel = teamData.currentLevel || 0;

        // Vérifier si le paiement est complété
        if (!teamData.paymentStatus || teamData.paymentStatus !== 'completed') {
          navigate('/register', {
            state: {
              message: "Veuillez finaliser votre inscription pour accéder aux niveaux du jeu."
            }
          });
          return;
        }

        // Vérifier si le niveau est accessible
        if (levelNumber > currentLevel + 1) {
          navigate('/tableaubord');
          return;
        }

        setIsAuthorized(true);
      } catch (error) {
        console.error('Error checking authorization:', error);
        navigate('/tableaubord');
      } finally {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [level, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#0e1d35]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return isAuthorized ? children : null;
};

export default ProtectedLevelRoute;