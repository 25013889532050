import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'lucide-react';
import ProfileImage from '../utils/ProfileImage';


const PublicLeaderboard = () => {
  const [teams, setTeams] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'teams'), (snapshot) => {
      const fetchedTeams = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      fetchedTeams.sort((a, b) => (b.points + b.bonusPoints) - (a.points + a.bonusPoints));
      setTeams(fetchedTeams);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const checkScrollable = () => {
      const tableContainer = document.querySelector('.table-container');
      if (tableContainer) {
        setShowScrollIndicator(tableContainer.scrollWidth > tableContainer.clientWidth && !isScrolled);
      }
    };

    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    return () => window.removeEventListener('resize', checkScrollable);
  }, [isScrolled]);

  const handleScroll = (e) => {
    if (e.target.scrollLeft > 20) {
      setShowScrollIndicator(false);
      setIsScrolled(true);
    }
  };

  const openLightbox = (team) => {
    setSelectedTeam(team);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedTeam(null);
  };

  return (
    <div className="min-h-screen bg-[#0e1d35]">
      <div className="container mx-auto px-4 py-8">
        {/* En-tête */}
        <div className="mb-8 mt-8 md:mt-2">
          <div className="relative py-8 text-center">
            <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-400 to-emerald-400 opacity-10 blur-xl -z-10 rounded-full"></div>
            <h1 className="text-4xl font-black text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
              {t('navbar.leaderboard')}
            </h1>
          </div>
        </div>

        {/* Tableau des scores */}
        <div className="bg-[#1a2437]/60 rounded-xl border border-white/10 backdrop-blur-sm p-6">
          <div className="relative">
            {showScrollIndicator && (
              <div className="md:hidden text-center mb-4 animate-pulse">
                <div className="inline-flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full">
                  <span className="text-white/80 text-sm font-medium">Swipe</span>
                  <ChevronRight className="w-4 h-4 text-white/80" />
                </div>
              </div>
            )}

            <div className="table-container overflow-x-auto" onScroll={handleScroll}>
              <table className="w-full">
                <thead>
                  <tr className="border-b border-white/10">
                    <th className="px-4 py-3 text-left text-white/60 font-medium whitespace-nowrap">{t('navbar.rank')}</th>
                    <th className="px-4 py-3 text-left text-white/60 font-medium whitespace-nowrap">{t('teamCreation.teamNameLabel')}</th>
                    <th className="px-4 py-3 text-left text-white/60 font-medium whitespace-nowrap">{t('teamCreation.teamCountryLabel')}</th>
                    <th className="px-4 py-3 text-center text-white/60 font-medium whitespace-nowrap">Niveau</th>
                    <th className="px-4 py-3 text-center text-white/60 font-medium whitespace-nowrap">Étape</th>
                    <th className="px-4 py-3 text-center text-white/60 font-medium whitespace-nowrap">{t('navbar.total')}</th>
                    <th className="px-4 py-3 text-center text-white/60 font-medium whitespace-nowrap">Image</th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map((team, index) => (
                    <tr key={team.id} className="border-b border-white/5 hover:bg-white/5 transition-colors duration-200">
                      <td className="px-4 py-3 text-white">{index + 1}</td>
                      <td className="px-4 py-3">
                        <button
                          onClick={() => openLightbox(team)}
                          className="text-blue-400 hover:text-emerald-400 transition-colors duration-200"
                        >
                          {team.name}
                        </button>
                      </td>
                      <td className="px-4 py-3 text-white">{team.country}</td>
                      <td className="px-4 py-3 text-center text-emerald-400 font-medium">{team.currentLevel}</td>
                      <td className="px-4 py-3 text-center text-blue-400 font-medium">{team.currentStage}</td>
                      <td className="px-4 py-3 text-center text-white font-bold">
                        {team.points + team.bonusPoints}
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex justify-center">
                        <ProfileImage 
  userId={team.id}
  imageUrl={team.profileImageUrl}
  className="border-2 border-blue-400 hover:border-emerald-400 transition-colors duration-200"
  onClick={() => openLightbox(team)}
/>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal/Lightbox */}
      {lightboxOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50 backdrop-blur-sm">
          <div className="bg-[#1a2437] text-white rounded-xl border border-white/10 p-8 max-w-md max-h-[90vh] overflow-y-auto relative">
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 text-white/60 hover:text-white transition-colors duration-200"
            >
              <svg className="w-6 h-6" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            
            {selectedTeam && (
              <div className="text-center">
                <img
                  src={selectedTeam.profileImageUrl || 'default-image-url'}
                  alt={`${selectedTeam.name}`}
                  className="w-32 h-32 rounded-full object-cover border-4 border-blue-400 mx-auto mb-6"
                />
                <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 mb-4">
                  {selectedTeam.name}
                </h2>
                
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div className="bg-[#0e1d35] p-3 rounded-lg">
                    <div className="text-white/60 text-sm">Pays</div>
                    <div className="text-white font-medium">{selectedTeam.country || 'N/A'}</div>
                  </div>
                  <div className="bg-[#0e1d35] p-3 rounded-lg">
                    <div className="text-white/60 text-sm">Points Totaux</div>
                    <div className="text-blue-400 font-bold">{selectedTeam.points + selectedTeam.bonusPoints}</div>
                  </div>
                </div>

                <div className="text-left">
                  <h3 className="text-lg font-medium mb-2">Membres de l'équipe:</h3>
                  <div className="bg-[#0e1d35] rounded-lg p-4">
                    {selectedTeam.players ? selectedTeam.players.map((player, index) => (
                      <div key={index} className="border-b border-white/10 last:border-0 py-2">
                        <div className="font-medium">{player.playerName}</div>
                        {player.isChild && (
                          <div className="text-sm text-emerald-400">
                            {player.childAge} ans
                          </div>
                        )}
                      </div>
                    )) : (
                      <div className="text-white/60">Aucun joueur enregistré</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicLeaderboard;