import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';

import BirdImage1 from '../assets/SpatuleRosee.png';
import BirdImage2 from '../assets/SpatuleRosee3.png';
import BirdImage3 from '../assets/SpatuleRosee4.png';
import BirdImage4 from '../assets/SpatuleRosee5.png';
import ExtraImage from '../assets/SpatuleRosee6.png';

const Level5 = () => {
  const navigate = useNavigate();

  const levels = [
    { delay: 1000 },
    { delay: 800 },
    { delay: 600 },
    { delay: 400 },
    { delay: 400, extraImage: true, extraPosition: 0 },
    { delay: 300, extraImage: true, extraPosition: 1 },
    { delay: 300, extraImage: true, extraPosition: 0 },
  ];

  const birdImages = [BirdImage1, BirdImage2, BirdImage3, BirdImage4];

  const [currentLevel, setCurrentLevel] = useState(0);
  const [sequence, setSequence] = useState([]);
  const [playerSequence, setPlayerSequence] = useState([]);
  const [clickable, setClickable] = useState(false);
  const [message, setMessage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [gridItems, setGridItems] = useState(Array.from({ length: 12 }, () => ({ image: null })));
  const [currentUser, setCurrentUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useState('fr');
  const [showContinueButton, setShowContinueButton] = useState(false);


  const levelToAdd = 'level5';
  const pointsToAdd = 500;
  const redirectUrl = '/niveau/6';
  const alreadyUsedRedirectUrl = '/niveau/6';

  const translations = {
    fr: {
      startLevelButton: 'Continuer',
      levelNumber: 'NIVEAU 005',
      missionTitle: 'Le jeu de la Spatule Rosée',
      levelTitle: 'Jeux de la Spatule Rosee Niveau 5',
      startButton: 'Commencer!',
      messageCongratulations: 'Félicitations ! Prochaine séquence..',
      messageTimeUp: "Temps écoulé ! Vous n'avez pas terminé à temps.",
      messageAlreadyCompleted:
        "Vous avez déjà complété ce niveau. Les points ne seront pas ajoutés à nouveau. Redirection vers le niveau suivant...",
      messageSuccess: 'Félicitations ! Vous avez gagné 500 points.',
      messageErrorUserNotFound: "Aucun document d'équipe trouvé pour l'utilisateur.",
      messageErrorOccurred: 'Une erreur est survenue. Veuillez réessayer.',
      messageUserNotLoggedIn: 'Utilisateur non connecté.',
      messageGameOver: 'Dommage ! Recommencez depuis le début...',
      replayButton: 'Essayez encore!',
      gameInstructions: `Pour cette 5ème épreuve, il est temps de tester votre mémoire et votre sens de l'observation !\n\n
      Suivez les déplacements avec attention et cliquez sur les cases correspondantes dans le même ordre que les atterrissages. Vous devrez bien mémoriser chaque mouvement pour reproduire le parcours sans erreur.\n\n
      Concentrez-vous, et réussissez cette épreuve pour avancer dans votre quête !`,
          },
    en: {
      startLevelButton: 'Continue',
      levelNumber: 'LEVEL 005',
      missionTitle: 'The Roseate Spoonbill Game',
      levelTitle: 'Level 5 Pink Spoonbill Game',
      startButton: 'Start!',
      messageCongratulations: 'Congratulations! Next sequence...',
      messageTimeUp: "Time's up! You didn't finish in time.",
      messageAlreadyCompleted:
        'You have already completed this level. Points will not be added again. Redirecting to the next level...',
      messageSuccess: 'Congratulations! You have earned 500 points.',
      messageErrorUserNotFound: 'No team document found for the user.',
      messageErrorOccurred: 'An error occurred. Please try again.',
      messageUserNotLoggedIn: 'User not logged in.',
      messageGameOver: 'Too bad! Restart from the beginning...',
      replayButton: 'Try Again!',
      gameInstructions: `For this 5th challenge, it's time to test your memory and observation skills!\n\n
      Follow the movements carefully and click on the corresponding squares in the same order as the landings. You will need to memorize each movement to reproduce the path without error.\n\n
      Focus, and succeed in this challenge to advance in your quest!`,
          },
    es: {
      startLevelButton: 'Continuar',
      levelNumber: 'NIVEL 005',
      missionTitle: 'El Juego de la Espátula Rosada',
      levelTitle: 'Nivel 5 Juego del Espátula Rosa',
      startButton: '¡Comenzar!',
      messageCongratulations: '¡Felicidades! Próxima secuencia...',
      messageTimeUp: '¡Tiempo agotado! No terminaste a tiempo.',
      messageAlreadyCompleted:
        'Ya has completado este nivel. Los puntos no se agregarán de nuevo. Redirigiendo al siguiente nivel...',
      messageSuccess: '¡Felicidades! Has ganado 500 puntos.',
      messageErrorUserNotFound: 'No se encontró ningún documento de equipo para el usuario.',
      messageErrorOccurred: 'Ocurrió un error. Por favor, inténtalo de nuevo.',
      messageUserNotLoggedIn: 'Usuario no conectado.',
      messageGameOver: '¡Demasiado mal! Recommence desde el principio...',
      replayButton: '¡Inténtalo de nuevo!',
      gameInstructions: `¡Para este quinto desafío, es hora de probar tu memoria y tu sentido de la observación!\n\n
      Sigue los movimientos con atención y haz clic en las casillas correspondientes en el mismo orden que sus aterrizajes. Deberás memorizar bien cada movimiento para reproducir la trayectoria sin error.\n\n
      ¡Concéntrate y logra este desafío para avanzar en tu búsqueda!`,
          },
  };

  const shuffleArray = (array) => {
    const arr = array.slice();
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const teamsCollection = collection(db, 'teams');
          const q = query(teamsCollection, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const teamsDoc = querySnapshot.docs[0];
            const teamsData = teamsDoc.data();
            setLanguage(teamsData.language || 'fr');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de la langue :', error);
          setLanguage('fr');
        }
      } else {
        setCurrentUser(null);
        setLanguage('fr');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const startLevel = () => {
    setSequence([]);
    setPlayerSequence([]);
    setClickable(false);
    setMessage('');
    setGameOver(false);
    setErrorMessage('');
    setSuccessMessage('');

    let currentBirdImages = [...birdImages];

    if (levels[currentLevel].extraImage) {
      currentBirdImages.splice(levels[currentLevel].extraPosition, 0, ExtraImage);
    }

    const shuffledPositions = shuffleArray([...Array(gridItems.length).keys()]).slice(
      0,
      currentBirdImages.length
    );

    const newSequence = [];
    for (let i = 0; i < currentBirdImages.length; i++) {
      newSequence.push({
        index: shuffledPositions[i],
        image: currentBirdImages[i],
      });
    }

    setSequence(newSequence);
    displaySequence(newSequence);
  };

  const displaySequence = (sequenceToDisplay) => {
    let delay = levels[currentLevel].delay;

    sequenceToDisplay.forEach((item, i) => {
      setTimeout(() => {
        showBird(item.index, item.image);
      }, delay * i * 2);

      setTimeout(() => {
        hideBird(item.index);
        if (i === sequenceToDisplay.length - 1) {
          setClickable(true);
        }
      }, delay * i * 2 + delay);
    });
  };

  const showBird = (index, imgSrc) => {
    setGridItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = { ...newItems[index], image: imgSrc };
      return newItems;
    });
  };

  const hideBird = (index) => {
    setGridItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = { ...newItems[index], image: null };
      return newItems;
    });
  };

  const handleGridItemClick = (index) => {
    if (!clickable) return;

    const expectedImage = sequence[playerSequence.length].image;

    setPlayerSequence((prevSequence) => {
      const newSequence = [...prevSequence, { index, image: expectedImage }];

      showBird(index, expectedImage);

      setTimeout(() => {
        hideBird(index);
      }, 300);

      if (newSequence.length === sequence.length) {
        setClickable(false);
        checkSequence(newSequence);
      }

      return newSequence;
    });
  };

  const checkSequence = (playerSeq) => {
    const isCorrect = playerSeq.every(
      (val, index) =>
        val.index === sequence[index].index && val.image === sequence[index].image
    );

    if (isCorrect) {
      if (currentLevel + 1 < levels.length) {
        setMessage(translations[language].messageCongratulations);
        setCurrentLevel((prevLevel) => prevLevel + 1);
        setTimeout(() => {
          startLevel();
        }, 2000);
      } else {
        setMessage(translations[language].messageSuccess);
        triggerConfetti();
        updatePointsAndLevel();
      }
    } else {
      setMessage(translations[language].messageGameOver);
      setGameOver(true);
      setCurrentLevel(0);
    }
  };

  const updatePointsAndLevel = async () => {
    if (currentUser) {
      try {
        const userId = currentUser.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();

          if (teamData.usedLevels && teamData.usedLevels.includes(levelToAdd)) {
            setMessage(translations[language].messageAlreadyCompleted);
setShowContinueButton(true); // Affiche le bouton "Continuer"

          } else {
            let updatedPoints = teamData.points || 0;
            updatedPoints += pointsToAdd;

            const teamDocRef = doc(db, 'teams', teamDoc.id);

            await updateDoc(teamDocRef, {
              points: updatedPoints,
              usedLevels: arrayUnion(levelToAdd),
            });

            setMessage(translations[language].messageSuccess);
setShowContinueButton(true); // Affiche le bouton "Continuer"

          }
        } else {
          setErrorMessage(translations[language].messageErrorUserNotFound);
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour des points:', error);
        setErrorMessage(translations[language].messageErrorOccurred);
      }
    } else {
      setErrorMessage(translations[language].messageUserNotLoggedIn);
    }
  };

  const triggerConfetti = () => {
    setShowConfetti(true);
  };

  const handlePlayAgain = () => {
    setGameOver(false);
    setMessage('');
    startLevel();
  };

  return (
    <div className="min-h-screen p-6 text-white">
      {/* Titre principal avec effet de dégradé et flou */}
      <div className="relative">
        <h1 className="text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
          {translations[language].levelNumber}
        </h1>
        <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
      </div>

      {/* Sous-titre avec ligne décorative */}
      <h2 className="text-2xl font-bold text-center mb-12 relative">
        <span className="bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
          {translations[language].missionTitle}
        </span>
        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full"></div>
      </h2>

      {/* Texte d'instructions stylisé */}
      <div className="max-w-2xl mx-auto mb-12 backdrop-blur-sm bg-white/5 p-8 rounded-xl">
        <p className="whitespace-pre-line text-white/90 leading-relaxed">
          {translations[language].gameInstructions}
        </p>
      </div>

      {/* Boutons de jeu centrés */}
      <div className="flex justify-center gap-4 mb-8">
        {!gameOver && sequence.length === 0 && (
          <button
            onClick={startLevel}
            className="px-8 py-4 bg-blue-400 text-white rounded-lg hover:bg-emerald-400 transition-colors duration-300"
          >
            {translations[language].startButton}
          </button>
        )}
      </div>

      {/* Grille de jeu */}
      {(sequence.length > 0 || gameOver) && (
        <div className="grid-container" style={{ width: '90vw', maxWidth: '400px', margin: '20px auto' }}>
          <div className="grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '5px' }}>
            {gridItems.map((item, index) => (
              <div
                key={index}
                onClick={() => handleGridItemClick(index)}
                style={{
                  backgroundColor: 'transparent',
                  border: '2px solid #ffffff',
                  position: 'relative',
                  paddingBottom: '100%',
                  cursor: clickable ? 'pointer' : 'default',
                }}
              >
                {item.image && (
                  <img
                    src={item.image}
                    alt="Spatule rosée"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Messages */}
      {message && (
        <div className="mt-8 text-center">
          <div className="text-xl font-medium bg-white/5 backdrop-blur-sm p-4 rounded-lg inline-block">
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
      )}

{showContinueButton && (
  <div className="text-center mt-8">
    <button
      onClick={() => navigate(redirectUrl)}
      className="px-8 py-4 bg-blue-400 text-white rounded-lg hover:bg-emerald-400 transition-colors duration-300"
    >
      {translations[language].startLevelButton}
    </button>
  </div>
)}


      {/* Bouton rejouer */}
      {gameOver && (
        <div className="text-center mt-8">
          <button
            onClick={handlePlayAgain}
            className="px-8 py-4 bg-blue-400 text-white rounded-lg hover:bg-emerald-400 transition-colors duration-300"
          >
            {translations[language].replayButton}
          </button>
        </div>
      )}

      {/* Messages d'erreur et de succès */}
      {errorMessage && (
        <div className="mt-8 text-center p-4 rounded-lg bg-red-500/20 text-red-300 backdrop-blur-sm border border-red-500/20">
          {errorMessage}
        </div>
      )}

      {successMessage && (
        <div className="mt-8 text-center p-4 rounded-lg bg-green-500/20 text-green-300 backdrop-blur-sm border border-green-500/20">
          {successMessage}
        </div>
      )}

      {/* Confetti */}
      {showConfetti && (
        <Confetti width={windowWidth} height={window.innerHeight} />
      )}
    </div>
  );
};

export default Level5;