import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Globe, LogOut, Trophy, Layout } from 'lucide-react';
import { auth, db } from '../firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import ProfileImage from '../utils/ProfileImage';


const logoTitles = {
  fr: "Rallye Trésors de Bacalar",
  en: "Rally Treasures of Bacalar",
  es: "Rally Tesoros de Bacalar"
};

const Navbar = ({ setLanguage, user }) => {
  const [teamName, setTeamName] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [rank, setRank] = useState(null);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    if (setLanguage) setLanguage(lang);
    setShowLanguages(false);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'teams', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            setTeamName(data.name);
            setProfileImageUrl(data.profileImageUrl);
            setTotalPoints(data.points + (data.bonusPoints || 0));

            const teamsCollection = collection(db, 'teams');
            onSnapshot(teamsCollection, (snapshot) => {
              const teams = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              teams.sort(
                (a, b) => (b.points + (b.bonusPoints || 0)) - (a.points + (a.bonusPoints || 0))
              );
              const userRank = teams.findIndex((team) => team.id === currentUser.uid) + 1;
              setRank(userRank);
            });
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données de l'équipe :", error);
        }
      } else {
        setTeamName('');
        setProfileImageUrl(null);
        setRank(null);
        setTotalPoints(0);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleMenuClick = () => {
    if (!isOpen) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="h-[120px]">
      <nav className="fixed top-0 left-0 w-full z-50 bg-gradient-to-r from-[#0e1d35] via-[#1a2437] to-[#0e1d35] border-b border-white/10 backdrop-blur-sm shadow-lg">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-[120px]">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0">
              <div className="relative group">
                <div className="flex items-center">
                  <div className="relative">
                    <div className="absolute -inset-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-1000 group-hover:duration-200"></div>
                    <div className="relative bg-[#1a2437] ring-1 ring-white/10 rounded-lg p-2 md:p-3">
                      <span className="text-xl md:text-2xl font-black bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent transform group-hover:scale-110 transition-transform duration-200">
                        R·T·B
                      </span>
                    </div>
                  </div>
                  <div className="ml-3 overflow-hidden min-w-[200px]">
                    <div className="transform group-hover:translate-x-2 translate-x-[-30px] opacity-0 group-hover:opacity-100 transition-all duration-300 whitespace-nowrap">
                      <span className="font-semibold bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent">
                        {logoTitles[i18n.language]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            {/* Navigation Desktop */}
            <div className="hidden md:flex items-center space-x-6">
              {/* Sélecteur de langue */}
              <div className="relative">
                <button
                  onClick={() => setShowLanguages(!showLanguages)}
                  className="p-2 rounded-full hover:bg-white/5 transition-colors duration-200 text-white/60 hover:text-white"
                >
                  <Globe size={20} />
                </button>
                
                {showLanguages && (
                  <div className="absolute top-full right-0 mt-2 bg-[#1a2437] border border-white/10 rounded-lg shadow-lg overflow-hidden">
                    {['en', 'fr', 'es'].map((lang) => (
                      <button
                        key={lang}
                        onClick={() => handleLanguageChange(lang)}
                        className={`w-full px-4 py-2 text-left hover:bg-white/5 transition-colors duration-200
                          ${i18n.language === lang ? 'text-blue-400 bg-blue-400/10' : 'text-white/60 hover:text-white'}`}
                      >
                        {lang.toUpperCase()}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {/* Navigation principale */}
              <div className="flex items-center space-x-6">
                <Link to="/publicleaderboard" 
                  className="group flex items-center space-x-2 px-4 py-2 rounded-lg bg-blue-400/10 hover:bg-blue-400/20 transition-all duration-200">
                  <Trophy size={20} className="text-blue-400" />
                  <span className="text-blue-400 font-medium">{t('navbar.leaderboard')}</span>
                  <span className="block h-0.5 w-0 group-hover:w-full bg-gradient-to-r from-blue-400 to-emerald-400 transition-all duration-300"></span>
                </Link>
                
                {user && (
                  <Link to="/tableaubord" 
                    className="group flex items-center space-x-2 px-4 py-2 rounded-lg bg-emerald-400/10 hover:bg-emerald-400/20 transition-all duration-200">
                    <Layout size={20} className="text-emerald-400" />
                    <span className="text-emerald-400 font-medium">{t('navbar.dashboard')}</span>
                    <span className="block h-0.5 w-0 group-hover:w-full bg-gradient-to-r from-emerald-400 to-blue-400 transition-all duration-300"></span>
                  </Link>
                )}
              </div>

              {user && (
                <div className="flex items-center space-x-6">
                  {/* Stats */}
                  <div className="flex space-x-3">
                    <div className="group relative">
                      <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-300"></div>
                      <div className="relative text-center bg-[#0e1d35] px-4 py-2 rounded-lg border border-white/10">
                        <span className="block text-sm font-medium text-white/60">{t('navbar.rank')}</span>
                        <strong className="text-2xl bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent">{rank || 'N/A'}</strong>
                      </div>
                    </div>
                    <div className="group relative">
                      <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-300"></div>
                      <div className="relative text-center bg-[#0e1d35] px-4 py-2 rounded-lg border border-white/10">
                        <span className="block text-sm font-medium text-white/60">{t('navbar.total')}</span>
                        <strong className="text-2xl bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent">{totalPoints || '0'}</strong>
                      </div>
                    </div>
                  </div>

                  {/* Profil et Déconnexion */}
                  <div className="flex items-center space-x-4">
                  {user && (
  <ProfileImage 
    userId={user.uid}
    imageUrl={profileImageUrl}
    className="border-2 border-blue-400 hover:border-emerald-400 transition-colors duration-200"
  />
)}
                    <button 
                      onClick={() => signOut(auth)}
                      className="p-2 rounded-full hover:bg-white/5 transition-colors duration-200 text-white/60 hover:text-white"
                    >
                      <LogOut size={20} />
                    </button>
                  </div>
                </div>
              )}

              {!user && (
                <div className="flex items-center space-x-4">
                  <Link to="/register" 
                    className="px-4 py-2 rounded-lg text-white hover:bg-white/5 transition-all duration-200">
                    {t('navbar.register')}
                  </Link>
                  <Link to="/login"
                    className="px-4 py-2 rounded-lg bg-gradient-to-r from-blue-400 to-emerald-400 text-white hover:opacity-90 transition-opacity duration-200">
                    {t('navbar.login')}
                  </Link>
                </div>
              )}
            </div>

            {/* Bouton Menu Mobile */}
            <button
              onClick={handleMenuClick}
              className="md:hidden relative group p-2 rounded-lg hover:bg-white/5 transition-colors duration-200"
            >
              {isOpen ? <X size={24} className="text-white" /> : <Menu size={24} className="text-white" />}
            </button>
          </div>

       {/* Menu Mobile */}
<div className={`md:hidden fixed inset-0 top-[120px] ${
  isOpen ? 'block' : 'hidden'
}`}>
  <div className="bg-[#1a2437] bg-opacity-98 min-h-screen px-4 py-6 space-y-6">
              <div className="p-4 space-y-6">
                <div className="text-center">
                  <span className="font-semibold bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent">
                    {logoTitles[i18n.language]}
                  </span>
                </div>

                <div className="flex justify-center space-x-2">
                  {['en', 'fr', 'es'].map((lang) => (
                    <button
                      key={lang}
                      onClick={() => handleLanguageChange(lang)}
                      className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                        i18n.language === lang 
                          ? 'bg-blue-400/20 text-blue-400' 
                          : 'text-white/60 hover:text-white hover:bg-white/5'
                      }`}
                    >
                      {lang.toUpperCase()}
                    </button>
                  ))}
                </div>

                <Link to="/publicleaderboard" 
                  className="flex items-center justify-center space-x-2 p-3 rounded-lg bg-blue-400/10 text-blue-400"
                  onClick={() => setIsOpen(false)}>
                  <Trophy size={20} />
                  <span className="font-medium">{t('navbar.leaderboard')}</span>
                </Link>

                {user && (
                  <Link to="/tableaubord"
                    className="flex items-center justify-center space-x-2 p-3 rounded-lg bg-emerald-400/10 text-emerald-400"
                    onClick={() => setIsOpen(false)}>
                    <Layout size={20} />
                    <span className="font-medium">{t('navbar.dashboard')}</span>
                  </Link>
                )}

                {/* Stats et profil pour utilisateurs connectés */}
                {user ? (
                  <>
                    <div className="flex justify-center space-x-3">
                      <div className="text-center bg-[#0e1d35] px-4 py-2 rounded-lg border border-white/10">
                        <span className="block text-sm font-medium text-white/60">{t('navbar.rank')}</span>
                        <strong className="text-2xl bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent">
                          {rank || 'N/A'}
                        </strong>
                      </div>
                      <div className="text-center bg-[#0e1d35] px-4 py-2 rounded-lg border border-white/10">
                        <span className="block text-sm font-medium text-white/60">{t('navbar.total')}</span>
                        <strong className="text-2xl bg-gradient-to-r from-blue-400 to-emerald-400 bg-clip-text text-transparent">
                          {totalPoints || '0'}
                        </strong>
                      </div>
                    </div>

                    {profileImageUrl && (
                      <div className="flex justify-center py-2">
                        <div className="relative group">
                          <div className="absolute -inset-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full blur opacity-25 group-hover:opacity-75 transition duration-300"></div>
                          <img
                            src={profileImageUrl}
                            alt="Profile"
                            className="relative w-16 h-16 rounded-full border-2 border-transparent group-hover:border-white/10 object-cover"
                          />
                        </div>
                      </div>
                    )}
                    
                    <button 
                      onClick={() => {
                        signOut(auth);
                        setIsOpen(false);
                      }}
                      className="flex items-center justify-center space-x-2 px-4 py-3 rounded-lg text-white/60 hover:text-white hover:bg-white/5 transition-colors duration-200"
                    >
                      <LogOut size={20} />
                      <span>{t('navbar.logout')}</span>
                    </button>
                  </>
                ) : (
                  <div className="flex flex-col space-y-2">
                    <Link to="/register"
                      className="block px-4 py-3 rounded-lg text-center text-white hover:bg-white/5 transition-colors duration-200"
                      onClick={() => setIsOpen(false)}>
                      {t('navbar.register')}
                    </Link>
                    <Link to="/login"
                      className="block px-4 py-3 rounded-lg text-center bg-gradient-to-r from-blue-400 to-emerald-400 text-white hover:opacity-90 transition-opacity duration-200"
                      onClick={() => setIsOpen(false)}>
                      {t('navbar.login')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;