import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
 collection,
 query,
 where,
 getDocs,
 updateDoc,
 arrayUnion,
 doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const Level10 = () => {
 const navigate = useNavigate();
 const [language, setLanguage] = useState(null);
 const [currentUser, setCurrentUser] = useState(null);
 const [loading, setLoading] = useState(true);
 const [success, setSuccess] = useState('');
 const [error, setError] = useState('');
 const [showConfetti, setShowConfetti] = useState(false);
 const [currentWord, setCurrentWord] = useState(null);
 const [userGuess, setUserGuess] = useState('');
 const [attempts, setAttempts] = useState(0);
 const [showNextButton, setShowNextButton] = useState(false);
 const [showHint, setShowHint] = useState(false);

 const maxAttempts = 3;
 const pointsToAdd = 500;
 const levelIdentifier = 'level10';
 const redirectUrl = '/niveau/11';

 const wordsList = {
   fr: [
     { word: "LAGUNE", hint: "Le joyau bleu de Bacalar" },
     { word: "FORTERESSE", hint: "Protection contre les pirates" },
     { word: "CENOTE", hint: "Puits naturel sacré" },
     { word: "STROMATOLITE", hint: "Fossile vivant de la lagune" },
     { word: "PIRATE", hint: "Ancien visiteur indésirable" }
   ],
   en: [
     { word: "LAGOON", hint: "The blue jewel of Bacalar" },
     { word: "FORTRESS", hint: "Protection from pirates" },
     { word: "CENOTE", hint: "Sacred natural well" },
     { word: "STROMATOLITE", hint: "Living fossil of the lagoon" },
     { word: "PIRATE", hint: "Ancient unwanted visitor" }
   ],
   es: [
     { word: "LAGUNA", hint: "La joya azul de Bacalar" },
     { word: "FORTALEZA", hint: "Protección contra piratas" },
     { word: "CENOTE", hint: "Pozo natural sagrado" },
     { word: "ESTROMATOLITO", hint: "Fósil viviente de la laguna" },
     { word: "PIRATA", hint: "Antiguo visitante no deseado" }
   ]
 };

 const mayanGlyphs = {
   'A': '★', 'B': '☆', 'C': '◆', 'D': '◇', 'E': '●',
   'F': '○', 'G': '▲', 'H': '△', 'I': '■', 'J': '□',
   'K': '⬟', 'L': '⬡', 'M': '⬢', 'N': '⬣', 'O': '⬤',
   'P': '⭓', 'Q': '⭔', 'R': '⬯', 'S': '⬰', 'T': '⬱',
   'U': '⬲', 'V': '⬳', 'W': '⬴', 'X': '⬵', 'Y': '⬶',
   'Z': '⬷'
 };
 
  const translations = {
    fr: {
      levelNumber: 'NIVEAU 010',
      title: 'Le Déchiffreur Maya',
      instruction: 'Déchiffrez le mot en utilisant le codex maya',
      verify: 'Vérifier',
      success: 'Félicitations ! Vous avez déchiffré le mot correctement !',
      error: 'Ce n\'est pas le bon mot. Essayez encore !',
      attemptsLeft: 'Tentatives restantes :',
      loading: 'Chargement...',
      nextLevel: 'Niveau Suivant',
      showHint: 'Voir l\'indice',
      hideHint: 'Cacher l\'indice',
      yourGuess: 'Votre réponse :',
      codex: 'Codex Maya',
      resetAttempts: 'Trop d\'essais... Un nouveau mot a été choisi.'
    },
    en: {
      levelNumber: 'LEVEL 010',
      title: 'The Mayan Decoder',
      instruction: 'Decode the word using the Mayan codex',
      verify: 'Check',
      success: 'Congratulations! You decoded the word correctly!',
      error: 'That\'s not the right word. Try again!',
      attemptsLeft: 'Attempts left:',
      loading: 'Loading...',
      nextLevel: 'Next Level',
      showHint: 'Show hint',
      hideHint: 'Hide hint',
      yourGuess: 'Your answer:',
      codex: 'Mayan Codex',
      resetAttempts: 'Too many attempts... A new word has been chosen.'
    },
    es: {
      levelNumber: 'NIVEL 010',
      title: 'El Decodificador Maya',
      instruction: 'Descifra la palabra usando el códice maya',
      verify: 'Verificar',
      success: '¡Felicitaciones! ¡Has descifrado la palabra correctamente!',
      error: 'Esa no es la palabra correcta. ¡Inténtalo de nuevo!',
      attemptsLeft: 'Intentos restantes:',
      loading: 'Cargando...',
      nextLevel: 'Siguiente Nivel',
      showHint: 'Ver pista',
      hideHint: 'Ocultar pista',
      yourGuess: 'Tu respuesta:',
      codex: 'Códice Maya',
      resetAttempts: 'Demasiados intentos... Se ha elegido una nueva palabra.'
    }
  };

 useEffect(() => {
   const unsubscribe = onAuthStateChanged(auth, async (user) => {
     if (user) {
       setCurrentUser(user);
       const teamsCollection = collection(db, 'teams');
       const q = query(teamsCollection, where('userId', '==', user.uid));
       const querySnapshot = await getDocs(q);

       if (!querySnapshot.empty) {
         const teamDoc = querySnapshot.docs[0];
         const teamData = teamDoc.data();
         setLanguage(teamData.language || 'fr');
         selectRandomWord(teamData.language || 'fr');
       } else {
         setLanguage('fr');
         selectRandomWord('fr');
       }
     } else {
       setLanguage('fr');
       selectRandomWord('fr');
     }
     setLoading(false);
   });

   return () => unsubscribe();
 }, []);

 const selectRandomWord = (lang) => {
   const randomIndex = Math.floor(Math.random() * wordsList[lang].length);
   setCurrentWord(wordsList[lang][randomIndex]);
   setUserGuess('');
   setShowHint(false);
 };

 const convertToGlyphs = (word) => {
   return word
     .toUpperCase()
     .split('')
     .map(letter => mayanGlyphs[letter] || letter)
     .join(' ');
 };

 const verifyWord = async () => {
   if (userGuess.toUpperCase() === currentWord.word) {
     setShowConfetti(true);
     setSuccess(translations[language].success);
     await updateTeamPoints();
   } else {
     setError(translations[language].error);
     setAttempts(prev => prev + 1);
     if (attempts + 1 >= maxAttempts) {
       setError(translations[language].resetAttempts);
       selectRandomWord(language);
       setAttempts(0);
     }
   }
   setUserGuess('');
 };

const updateTeamPoints = async () => {
   if (!currentUser) return;

   try {
     const teamsCollection = collection(db, 'teams');
     const q = query(teamsCollection, where('userId', '==', currentUser.uid));
     const querySnapshot = await getDocs(q);

     if (!querySnapshot.empty) {
       const teamDoc = querySnapshot.docs[0];
       const teamData = teamDoc.data();

       if (teamData.usedLevels?.includes(levelIdentifier)) {
         setError(translations[language].alreadyCompleted);
         setShowNextButton(true);
         return;
       }

       const teamRef = doc(db, 'teams', teamDoc.id);
       await updateDoc(teamRef, {
         points: (teamData.points || 0) + pointsToAdd,
         usedLevels: arrayUnion(levelIdentifier)
       });

       setShowNextButton(true);
     }
   } catch (err) {
     console.error('Erreur:', err);
     setError(translations[language].error);
   }
 };

 if (loading || !language || !currentWord) {
   return (
     <div className="min-h-screen p-4 bg-[#0e1d35] flex items-center justify-center">
       <div className="text-white">{translations[language]?.loading || 'Chargement...'}</div>
     </div>
   );
 }

 return (
   <div className="min-h-screen p-4 bg-[#0e1d35]">
     <div className="max-w-4xl mx-auto">
       <div className="relative mb-8">
         <h1 className="text-6xl md:text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
           {translations[language].levelNumber}
         </h1>
         <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
       </div>

<div className="text-center mb-8">
 <h2 className="text-2xl text-white mb-4">{translations[language].title}</h2>
 <p className="text-white/70 mb-4">{translations[language].instruction}</p>
 <p className="text-white/70">
   {translations[language].attemptsLeft} {maxAttempts - attempts}
 </p>
</div>

<div className="bg-[#1a2437]/60 p-6 rounded-xl border border-white/10 backdrop-blur-sm mb-8">
 <h3 className="text-xl text-white/90 mb-4 text-center">{translations[language].codex}</h3>
 <div className="grid grid-cols-6 md:grid-cols-13 gap-2 text-center">
   {Object.entries(mayanGlyphs).map(([letter, glyph]) => (
     <div key={letter} className="flex flex-col items-center p-2 bg-[#0e1d35] rounded-lg">
       <span className="text-3xl text-emerald-400">{glyph}</span>
       <span className="text-white/60 text-sm">{letter}</span>
     </div>
   ))}
 </div>
</div>

<div className="text-center mb-8">
 <div className="text-4xl font-bold text-white mb-4 tracking-wider">
   {convertToGlyphs(currentWord.word)}
 </div>
 <button
   onClick={() => setShowHint(!showHint)}
   className="text-white/60 hover:text-white transition-colors duration-200"
 >
   <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
   {showHint ? translations[language].hideHint : translations[language].showHint}
 </button>
 {showHint && (
   <p className="mt-2 text-emerald-400">{currentWord.hint}</p>
 )}
</div>
<div className="flex flex-col items-center gap-4">
 <input
   type="text"
   value={userGuess}
   onChange={(e) => setUserGuess(e.target.value)}
   className="w-full max-w-md p-3 rounded-lg bg-[#1a2437] text-white border border-white/20 focus:border-blue-400 focus:outline-none"
   placeholder={translations[language].yourGuess}
 />
 <button
   onClick={verifyWord}
   disabled={!userGuess || showNextButton}
   className="px-8 py-4 bg-blue-400 text-white rounded-lg hover:bg-emerald-400 
              transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
 >
   {translations[language].verify}
 </button>
</div>

{(success || error) && (
 <div className={`mt-4 text-center p-4 rounded-lg ${
   success ? 'bg-emerald-500/20 text-emerald-300' : 'bg-red-500/20 text-red-300'
 }`}>
   {success || error}
 </div>
)}

{showNextButton && (
 <div className="mt-4 text-center">
   <button
     onClick={() => navigate(redirectUrl)}
     className="px-8 py-4 bg-emerald-400 text-white rounded-lg 
                hover:bg-emerald-500 transition-colors duration-300
                transform hover:scale-105"
   >
     {translations[language].nextLevel}
   </button>
 </div>
)}

{showConfetti && (
 <Confetti
   width={window.innerWidth}
   height={window.innerHeight}
   recycle={false}
   numberOfPieces={200}
 />
)}

</div>
</div>
);
};

export default Level10;