import React from 'react';
import { useTranslation } from 'react-i18next';
import '../index.css'; // Import des styles globaux
import '../styles.css'; // Import des styles spécifiques
import EnhancedPresentation from '../components/EnhancedPresentation';


const Home = () => {
  const { t } = useTranslation();

  return (
    <>
    

      <EnhancedPresentation />
    </>
  );
};

export default Home;
