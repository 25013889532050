import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Importer les instances Firebase
import { auth, db, storage } from '../firebase.jsx';

// Importer les fonctions Firebase nécessaires
import { onAuthStateChanged } from 'firebase/auth';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';

const translations = {
  fr: {
    levelNumber: 'NIVEAU 001',
    missionTitle: 'Créez votre image de profil',
    uploadLabel: 'Envoyer ici votre image :',
    descriptionLabel: 'Description de votre image :',
    descriptionPlaceholder: 'Décrivez votre image (max 500 mots)',
    errorNoFile: 'Veuillez sélectionner une image et ajouter une description.',
    errorNotLoggedIn: 'Vous devez être connecté pour soumettre une image.',
    errorAlreadyUsed: 'Vous avez déjà utilisé ce formulaire pour ce niveau.',
    errorInternal: 'Erreur interne. Veuillez contacter le support.',
    errorUpload: "Erreur lors du téléchargement ou de la mise à jour des points. Veuillez réessayer.",
    submitButton: 'Soumettre',
  },
  en: {
    levelNumber: 'LEVEL 001',
    missionTitle: 'Create Your Profile Picture',
    uploadLabel: 'Upload your image here:',
    descriptionLabel: 'Description of your image:',
    descriptionPlaceholder: 'Describe your image (max 500 words)',
    errorNoFile: 'Please select an image and add a description.',
    errorNotLoggedIn: 'You must be logged in to submit an image.',
    errorAlreadyUsed: 'You have already used this form for this level.',
    errorInternal: 'Internal error. Please contact support.',
    errorUpload: 'Error during upload or point update. Please try again.',
    submitButton: 'Submit',
  },
  es: {
    levelNumber: 'NIVEL 001',
    missionTitle: 'Crea tu imagen de perfil',
    uploadLabel: 'Sube tu imagen aquí:',
    descriptionLabel: 'Descripción de tu imagen:',
    descriptionPlaceholder: 'Describe tu imagen (máx. 500 palabras)',
    errorNoFile: 'Por favor selecciona una imagen y añade una descripción.',
    errorNotLoggedIn: 'Debes iniciar sesión para enviar una imagen.',
    errorAlreadyUsed: 'Ya has usado este formulario para este nivel.',
    errorInternal: 'Error interno. Por favor contacta al soporte.',
    errorUpload: 'Error durante la carga o actualización de puntos. Inténtalo de nuevo.',
    submitButton: 'Enviar',
  },
};

const Level1 = () => {
  const fileInputRef = useRef();
  const descriptionRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [language, setLanguage] = useState('fr'); // Langue par défaut
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  // Constantes nécessaires
  const pointsToAdd = 500;
  const bonusPointsToAdd = 0;
  const isBonus = false;
  const redirectUrl = '/niveau/2';
  const currentLevel = 'level1';

  useEffect(() => {
    // Récupérer la langue préférée de l'utilisateur
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const userId = user.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();
          setLanguage(teamData.language || 'fr');
        }
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const t = translations[language]; // Texte traduit selon la langue

  const uploadImage = async (event) => {
    event.preventDefault();

    const file = fileInputRef.current.files[0];
    const description = descriptionRef.current.value;

    if (!file || !description) {
      setErrorMessage(t.errorNoFile);
      return;
    }

    try {
      const user = currentUser;

      if (!user) {
        setErrorMessage(t.errorNotLoggedIn);
        return;
      }

      const userId = user.uid;
      const storagePath = `teamFlags/${userId}/${file.name}`;
      const storageReference = storageRef(storage, storagePath);

      // Téléchargement du fichier
      await uploadBytes(storageReference, file);

      // Obtenir l'URL
      const downloadURL = await getDownloadURL(storageReference);

      // Mise à jour des données Firestore
      await updateTeamData(userId, downloadURL, description);

      // Redirection
      navigate(redirectUrl);
    } catch (error) {
      console.error('Erreur lors du traitement:', error);
      setErrorMessage(t.errorUpload);
    }
  };

  const updateTeamData = async (userId, imageUrl, description) => {
    const teamsCollection = collection(db, 'teams');
    const q = query(teamsCollection, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const teamDoc = querySnapshot.docs[0];
      const teamData = teamDoc.data();

      if (teamData.usedLevels && teamData.usedLevels.includes(currentLevel)) {
        setErrorMessage(t.errorAlreadyUsed);
      } else {
        let updatedPoints = teamData.points || 0;
        let updatedBonusPoints = teamData.bonusPoints || 0;

        if (isBonus) {
          updatedBonusPoints += bonusPointsToAdd;
        } else {
          updatedPoints += pointsToAdd;
        }

        const teamDocRef = doc(db, 'teams', teamDoc.id);
        await updateDoc(teamDocRef, {
          points: updatedPoints,
          bonusPoints: updatedBonusPoints,
          profileImageUrl: imageUrl,
          imageDescription: description,
          usedLevels: arrayUnion(currentLevel),
        });
      }
    } else {
      console.error("Aucun document trouvé.");
      setErrorMessage(t.errorInternal);
    }
  };

  return (
    <div className="min-h-screen p-6 mt-8 md:mt-4">
      <div className="max-w-2xl mx-auto">
        {/* Numéro de niveau stylisé */}
        <div className="relative">
          <h1 className="text-8xl font-black text-center mb-2  text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
            {translations[language].levelNumber}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        {/* Titre de la mission avec effet */}
        <h2 className="text-2xl font-bold text-center mb-12 relative">
          <span className="bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text mt-8 md:mt-4">
            {translations[language].missionTitle}
          </span>
          <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full"></div>
        </h2>

        <form onSubmit={uploadImage} className="space-y-8 backdrop-blur-sm bg-white/5 p-8 rounded-xl shadow-xl">
          <div>
            <label className="block mb-2 text-lg text-white/90 font-medium">
              {t.uploadLabel}
            </label>
            <input
  type="file"
  id="teamFlag"
  accept="image/*"
  className="w-full p-3 bg-[#1a2437]/60 rounded-lg text-white/90 border border-white/10 hover:bg-[#1a2437]/80 transition-all duration-300"
  required
  ref={fileInputRef}
/>
          </div>

          <div>
            <label className="block mb-2 text-lg text-white/90 font-medium">
              {t.descriptionLabel}
            </label>
            <textarea
              id="imageDescription"
              maxLength={500}
              rows={4}
              className="w-full p-3 bg-[#1a2437]/60 rounded-lg text-white/90 border border-white/10 hover:bg-[#1a2437]/80 transition-all duration-300"
              placeholder={t.descriptionPlaceholder}
              required
              ref={descriptionRef}
            />
          </div>

          {errorMessage && (
            <div className="text-red-400 text-center p-3 bg-red-900/20 rounded-lg backdrop-blur-sm">
              {errorMessage}
            </div>
          )}

          <button
            type="submit"
            className="w-full py-4 bg-[#0e1d35]/60 text-white rounded-lg 
                     hover:bg-[#0e1d35]/40 transition-all duration-300 ease-in-out
                     border border-white/10 backdrop-blur-sm
                     transform hover:scale-[1.02] hover:shadow-lg
                     active:scale-[0.98]"
          >
            {t.submitButton}
          </button>
        </form>

        {/* Message d'information avec style */}
        <div className="mt-8 text-sm text-center text-white/70 bg-white/5 p-6 rounded-lg backdrop-blur-sm">
          <p className="font-medium text-white/90 mb-4">
            Important : Avertissement sur l'Utilisation des Images de Profil
          </p>
          <p className="mb-2">
            Veuillez noter que l'image de profil que vous soumettez pourrait être rendue publique 
            sur le tableau des points du rallye, apparaître sur les réseaux sociaux du Rallye et 
            d'Adorable Sailing, ainsi que potentiellement être utilisée pour des impressions sur certains prix.
          </p>
          <p>
            Nous nous réservons le droit de refuser ou de supprimer toute image dont le contenu 
            serait jugé inapproprié ou sensible. Ce rallye est un événement familial et joyeux.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Level1;