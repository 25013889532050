import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { useTranslation } from 'react-i18next';
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PublicLeaderboard from './pages/PublicLeaderboard';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LevelPage from './pages/LevelPage';
import EnhancedPresentation from "./components/EnhancedPresentation";
import ProtectedLevelRoute from './components/ProtectedLevelRoute';
import AdminMessages from './components/AdminMessages';
import ForgotPassword from './pages/ForgotPassword';
import './app.css';

// Composant pour protéger la route admin
const ProtectedAdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAdmin(user?.uid === 'TvkedO2qXOag5RSiWZifi7PuEW23');
      setChecking(false);
    });

    return () => unsubscribe();
  }, []);

  if (checking) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return isAdmin ? children : <Navigate to="/" replace />;
};

const App = () => {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'fr'
  });

  // Effet pour le scroll vers le haut à chaque changement de route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center" style={{ backgroundColor: '#0e1d35' }}>
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-[#0e1d35]">
      <Navbar setLanguage={handleLanguageChange} user={user} language={language} />
      <main className="flex-grow pb-8">
        <Routes>
          <Route path="/" element={<Home language={language} />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/publicleaderboard" element={<PublicLeaderboard language={language} />} />
          <Route path="/tableaubord" element={<Dashboard user={user} language={language} />} />
          <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />

          <Route 
            path="/messages" 
            element={
              <ProtectedAdminRoute>
                <AdminMessages />
              </ProtectedAdminRoute>
            } 
          />
          <Route 
            path="/niveau/:level" 
            element={
              <ProtectedLevelRoute>
                <LevelPage />
              </ProtectedLevelRoute>
            } 
          />
          <Route path="/enhanced-presentation" element={<EnhancedPresentation />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;