import React, { useState } from 'react';
import { auth } from '../firebase';
import { getFirestore } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

const TeamCreation = ({ email, onSubmit }) => {
  const { t, i18n } = useTranslation();

  const [teamName, setTeamName] = useState('');
  const [teamCountry, setTeamCountry] = useState('');
  const [teamPlayers, setTeamPlayers] = useState(1);
  const [tripDate, setTripDate] = useState('');
  const [players, setPlayers] = useState([{ playerName: '', isChild: false, childAge: '' }]);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const db = getFirestore();

  const handlePlayerChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;
    setPlayers(updatedPlayers);
  };

  const handleTeamPlayersChange = (e) => {
    const newCount = parseInt(e.target.value, 10);
    setTeamPlayers(newCount);

    const updatedPlayers = Array.from({ length: newCount }, (_, i) => {
      return players[i] || { playerName: '', isChild: false, childAge: '' };
    });
    setPlayers(updatedPlayers);
  };

  const handleTeamCreation = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError('');

    if (!teamName || !teamCountry || !tripDate) {
      setError(t('teamCreation.errorFieldsRequired'));
      setIsSubmitting(false);
      return;
    }

    try {
      const formData = {
        name: teamName,
        country: teamCountry,
        players: players,
        tripDate: tripDate,
        language: i18n.language
      };

      await onSubmit(formData);
      
    } catch (error) {
      console.error('Erreur lors de la création de l\'équipe:', error);
      setError(t('teamCreation.errorSaving'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputBaseStyles = `w-full p-3 rounded-lg
                          bg-[#0e1d35] text-white
                          border border-white/20
                          focus:outline-none focus:border-blue-400
                          placeholder-white/50
                          transition-colors duration-200
                          disabled:opacity-50
                          disabled:cursor-not-allowed`;

  const labelStyles = `block text-white/80 mb-2 font-medium`;

  return (
    <div>
      <h3 className="text-xl font-bold mb-6 text-white">{t('teamCreation.stepTitle')}</h3>
      
      <form onSubmit={handleTeamCreation} className="space-y-6">
        <div>
          <label htmlFor="team-name" className={labelStyles}>
            {t('teamCreation.teamNameLabel')}
          </label>
          <input
            type="text"
            id="team-name"
            placeholder={t('teamCreation.teamNamePlaceholder')}
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            required
            className={inputBaseStyles}
            disabled={isSubmitting}
          />
        </div>

        <div>
          <label htmlFor="team-country" className={labelStyles}>
            {t('teamCreation.teamCountryLabel')}
          </label>
          <input
            type="text"
            id="team-country"
            placeholder={t('teamCreation.teamCountryPlaceholder')}
            value={teamCountry}
            onChange={(e) => setTeamCountry(e.target.value)}
            required
            className={inputBaseStyles}
            disabled={isSubmitting}
          />
        </div>

        <div>
          <label htmlFor="team-players" className={labelStyles}>
            {t('teamCreation.teamPlayersLabel')}
          </label>
          <input
            type="number"
            id="team-players"
            min="1"
            value={teamPlayers}
            onChange={handleTeamPlayersChange}
            required
            className={`${inputBaseStyles} [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
            disabled={isSubmitting}
          />
        </div>

        {players.map((player, index) => (
          <div key={index} className="p-4 rounded-lg border border-white/10 bg-[#0e1d35]/40 space-y-4">
            <label className={labelStyles}>
              {index === 0 
                ? t('teamCreation.captainNameLabel') 
                : t('teamCreation.playerNameLabel', { index: index + 1 })}
            </label>
            <input
              type="text"
              placeholder={index === 0 
                ? t('teamCreation.captainNamePlaceholder') 
                : t('teamCreation.playerNamePlaceholder', { index: index + 1 })}
              value={player.playerName}
              onChange={(e) => handlePlayerChange(index, 'playerName', e.target.value)}
              required
              className={inputBaseStyles}
              disabled={isSubmitting}
            />

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id={`isChild-${index}`}
                checked={player.isChild}
                onChange={(e) => handlePlayerChange(index, 'isChild', e.target.checked)}
                className="w-4 h-4 rounded bg-[#0e1d35] border-white/20 text-blue-400 
                         focus:ring-2 focus:ring-blue-400/20"
                disabled={isSubmitting}
              />
              <label htmlFor={`isChild-${index}`} className="text-white/80">
                {t('teamCreation.isChildLabel')}
              </label>
            </div>

            {player.isChild && (
              <div className="ml-4">
                <label className={labelStyles}>{t('teamCreation.childAgeLabel')}</label>
                <input
                  type="number"
                  min="0"
                  max="17"
                  placeholder={t('teamCreation.childAgePlaceholder')}
                  value={player.childAge}
                  onChange={(e) => handlePlayerChange(index, 'childAge', e.target.value)}
                  className={`${inputBaseStyles} [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                  required
                  disabled={isSubmitting}
                />
              </div>
            )}
          </div>
        ))}

        <div>
          <label htmlFor="trip-date" className={labelStyles}>
            {t('teamCreation.tripDateLabel')}
          </label>
          <input
            type="date"
            id="trip-date"
            value={tripDate}
            onChange={(e) => setTripDate(e.target.value)}
            required
            className={inputBaseStyles}
            disabled={isSubmitting}
          />
        </div>

        <button 
          type="submit" 
          disabled={isSubmitting}
          className="w-full py-4 px-6 rounded-lg bg-emerald-400 text-white
                   hover:bg-emerald-500 transition-all duration-300
                   border border-white/10 transform hover:scale-[1.02]
                   disabled:opacity-50 disabled:cursor-not-allowed
                   active:scale-[0.98]"
        >
          {isSubmitting ? t('teamCreation.submitting') : t('teamCreation.submitButton')}
        </button>
      </form>

      {error && (
        <div className="mt-4 p-4 rounded-lg bg-red-500/20 text-red-300 border border-red-500/20">
          {error}
        </div>
      )}
    </div>
  );
};

export default TeamCreation;