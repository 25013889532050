import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Translations intégrées
const translations = {
  fr: {
    title: 'Réinitialisation du mot de passe',
    emailPlaceholder: 'Entrez votre adresse email',
    submitButton: 'Réinitialiser le mot de passe',
    successMessage: 'Un email de réinitialisation a été envoyé. Vérifiez votre boîte de réception.',
    userNotFound: 'Aucun compte n\'existe avec cette adresse email',
    invalidEmail: 'L\'adresse email n\'est pas valide',
    genericError: 'Une erreur est survenue. Veuillez réessayer.',
    backToLogin: 'Retour à la connexion',
    loading: 'Chargement...'
  },
  en: {
    title: 'Password Reset',
    emailPlaceholder: 'Enter your email address',
    submitButton: 'Reset Password',
    successMessage: 'A password reset email has been sent. Please check your inbox.',
    userNotFound: 'No account exists with this email address',
    invalidEmail: 'Invalid email address',
    genericError: 'An error occurred. Please try again.',
    backToLogin: 'Back to Login',
    loading: 'Loading...'
  },
  es: {
    title: 'Restablecer contraseña',
    emailPlaceholder: 'Introduce tu correo electrónico',
    submitButton: 'Restablecer contraseña',
    successMessage: 'Se ha enviado un correo electrónico para restablecer la contraseña. Por favor, revisa tu bandeja de entrada.',
    userNotFound: 'No existe una cuenta con este correo electrónico',
    invalidEmail: 'Dirección de correo electrónico no válida',
    genericError: 'Se ha producido un error. Por favor, inténtalo de nuevo.',
    backToLogin: 'Volver al inicio de sesión',
    loading: 'Cargando...'
  }
};

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // Fonction pour obtenir la traduction en fonction de la langue actuelle
  const t = (key) => {
    const currentLanguage = i18n.language || 'fr';
    return translations[currentLanguage]?.[key] || translations['fr'][key];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setTimeout(() => {
        navigate('/connexion');
      }, 5000);
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          setError(t('userNotFound'));
          break;
        case 'auth/invalid-email':
          setError(t('invalidEmail'));
          break;
        default:
          setError(t('genericError'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-md mx-auto mt-8 md:mt-4">
        <div className="relative">
          <h1 className="text-4xl font-black text-center mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
            {t('title')}
          </h1>
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
        </div>

        <div className="mt-8 backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10">
          {success ? (
            <div className="p-4 rounded-lg bg-green-500/20 text-green-300 border border-green-500/20">
              {t('successMessage')}
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-white/90 font-medium mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 rounded-lg bg-[#1a2437]/60 text-white border border-white/10 focus:border-blue-400/50 focus:ring-2 focus:ring-blue-400/20 transition-all duration-200"
                  placeholder={t('emailPlaceholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              {error && (
                <div className="p-4 rounded-lg bg-red-500/20 text-red-300 border border-red-500/20">
                  {error}
                </div>
              )}

              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-4 bg-blue-400 text-white rounded-lg hover:bg-blue-500 transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    {t('loading')}
                  </span>
                ) : (
                  t('submitButton')
                )}
              </button>

              <div className="text-center text-white/60">
                <button
                  type="button"
                  onClick={() => navigate('/connexion')}
                  className="text-blue-400 hover:text-blue-300"
                >
                  {t('backToLogin')}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;