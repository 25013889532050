import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCompass, faSailboat, faTrophy } from '@fortawesome/free-solid-svg-icons';
import imageSlide1 from '../assets/rallytresorsbacalar.webp';
import imageSlide2 from '../assets/formYourCrew.webp';
import imageSlide3 from '../assets/challenge.webp';
import imageSlide4 from '../assets/theProcess2.webp';
import imageSlide5 from '../assets/the-price.webp';

const IntroSection = () => {
  const { t } = useTranslation();
  const highlights = [
    {
      icon: faGlobe,
      title: t('presentation.highlights.online.title'),
      description: t('presentation.highlights.online.description'),
      gradient: "from-blue-400 via-sky-400 to-blue-500"
    },
    {
      icon: faCompass,
      title: t('presentation.highlights.city.title'),
      description: t('presentation.highlights.city.description'),
      gradient: "from-amber-400 via-orange-400 to-amber-500"
    },
    {
      icon: faSailboat,
      title: t('presentation.highlights.sailing.title'),
      description: t('presentation.highlights.sailing.description'),
      gradient: "from-emerald-400 via-teal-400 to-emerald-500"
    },
    {
      icon: faTrophy,
      title: t('presentation.highlights.rewards.title'),
      description: t('presentation.highlights.rewards.description'),
      gradient: "from-yellow-400 via-amber-400 to-yellow-500"
    }
  ];

  return (
    <div className="mb-24">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {highlights.map((item, index) => (
          <div key={index} className="bg-[#1a2437]/60 border border-white/10 p-6 rounded-xl transform hover:scale-105 transition-all duration-300">
            <div className="flex flex-col items-center text-center space-y-4">
              <div className={`p-3 rounded-full bg-gradient-to-r ${item.gradient}`}>
                <FontAwesomeIcon icon={item.icon} className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-xl font-bold text-white">{item.title}</h3>
              <p className="text-white/70">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const highlightWords = (text) => {
  const keywords = [
    'Mayas', 'Maya', 'Pirate', 'Piratas', 'Mexique', 'Bacalar', 'Aventure', 'Lagune', 'Voile', 'Velero', 'Equipos', 'Equipo', 
    'Excursiones', 'Excursions', 'Excursion', 'Partie Virtuelle', 'Partie Physique', 'Partie en voilier', '¡De la web a la vela, la aventura comienza en casa!', 'De la toile à la voile, l\aventure commence chez-vous!'
  ];
  
  let highlightedText = text;
  keywords.forEach(word => {
    const regex = new RegExp(`(${word})`, 'gi');
    highlightedText = highlightedText.replace(regex, '<span class="text-emerald-400 font-semibold">$1</span>');
  });
  
  return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

const SectionTitle = ({ children }) => (
  <h2 className="text-4xl md:text-5xl font-black text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-amber-400 to-emerald-400 mb-6 pb-4">
    {children}
  </h2>
);

const ContentSection = ({ title, content, isReversed, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const paragraphs = content.split('\n\n').filter(p => p.trim());
  
  const sectionImages = {
    0: imageSlide1,
    1: imageSlide2,
    2: imageSlide3,
    3: imageSlide4,
    4: imageSlide5
  };
  
  return (
    <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} items-start gap-8 mb-16`}>
      <div className="w-full md:w-1/2 space-y-6">
        <div className="bg-[#1a2437]/60 p-8 rounded-xl border border-white/10">
          <SectionTitle>{title}</SectionTitle>
          <div className={`prose prose-invert max-w-none ${!isExpanded ? 'line-clamp-6' : ''}`}>
            {paragraphs.map((paragraph, idx) => (
              <div key={idx} className="text-white/90 text-xl leading-relaxed mb-6">
                {highlightWords(paragraph)}
              </div>
            ))}
          </div>
          {paragraphs.length > 2 && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="flex items-center gap-2 text-blue-400 hover:text-amber-400 transition-colors duration-200 mt-4 text-lg"
            >
              {isExpanded ? 'Voir moins' : 'Voir plus'}
              <ChevronRight className={`w-4 h-4 transform transition-transform duration-200 ${isExpanded ? 'rotate-90' : ''}`} />
            </button>
          )}
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <div className="rounded-xl border border-white/10 overflow-hidden">
          <img 
            src={sectionImages[index]} 
            alt={`Section ${index + 1}`}
            className="w-full object-cover"
            style={{ maxHeight: '600px' }}
          />
        </div>
      </div>
    </div>
  );
};

const PriceSection = () => {
  const { t } = useTranslation();
  
  return (
    <div className="bg-[#1a2437]/60 p-8 rounded-xl border border-white/10 text-center mb-16">
      <SectionTitle>
        {t('presentation.specialOffer')}
      </SectionTitle>
      <div className="mb-8">
        <span className="text-gray-400 line-through text-2xl block mb-2">{t('presentation.regularPrice')}</span>
        <span className="text-6xl font-bold text-amber-400">{t('presentation.price')}</span>
      </div>
      <div className="text-white/90 text-xl">
        {highlightWords(t('presentation.slides.5'))}
      </div>
    </div>
  );
};

const EnhancedPresentation = () => {
  const { t } = useTranslation();

  const sections = [
    { title: t('presentation.section1Title'), content: t('presentation.slides.0') },
    { title: t('presentation.section2Title'), content: t('presentation.slides.1') },
    { title: t('presentation.section3Title'), content: t('presentation.slides.2') },
    { title: t('presentation.section4Title'), content: t('presentation.slides.3') },
    { title: t('presentation.section5Title'), content: t('presentation.slides.4') }
  ];
  
  return (
    <div className="min-h-screen bg-[#0e1d35] pt-16 pb-24">
      <div className="max-w-7xl mx-auto px-4">
        <div className="relative py-16 text-center mb-24">
          <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-400 via-amber-400 to-emerald-400 opacity-10 blur-xl -z-10 rounded-full" />
          <h1 className="text-6xl md:text-8xl font-black text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-amber-400 to-emerald-400 mb-8 pb-4">
            {t('presentation.title')}
          </h1>
          <p className="text-3xl md:text-4xl text-white/95 max-w-4xl mx-auto leading-relaxed font-semibold">
            {t('presentation.subtitle2')}
          </p>
        </div>

        <IntroSection />
        
        <div className="space-y-32">
          {sections.map((section, index) => (
            <ContentSection
              key={index}
              title={section.title}
              content={section.content}
              isReversed={index % 2 !== 0}
              index={index}
            />
          ))}
          
          <PriceSection />
          
          <div className="text-center">
            <Link to="/register">
              <button className="bg-gradient-to-r from-blue-400 via-amber-400 to-emerald-400 text-white text-2xl px-16 py-8 rounded-xl hover:opacity-90 transition-opacity duration-300">
                <span className="flex items-center gap-3">
                  {t('presentation.register')}
                  <ChevronRight className="w-8 h-8" />
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnhancedPresentation;