import React, { useState } from 'react';

const ProfileImage = ({ imageUrl, name = '?', className = '', size = 'w-10 h-10' }) => {
  const [hasError, setHasError] = useState(false);

  // Si l'image échoue, montre un cercle avec la première lettre
  if (!imageUrl || hasError) {
    return (
      <div className={`${size} rounded-full bg-gradient-to-r from-blue-400 to-emerald-400 flex items-center justify-center text-white font-bold ${className}`}>
        {name.charAt(0).toUpperCase()}
      </div>
    );
  }

  // Sinon montre l'image
  return (
    <img 
      src={imageUrl} 
      alt="Profile" 
      className={`${size} rounded-full object-cover ${className}`}
      onError={() => setHasError(true)} 
    />
  );
};

export default ProfileImage;