import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ email, teamData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch("https://us-central1-rallye-bacalar.cloudfunctions.net/createCheckoutSession", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerEmail: email,
          teamId: teamData?.userId,
          successUrl: `${window.location.origin}/register?session_id={CHECKOUT_SESSION_ID}&team_id=${teamData?.userId}`,
          cancelUrl: `${window.location.origin}/register`
        }),
      });

      if (!response.ok) {
        throw new Error(t('checkoutForm.sessionError'));
      }

      const { sessionUrl } = await response.json();
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error:', error);
      setError(t('checkoutForm.paymentErrorRetry'));
    } finally {
      setLoading(false);
    }
  };

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  };

  return (
    <div className="space-y-8">
      {/* En-tête du récapitulatif */}
      <h2 className="text-2xl font-bold text-white mb-6">
        {t('checkoutForm.summary')}
      </h2>

      {/* Résumé de la commande */}
      <div className="bg-[#0e1d35] rounded-lg border border-white/10 overflow-hidden">
        {/* Prix et détails */}
        <div className="p-6 space-y-4">
          <div className="flex justify-between items-center text-white/80">
            <span>{t('checkoutForm.description')}</span>
            <span className="font-semibold text-white">{formatPrice(500)}</span>
          </div>

          <div className="border-t border-white/10 pt-4">
            <div className="flex justify-between items-center">
              <span className="text-white/80">{t('checkoutForm.totalAmount')}</span>
              <span className="text-xl font-bold text-white">{formatPrice(500)}</span>
            </div>
          </div>
        </div>

        {/* Détails de l'équipe */}
        <div className="bg-[#1a2437] border-t border-white/10 p-6">
          <h3 className="text-lg font-semibold text-white mb-4">
            {t('checkoutForm.teamDetails')}
          </h3>
          
          <div className="grid gap-3 text-white/80">
            <div className="flex justify-between">
              <span>{t('checkoutForm.teamName')}</span>
              <span className="text-white">{teamData?.name}</span>
            </div>
            <div className="flex justify-between">
              <span>{t('checkoutForm.country')}</span>
              <span className="text-white">{teamData?.country}</span>
            </div>
            <div className="flex justify-between">
              <span>{t('checkoutForm.players')}</span>
              <span className="text-white">{teamData?.players?.length}</span>
            </div>
            <div className="flex justify-between">
              <span>{t('checkoutForm.tripDate')}</span>
              <span className="text-white">
                {new Date(teamData?.tripDate).toLocaleDateString(teamData?.language || 'fr')}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Bouton de paiement */}
      <button
        onClick={handleCheckout}
        disabled={loading}
        className="w-full py-4 bg-emerald-400 text-white rounded-lg 
                 hover:bg-emerald-500 transition-all duration-300
                 border border-white/10 transform hover:scale-[1.02]
                 disabled:opacity-50 disabled:cursor-not-allowed
                 active:scale-[0.98]
                 flex items-center justify-center gap-2"
      >
        {loading ? (
          <>
            <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
            <span>{t('checkoutForm.processing')}</span>
          </>
        ) : (
          t('checkoutForm.proceedToPayment')
        )}
      </button>

      {/* Message d'erreur */}
      {error && (
        <div className="p-4 rounded-lg bg-red-500/20 text-red-300 border border-red-500/20">
          {error}
        </div>
      )}

      {/* Infos de sécurité */}
      <div className="text-center space-y-2">
        <p className="text-white/60">{t('checkoutForm.taxInfo')}</p>
        <div className="flex items-center justify-center gap-2 text-white/60">
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"/>
          </svg>
          <span>{t('checkoutForm.securePayment')}</span>
        </div>
        <p className="text-white/60">{t('checkoutForm.stripeInfo')}</p>
      </div>
    </div>
  );
};

export default CheckoutForm;