// src/games/Level4.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase.jsx';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSailboat, faQuestion } from '@fortawesome/free-solid-svg-icons';

const Level4 = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [hasFlippedCard, setHasFlippedCard] = useState(false);
  const [lockBoard, setLockBoard] = useState(false);
  const [firstCard, setFirstCard] = useState(null);
  const [secondCard, setSecondCard] = useState(null);
  const [matchedCards, setMatchedCards] = useState(0);
  const [timeLeft, setTimeLeft] = useState(180);
  const timerRef = React.useRef(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false); // Nouvel état pour gérer l'échec
  const [showConfetti, setShowConfetti] = useState(false);
  const [message, setMessage] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useState('fr'); // Par défaut en français
  const [showContinueButton, setShowContinueButton] = useState(false);


  const currentLevel = 'level4';
  const pointsToAdd = 300;
  const redirectUrl = '/niveau/5';
  const alreadyUsedRedirectUrl = '/niveau/5';

  const translations = {
    fr: {
      continueButton: 'Continuer',
      levelNumber: 'NIVEAU 004',
      missionTitle: 'Niveau 4 Jeux de Mémoire',
      startButton:
        'Le temps commence dès que vous appuyez sur ce bouton, bonne chance !',
      timeLeft: 'Temps restant:',
      congratulations: 'Félicitations ! Vous avez trouvé toutes les paires !',
      timeUp: "Temps écoulé ! Vous n'avez pas terminé à temps.",
      alreadyCompleted:
        "Vous avez déjà complété ce niveau. Les points ne seront pas ajoutés à nouveau. Redirection vers le niveau suivant...",
      successMessage:
        'Félicitations ! Vous avez complété ce niveau et gagné 300 points.',
      errorUserNotFound:
        "Aucun document d'équipe trouvé pour l'utilisateur.",
      errorOccurred: 'Une erreur est survenue. Veuillez réessayer.',
      userNotLoggedIn: 'Utilisateur non connecté.',
      replay: 'Rejouer',
      wrongLetters: 'Mauvaises lettres : ',
    },
    en: {
      continueButton: 'Continue',
      levelNumber: 'LEVEL 004',
      missionTitle: 'Sailboat Memory Game',
      startButton:
        'Time starts as soon as you press this button, good luck!',
      timeLeft: 'Time left:',
      congratulations: 'Congratulations! You have found all the pairs!',
      timeUp: "Time's up! You didn't finish in time.",
      alreadyCompleted:
        'You have already completed this level. Points will not be added again. Redirecting to the next level...',
      successMessage:
        'Congratulations! You have completed this level and earned 300 points.',
      errorUserNotFound: 'No team document found for the user.',
      errorOccurred: 'An error occurred. Please try again.',
      userNotLoggedIn: 'User not logged in.',
      replay: 'Replay',
      wrongLetters: 'Wrong letters: ',
    },
    es: {
      continueButton: 'Continuar',
      levelNumber: 'NIVEL 004',
      missionTitle: 'Juego de Memoria del Velero',
      startButton:
        '¡El tiempo comienza tan pronto como presiones este botón, buena suerte!',
      timeLeft: 'Tiempo restante:',
      congratulations: '¡Felicidades! ¡Has encontrado todos los pares!',
      timeUp: '¡Tiempo agotado! No terminaste a tiempo.',
      alreadyCompleted:
        'Ya has completado este nivel. Los puntos no se agregarán de nuevo. Redirigiendo al siguiente nivel...',
      successMessage:
        '¡Felicidades! Has completado este nivel y ganado 300 puntos.',
      errorUserNotFound: 'No se encontró ningún documento de equipo para el usuario.',
      errorOccurred: 'Ocurrió un error. Por favor, inténtalo de nuevo.',
      userNotLoggedIn: 'Usuario no conectado.',
      replay: 'Repetir',
      wrongLetters: 'Letras incorrectas: ',
    },
  };

  const initialCardsData = [
    { framework: 'sailboat1', color: 'white' },
    { framework: 'sailboat2', color: 'yellow' },
    { framework: 'sailboat3', color: 'pink' },
    { framework: 'sailboat4', color: 'black' },
    { framework: 'sailboat5', color: 'orange' },
    { framework: 'sailboat6', color: 'purple' },
    { framework: 'sailboat7', color: 'green' },
    { framework: 'sailboat8', color: 'brown' },
    { framework: 'sailboat9', color: 'cyan' },
    { framework: 'sailboat10', color: 'navy' },
    { framework: 'sailboat11', color: 'teal' },
    { framework: 'sailboat12', color: 'magenta' },
    { framework: 'sailboat13', color: 'maroon' },
    { framework: 'sailboat14', color: 'lime' },
    { framework: 'sailboat15', color: 'gold' },
  ];

  const initializeCards = () => {
    const cards = initialCardsData.flatMap((data, index) => [
      {
        id: index * 2,
        framework: data.framework,
        color: data.color,
        flipped: false,
        matched: false,
      },
      {
        id: index * 2 + 1,
        framework: data.framework,
        color: data.color,
        flipped: false,
        matched: false,
      },
    ]);
    return shuffleArray(cards);
  };

  const shuffleArray = (array) => {
    const arr = array.slice();
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);

        // Récupérer les données de l'équipe
        const userId = user.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();
          setLanguage(teamData.language || 'fr'); // Définir la langue à partir des données de l'équipe
        } else {
          setLanguage('fr'); // Langue par défaut si aucun document d'équipe trouvé
        }
      } else {
        setCurrentUser(null);
        setLanguage('fr'); // Langue par défaut si utilisateur non connecté
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (gameStarted) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            setMessage(translations[language].timeUp);
            setGameOver(true);
            setGameStarted(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timerRef.current);
    }
  }, [gameStarted, language]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const startGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setTimeLeft(180);
    const shuffledCards = initializeCards();
    setCards(shuffledCards);
    setMatchedCards(0);
    setHasFlippedCard(false);
    setLockBoard(false);
    setFirstCard(null);
    setSecondCard(null);
    setMessage('');
    setShowConfetti(false);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleCardClick = (card) => {
    if (lockBoard) return;
    if (card === firstCard) return;
    if (card.flipped || card.matched) return;

    const flippedCard = { ...card, flipped: true };
    const newCards = cards.map((c) => (c.id === card.id ? flippedCard : c));
    setCards(newCards);

    if (!hasFlippedCard) {
      setHasFlippedCard(true);
      setFirstCard(flippedCard);
      return;
    }

    setSecondCard(flippedCard);
    setLockBoard(true);

    if (firstCard.framework === flippedCard.framework) {
      const updatedCards = newCards.map((c) =>
        c.framework === flippedCard.framework
          ? { ...c, matched: true }
          : c
      );
      setCards(updatedCards);
      setMatchedCards((prev) => prev + 2);
      resetBoard();

      if (matchedCards + 2 === cards.length) {
        setMessage(translations[language].congratulations);
        clearInterval(timerRef.current);
        setShowConfetti(true);
        updateTeamPoints();
      }
    } else {
      setTimeout(() => {
        const updatedCards = cards.map((c) =>
          c.id === firstCard.id || c.id === flippedCard.id
            ? { ...c, flipped: false }
            : c
        );
        setCards(updatedCards);
        resetBoard();
      }, 1500);
    }
  };

  const resetBoard = () => {
    setHasFlippedCard(false);
    setLockBoard(false);
    setFirstCard(null);
    setSecondCard(null);
  };

  const updateTeamPoints = async () => {
    if (currentUser) {
      try {
        const userId = currentUser.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();

          if (teamData.usedLevels && teamData.usedLevels.includes(currentLevel)) {
            setErrorMessage(translations[language].alreadyCompleted);
setShowContinueButton(true); // Affiche le bouton "Continuer"

          } else {
            let updatedPoints = teamData.points || 0;
            updatedPoints += pointsToAdd;

            const teamDocRef = doc(db, 'teams', teamDoc.id);

            await updateDoc(teamDocRef, {
              points: updatedPoints,
              usedLevels: arrayUnion(currentLevel),
            });

            setSuccessMessage(translations[language].successMessage);
            setShowContinueButton(true); // Affiche le bouton "Continuer"
            
          }
        } else {
          setErrorMessage(translations[language].errorUserNotFound);
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour des points:', error);
        setErrorMessage(translations[language].errorOccurred);
      }
    } else {
      setErrorMessage(translations[language].userNotLoggedIn);
    }
  };

  const getCardStyle = () => {
    return {
      flex: '0 0 calc(20% - 10px)', // 5 cartes par ligne
      margin: '5px',
      position: 'relative',
      transformStyle: 'preserve-3d',
      transition: 'transform 0.5s',
      height: '70px',
      cursor: 'pointer',
    };
  };

  const getIconStyle = () => {
    return {
      fontSize: '28px',
    };
  };

  return (
<div className="min-h-screen p-6 text-white">
  {/* Numéro de niveau avec dégradé bleu-émeraude */}
  <div className="relative">
    <h1 className="text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
      {translations[language].levelNumber}
    </h1>
    <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
  </div>

  {/* Titre de la mission avec ligne décorative */}
  <h2 className="text-2xl font-bold text-center mb-12 relative">
    <span className="bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
      {translations[language].missionTitle}
    </span>
    <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full"></div>
  </h2>

      {!gameStarted && !gameOver && (
        <div id="start-button-container" className="text-center mb-8">
          <button
  id="start-button"
  className="px-8 py-4 text-2xl bg-[#60a5fa] text-white rounded-lg hover:bg-[#34d399] hover:text-white transition-colors duration-300"
  onClick={startGame}
>
  {translations[language].startButton}
</button>
        </div>
      )}

      {gameStarted && (
        <>
          <div id="timer" className="text-center text-2xl mb-4">
            {translations[language].timeLeft} {timeLeft}s
          </div>

          <div
            className="memory-game flex flex-wrap justify-center"
            style={{ maxWidth: '500px', margin: '0 auto' }}
          >
            {cards.map((card) => (
              <div
                key={card.id}
                className={`memory-card ${
                  card.flipped || card.matched ? 'flip' : ''
                }`}
                onClick={() => handleCardClick(card)}
                style={{
                  ...getCardStyle(),
                  transform:
                    card.flipped || card.matched ? 'rotateY(180deg)' : '',
                }}
              >
                <div
                  className="front-face"
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    backfaceVisibility: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#0E1D35',
                    color: 'white',
                    transform: 'rotateY(180deg)',
                    borderRadius: '5px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSailboat}
                    style={{ color: card.color, ...getIconStyle() }}
                  />
                </div>
                <div
                  className="back-face"
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    backfaceVisibility: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#0E1D35',
                    color: 'white',
                    borderRadius: '5px',
                  }}
                >
                  <FontAwesomeIcon icon={faQuestion} style={getIconStyle()} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {gameOver && (
        <div className="text-center mt-8">
          <h2>{message}</h2>
          <button
            className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            onClick={startGame}
          >
            {translations[language].replay}
          </button>
        </div>
      )}

      {errorMessage && (
        <div id="error-message" className="text-center text-[#e74c3c] mt-8">
          {errorMessage}
        </div>
      )}

      {successMessage && (
        <div id="success-message" className="text-center text-[#2ecc71] mt-8">
          {successMessage}
        </div>
      )}

{showContinueButton && (
  <button
    onClick={() => navigate(redirectUrl)}
    className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 block mx-auto"
    >
    {translations[language].continueButton}
  </button>
)}


      {showConfetti && (
        <Confetti width={windowWidth} height={window.innerHeight} />
      )}
    </div>
  );
};

export default Level4;
