import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fr: {
    translation: {
      navbar: {
        home: "Accueil",
        faq: "FAQ",
        leaderboard: "Classement",
        dashboard: "Tableau de bord",
        welcome: "Bienvenue",
        logout: "Déconnexion",
        register: "Inscription",
        login: "Connexion",
        rank: "Rang",
        total: "Total"
      },
      presentation: {
        title: "Rallye Trésors de Bacalar",
        register: "S'inscrire maintenant",
        subtitle: "Une chasse aux trésors unique entre virtuel et réel",
        subtitle2: "Un rallye-chasse aux trésors qui commence en ligne, se poursit dans les rues de Bacalar et se termine sur un voilier.",
        startNow: "Commencer l'aventure",
        specialOffer: "Offre spéciale de lancement",
        joinAdventure: "Rejoindre l'aventure",
        price: "300$ MXN",
        regularPrice: "500$ MXN",
        "section1Title": "L'Aventure Commence",
        "section2Title": "Formez Votre Équipage",
        "section3Title": "Les Défis",
        "section4Title": "Le Déroulement", 
        "section5Title": "Les Récompenses",
        highlights: {
          online: {
            title: "75 étapes en ligne",
            description: "Commencez l'aventure depuis chez vous avec une chasse au trésor virtuelle passionnante"
          },
          city: {
            title: "25 défis à Bacalar",
            description: "Explorez les secrets de la ville dans une quête urbaine fascinante"
          },
          sailing: {
            title: "12 missions en voilier",
            description: "Vivez l'aventure sur la magnifique lagune de Bacalar"
          },
          rewards: {
            title: "Des récompenses exceptionnelles",
            description: "Gagnez des prix et profitez de réductions sur les excursions en voilier"
          }
        },
        introText: "Embarquez pour une aventure unique qui combine exploration virtuelle, découverte urbaine et navigation à voile. Un rallye moderne accessible à tous, mêlant défis en ligne et aventures réelles à Bacalar.",
        slides: [
          `Imaginez-vous embarquer dans une aventure extraordinaire, où chaque clic de souris vous rapproche d'un trésor caché, où chaque défi relevé vous transporte dans un univers fascinant.

De la toile à la voile, l'aventure commence chez-vous!

Le Rallye Trésors de Bacalar n'est pas simplement un jeu, c'est une odyssée moderne qui commence confortablement chez vous et se termine sur la scintillante Lagune de Bacalar.

Vos premiers pas vous emmènent dans les méandres mystérieux de l'Internet, où vous découvrez les secrets du monde Maya, les récits épiques des pirates, et les histoires fascinantes de la conquête espagnole.

Chaque défi relevé vous propulse un peu plus loin, vous ouvrant les portes de la géographie envoûtante de la Péninsule du Yucatan, sa faune exotique et la flore luxuriante de Bacalar.`,

          `Formez votre équipage !

Enrôlez vos amis, formez une équipe intrépide avec un capitaine courageux et ses moussaillons dévoués. Les équipes peuvent être des familles, des groupes d'amis, des couples, des collègues en teambuilding ou vous seul(e).

Le rallye est également adapté aux enfants garantissant ainsi une expérience inclusive pour tous.

Choisissez un nom d'équipe original, qui peut également rapporter des points. Faites preuve de créativité !

La collaboration, les rires partagés et les stratégies élaborées ensemble renforceront les liens entre les membres de votre équipe.

En attendant vos vacances tant attendues au Mexique, participer au Rallye Trésors de Bacalar est une excellente façon de faire patienter les enfants (et vous) et de commencer dès maintenant votre immersion dans la riche culture mexicaine.`,

          `Une tapisserie de défis vous attend !

Tests de connaissances : Stimulez votre intellect avec des questions qui mettent en valeur l'histoire, la géographie, la faune et la flore de la Péninsule du Yucatán.

Jeux de mots : Aiguisez votre esprit créatif en résolvant des énigmes autour des légendes mayas, des récits de pirates et de la navigation à la voile.

Défis créatifs : Réveillez le Picasso en vous avec des activités inspirées par l'art et la culture maya.

Défis linguistiques : Déchiffrez des indices en espagnol et maya pour une immersion culturelle enrichissante.

Énigmes chiffrées : Testez votre esprit analytique avec des défis numériques.

Tout au long de cette expérience hors du commun, il y aura des épreuves et des surprises, des défis un peu fous mais toujours amusants et sécurisés. Restez prêts pour l'inattendu, car chaque étape peut vous réserver des moments de rires, des découvertes étonnantes et des souvenirs mémorables.`,

          `Le Rallye Trésors de Bacalar se compose de trois parties :

Partie Virtuelle : 75 étapes captivantes en ligne. À travers des épreuves ludiques et enrichissantes, vous explorerez des trésors cachés sur notre site Internet et ailleurs sur le Web, découvrant des sites fascinants et des informations intrigantes.

Partie Physique à Bacalar : 25 étapes pour découvrir les joyaux cachés du centre historique. Prévoyez au minimum 2 heures pour parcourir les rues de la ville, où chaque détour vous réserve une surprise.

Partie en voilier : 12 petites épreuves amusantes mettant à l'épreuve tout ce que vous aurez appris. C'est ici que vous trouverez (ou pas) votre trésor !

À tout moment, vous pouvez voir en direct le progrès des autres équipes et ressentir la montée d'adrénaline en voyant vos rivaux avancer.`,

          `Récompenses Excitantes Tout au Long du Rallye !

🎁 Welcome Gift: Un bon de réduction de 20% sur une excursion en voilier à Bacalar.

Pendant le Rallye, gagnez des prix fantastiques :
• Excursions privées du Luxe en voilier
• Rabais Incroyables sur des excursions en Voilier
• Excursions en kayak et paddle
• Repas dans des restaurants partenaires
• Souvenirs et produits locaux`,

          `Rejoignez les premiers participants à vivre cette aventure unique !

Un rallye culturel et interactif qui commence confortablement chez vous et se termine sur les eaux scintillantes de Bacalar. À travers des énigmes, des défis et des découvertes, plongez dans l'histoire maya, l'univers des pirates, et la magie de la lagune de Bacalar.

Prix spécial de lancement : 300$ MXN par équipe au lieu de 500$ MXN ! Mais dépêchez-vous : cette offre est limitée !

Une fois l'inscription terminée, vous serez dirigé(e) vers votre tableau de bord, où vous pourrez commencer le Rallye immédiatement.

N'attendez plus ! Les places sont limitées et le prix augmentera bientôt. Inscrivez votre équipe dès aujourd'hui et embarquez pour une chasse aux trésors inoubliable !`
        ]
      },
      auth: {
        login: {
          title: "Connexion",
          passwordLabel: "Mot de passe",
          rememberMe: "Se souvenir de moi",
          forgotPassword: "Mot de passe oublié ?",
          emailRequired: "L'email est requis",
          passwordRequired: "Le mot de passe est requis",
          or: "OU",
          welcomeBack: "Bon retour {{email}}", 
          continueRegistration: "Poursuivez votre inscription là où vous l'aviez laissée", 
          verificationError: "Erreur lors de la vérification de votre compte",
          googleButton: "Se connecter avec Google",
          orUseEmail: "OU UTILISER L'EMAIL",
          emailPlaceholder: "Email",
          passwordPlaceholder: "Mot de passe",
          submitButton: "Se connecter",
          error: "Erreur de connexion. Veuillez réessayer."
        },
        googleSignup: "S'inscrire avec Google",
        emailSignup: "S'inscrire avec un email",
        emailError: "Erreur lors de l'inscription avec email",
        googleError: "Erreur lors de l'inscription avec Google"
      },
      register: {
        title: "Inscription au Rallye Trésors de Bacalar",
        nextStep: "Étape suivante"
      },
      teamCreation: {
        stepTitle: "Créez votre équipe",
        teamNameLabel: "Nom de l'équipe",
        teamNamePlaceholder: "Entrez le nom de votre équipe",
        teamCountryLabel: "Pays",
        teamCountryPlaceholder: "Pays d'origine",
        teamPlayersLabel: "Nombre de joueurs",
        teamPlayersPlaceholder: "Sélectionnez le nombre de joueurs",
        captainNameLabel: "Nom du capitaine",
        captainNamePlaceholder: "Nom du capitaine de l'équipe",
        playerNameLabel: "Joueur {{index}}",
        playerNamePlaceholder: "Nom du joueur {{index}}",
        isChildLabel: "Enfant ?",
        childAgeLabel: "Âge de l'enfant",
        childAgePlaceholder: "Âge",
        tripDateLabel: "Date approximative de votre voyage à Bacalar",
        submitButton: "Créer l'équipe",
        submitting: "Création en cours...",
        errorFieldsRequired: "Tous les champs sont requis",
        errorSaving: "Erreur lors de la sauvegarde de l'équipe"
      },
      checkoutForm: {
        summary: "Résumé de votre inscription",
        description: "Inscription au Rallye Trésors de Bacalar",
        totalAmount: "Montant total",
        teamDetails: "Détails de l'équipe",
        teamName: "Équipe",
        country: "Pays",
        players: "Joueurs",
        tripDate: "Date du voyage",
        proceedToPayment: "Procéder au paiement",
        processing: "Traitement en cours...",
        sessionError: "Erreur lors de la création de la session de paiement",
        paymentErrorRetry: "Erreur de paiement. Veuillez réessayer",
        taxInfo: "TVA incluse si applicable",
        securePayment: "Paiement sécurisé",
        stripeInfo: "Paiement traité par Stripe"
      },
      common: {
        loading: "Chargement...",
        error: "Erreur",
        success: "Succès"
      }
    }
  },
  en: {
    translation: {
      navbar: {
        home: "Home",
        faq: "FAQ",
        leaderboard: "Leaderboard",
        dashboard: "Dashboard",
        welcome: "Welcome",
        logout: "Logout",
        register: "Register",
        login: "Login",
        rank: "Rank",
        total: "Total"
      },
      presentation: {
        title: "Bacalar Treasure Rally",
        register: "Register Now",
        subtitle: "A unique treasure hunt between virtual and real",
        subtitle2: "A treasure hunt rally that starts online, continues through the streets of Bacalar and ends on a sailboat.",
        startNow: "Start the adventure",
        specialOffer: "Special launch offer",
        joinAdventure: "Join the adventure",
        price: "300$ MXN",
        regularPrice: "800$ MXN",
        "section1Title": "The Adventure Begins",
        "section2Title": "Form Your Crew",
        "section3Title": "The Challenges",
        "section4Title": "The Process",
        "section5Title": "The Rewards",
        highlights: {
          online: {
            title: "75 online stages",
            description: "Start the adventure from home with an exciting virtual treasure hunt"
          },
          city: {
            title: "25 Bacalar challenges",
            description: "Explore the city's secrets in a fascinating urban quest"
          },
          sailing: {
            title: "12 sailing missions",
            description: "Experience the adventure on the magnificent Bacalar lagoon"
          },
          rewards: {
            title: "Exceptional rewards",
            description: "Win prizes and enjoy discounts on sailing excursions"
          }
        },
        introText: "Embark on a unique adventure that combines virtual exploration, urban discovery and sailing. A modern rally accessible to all, mixing online challenges and real adventures in Bacalar.",
        slides: [
          `Imagine embarking on an extraordinary adventure where each mouse click brings you closer to hidden treasure, where each challenge completed transports you to a fascinating universe.

From web to sail, the adventure begins at home!

The Bacalar Treasure Rally isn't just a game, it's a modern odyssey that starts comfortably from your home and ends on the shimmering Bacalar Lagoon.

Your first steps take you through the mysterious meanders of the Internet, where you discover the secrets of the Maya world, epic pirate tales, and fascinating stories of the Spanish conquest.

Each challenge completed propels you further, opening the doors to the enchanting geography of the Yucatán Peninsula, its exotic fauna, and Bacalar's lush flora.`,

          `Form your crew!

Enlist your friends, form an intrepid team with a brave captain and dedicated crew members. Teams can be families, groups of friends, couples, colleagues in team building, or just you alone.

The rally is also adapted for children, ensuring an inclusive experience for all.

Choose an original team name, which can also earn you points. Be creative!

The collaboration, shared laughter, and strategies developed together will strengthen the bonds between your team members.

While waiting for your long-awaited vacation in Mexico, participating in the Bacalar Treasure Rally is an excellent way to keep the children (and you) entertained and start your immersion in the rich Mexican culture right now.`,

          `A tapestry of challenges awaits!

Knowledge Tests: Stimulate your intellect with questions that highlight the history, geography, fauna, and flora of the Yucatán Peninsula.

Word Games: Sharpen your creative mind by solving riddles about Maya legends, pirate stories, and sailing navigation.

Creative Challenges: Awaken your inner Picasso with activities inspired by Maya art and culture.

Language Challenges: Decipher clues in Spanish and Maya for an enriching cultural immersion.

Number Puzzles: Test your analytical mind with numerical challenges.

Throughout this extraordinary experience, there will be trials and surprises, challenges that are a bit crazy but always fun and safe. Stay ready for the unexpected, as each stage can bring moments of laughter, amazing discoveries, and memorable memories.`,

          `The Bacalar Treasure Rally consists of three parts:

Virtual Part: 75 captivating online stages. Through fun and enriching trials, you'll explore hidden treasures on our website and elsewhere on the Web, discovering fascinating sites and intriguing information.

Physical Part in Bacalar: 25 stages to discover the hidden gems of the historic center. Plan at least 2 hours to explore the city streets, where each turn holds a surprise.

Sailing Part: 12 fun small challenges testing everything you've learned. This is where you'll find (or not) your treasure!

At any time, you can see other teams' progress in real-time and feel the adrenaline rush as you watch your rivals advance.`,

          `Exciting Rewards Throughout the Rally!

Welcome Gift: A 20% discount voucher for a sailing excursion in Bacalar.

During the Rally, win fantastic prizes:
• Private sailing excursions
• Incredible discounts on Sailing Excursions
• Kayak and paddle excursions
• Meals in partner restaurants
• Souvenirs and local products`,

          `Join the first participants to experience this unique adventure!

A cultural and interactive rally that starts comfortably from your home and ends on the shimmering waters of Bacalar. Through riddles, challenges, and discoveries, dive into Maya history, the universe of pirates, and the magic of the Bacalar lagoon.

Special launch price: only 300$ MXN per team instead of 500$ MXN! But hurry: this offer is limited!

Once the registration is complete, you will be directed to your dashboard, where you can start the Rally right away.

Don't wait! Places are limited and the price will increase soon. Register your team today and embark on an unforgettable treasure hunt!`
        ]
      },
      auth: {
        login: {
          title: "Login",
          passwordLabel: "Password",
          rememberMe: "Remember me",
          forgotPassword: "Forgot password?",
          emailRequired: "Email is required",
          passwordRequired: "Password is required",
          or: "OR",
          welcomeBack: "Welcome back {{email}}",
          continueRegistration: "Continue your registration where you left off",
          verificationError: "Error verifying your account",
          googleButton: "Sign in with Google",
          orUseEmail: "OR USE EMAIL",
          emailPlaceholder: "Email",
          passwordPlaceholder: "Password",
          submitButton: "Sign in",
          error: "Login error. Please try again."
        },
        googleSignup: "Sign up with Google",
        emailSignup: "Sign up with email",
        emailError: "Error signing up with email",
        googleError: "Error signing up with Google"
      },
      register: {
        title: "Registration for the Bacalar Treasure Rally",
        nextStep: "Next step"
      },
      teamCreation: {
        stepTitle: "Create your team",
        teamNameLabel: "Team name",
        teamNamePlaceholder: "Enter your team name",
        teamCountryLabel: "Country",
        teamCountryPlaceholder: "Country of origin",
        teamPlayersLabel: "Number of players",
        teamPlayersPlaceholder: "Select the number of players",
        captainNameLabel: "Captain's name",
        captainNamePlaceholder: "Team captain's name",
        playerNameLabel: "Player {{index}}",
        playerNamePlaceholder: "Player {{index}} name",
        isChildLabel: "Child?",
        childAgeLabel: "Child's age",
        childAgePlaceholder: "Age",
        tripDateLabel: "Approximate date of your trip to Bacalar",
        submitButton: "Create team",
        submitting: "Creating...",
        errorFieldsRequired: "All fields are required",
        errorSaving: "Error saving the team"
      },
      checkoutForm: {
        summary: "Your registration summary",
        description: "Registration for the Bacalar Treasure Rally",
        totalAmount: "Total amount",
        teamDetails: "Team details",
        teamName: "Team",
        country: "Country",
        players: "Players",
        tripDate: "Trip date",
        proceedToPayment: "Proceed to payment",
        processing: "Processing...",
        sessionError: "Error creating payment session",
        paymentErrorRetry: "Payment error. Please try again",
        taxInfo: "VAT included if applicable",
        securePayment: "Secure payment",
        stripeInfo: "Payment processed by Stripe"
      },
      common: {
        loading: "Loading...",
        error: "Error",
        success: "Success"
      }
    }
  },
  es: {
    translation: {
      navbar: {
        home: "Inicio",
        faq: "FAQ",
        leaderboard: "Clasificación",
        dashboard: "Panel de Control",
        welcome: "Bienvenido",
        logout: "Cerrar Sesión",
        register: "Registrarse",
        login: "Iniciar Sesión",
        rank: "Rango",
        total: "Total"
      },
      presentation: {
        title: "Rally Tesoros de Bacalar",
        register: "Registrarse ahora",
        subtitle: "Una búsqueda del tesoro única entre virtual y real",
        subtitle2: "Un rally de búsqueda del tesoro que comienza en línea, continúa por las calles de Bacalar y termina en un velero.",
        startNow: "Comenzar la aventura",
        specialOffer: "Oferta especial de lanzamiento",
        joinAdventure: "Únete a la aventura",
        price: "300$ MXN",
        regularPrice: "500$ MXN",
        "section1Title": "La Aventura Comienza",
        "section2Title": "Forma tu Equipo",
        "section3Title": "Los Desafíos",
        "section4Title": "El Desarrollo",
        "section5Title": "Las Recompensas",
        highlights: {
          online: {
            title: "75 etapas en línea",
            description: "Comienza la aventura desde casa con una emocionante búsqueda del tesoro virtual"
          },
          city: {
            title: "25 desafíos en Bacalar",
            description: "Explora los secretos de la ciudad en una fascinante búsqueda urbana"
          },
          sailing: {
            title: "12 misiones en velero",
            description: "Vive la aventura en la magnífica laguna de Bacalar"
          },
          rewards: {
            title: "Recompensas excepcionales",
            description: "Gana premios y disfruta de descuentos en excursiones en velero"
          }
        },
        introText: "Embárcate en una aventura única que combina exploración virtual, descubrimiento urbano y navegación a vela. Un rally moderno accesible para todos, mezclando desafíos en línea y aventuras reales en Bacalar.",
        slides: [
          `Imagínate embarcándote en una aventura extraordinaria, donde cada clic del ratón te acerca a un tesoro escondido, donde cada desafío superado te transporta a un universo fascinante.

¡De la web a la vela, la aventura comienza en casa!

El Rally Tesoros de Bacalar no es solo un juego, es una odisea moderna que comienza cómodamente desde tu casa y termina en la brillante Laguna de Bacalar.

Tus primeros pasos te llevan por los intrincados caminos de Internet, donde descubrirás los secretos del mundo Maya, las épicas historias de piratas y las fascinantes historias de la conquista española.

Cada desafío superado te impulsa más lejos, abriéndote las puertas a la encantadora geografía de la Península de Yucatán, su fauna exótica y la exuberante flora de Bacalar.`,

          `¡Forma tu tripulación!

Recluta a tus amigos, forma un equipo intrépido con un capitán valiente y tripulantes dedicados. Los equipos pueden ser familias, grupos de amigos, parejas, colegas en team building o tú solo.

El rally también está adaptado para niños, garantizando una experiencia inclusiva para todos.

¡Elige un nombre de equipo original, que también puede otorgarte puntos. ¡Sé creativo!

La colaboración, las risas compartidas y las estrategias desarrolladas juntos fortalecerán los lazos entre los miembros de tu equipo.

Mientras esperas tus tan esperadas vacaciones en México, participar en el Rally Tesoros de Bacalar es una excelente manera de mantener a los niños (y a ti) entretenidos y comenzar tu inmersión en la rica cultura mexicana desde ahora.`,

          `¡Un mundo de desafíos te espera!

Pruebas de Conocimiento: Estimula tu intelecto con preguntas que destacan la historia, geografía, fauna y flora de la Península de Yucatán.

Juegos de Palabras: Agudiza tu mente creativa resolviendo acertijos sobre leyendas mayas, historias de piratas y navegación a vela.

Desafíos Creativos: Despierta tu Picasso interior con actividades inspiradas en el arte y la cultura maya.

Desafíos Lingüísticos: Descifra pistas en español y maya para una enriquecedora inmersión cultural.

Enigmas Numéricos: Pon a prueba tu mente analítica con desafíos numéricos.

A lo largo de esta extraordinaria experiencia, habrá pruebas y sorpresas, desafíos un poco locos pero siempre divertidos y seguros. Mantente preparado para lo inesperado, ya que cada etapa puede traerte momentos de risa, descubrimientos asombrosos y recuerdos memorables.`,

          `El Rally Tesoros de Bacalar consta de tres partes:

Parte Virtual: 75 etapas cautivadoras en línea. A través de pruebas divertidas y enriquecedoras, explorarás tesoros ocultos en nuestro sitio web y en otros lugares de la Web, descubriendo sitios fascinantes e información intrigante.

Parte Física en Bacalar: 25 etapas para descubrir las joyas ocultas del centro histórico. Planifica al menos 2 horas para explorar las calles de la ciudad, donde cada giro guarda una sorpresa.

Parte en Velero: 12 pequeñas pruebas divertidas que ponen a prueba todo lo que has aprendido. ¡Aquí es donde encontrarás (o no) tu tesoro!

En cualquier momento, puedes ver el progreso de otros equipos en tiempo real y sentir la adrenalina mientras ves avanzar a tus rivales.`,

          `¡Emocionantes Recompensas Durante Todo el Rally!

Regalo de Bienvenida: Un vale de descuento del 20% para una excursión en velero en Bacalar.

Durante el Rally, gana premios fantásticos:
• Excursiones privadas en velero
• Descuentos increíbles en excursiones en velero
• Excursiones en kayak y paddle
• Comidas en restaurantes asociados
• Recuerdos y productos locales`,

          `¡Únete a los primeros participantes en vivir esta aventura única!

Un rally cultural e interactivo que comienza cómodamente desde tu casa y termina en las aguas brillantes de Bacalar. A través de enigmas, desafíos y descubrimientos, sumérgete en la historia maya, el universo de los piratas y la magia de la laguna de Bacalar.

Precio especial de lanzamiento: ¡solo 300$ MXN por equipo en lugar de 500$ MXN! Pero date prisa: ¡esta oferta es limitada!

Una vez completado el registro, serás dirigido a tu tablero, donde podrás comenzar el Rally de inmediato.

¡No esperes más! Las plazas son limitadas y el precio aumentará pronto. ¡Registra tu equipo hoy y embárcate en una búsqueda del tesoro inolvidable!`
        ]
      },
      auth: {
        login: {
          title: "Iniciar Sesión",
          passwordLabel: "Contraseña",
          rememberMe: "Recuérdame",
          forgotPassword: "¿Olvidaste tu contraseña?",
          emailRequired: "Se requiere email",
          passwordRequired: "Se requiere contraseña",
          or: "O",
          welcomeBack: "Bienvenido de nuevo {{email}}",
          continueRegistration: "Continúa tu registro donde lo dejaste",
          verificationError: "Error al verificar tu cuenta",
          googleButton: "Iniciar sesión con Google",
          orUseEmail: "O USA EMAIL",
          emailPlaceholder: "Email",
          passwordPlaceholder: "Contraseña",
          submitButton: "Iniciar Sesión",
          error: "Error de inicio de sesión. Por favor, inténtalo de nuevo."
        },
        googleSignup: "Regístrate con Google",
        emailSignup: "Regístrate con email",
        emailError: "Error al registrarse con email",
        googleError: "Error al registrarse con Google"
      },
      register: {
        title: "Registro para el Rally Tesoros de Bacalar",
        nextStep: "Siguiente paso"
      },
      teamCreation: {
        stepTitle: "Crea tu equipo",
        teamNameLabel: "Nombre del equipo",
        teamNamePlaceholder: "Ingresa el nombre de tu equipo",
        teamCountryLabel: "País",
        teamCountryPlaceholder: "País de origen",
        teamPlayersLabel: "Número de jugadores",
        teamPlayersPlaceholder: "Selecciona el número de jugadores",
        captainNameLabel: "Nombre del capitán",
        captainNamePlaceholder: "Nombre del capitán del equipo",
        playerNameLabel: "Jugador {{index}}",
        playerNamePlaceholder: "Nombre del jugador {{index}}",
        isChildLabel: "¿Niño?",
        childAgeLabel: "Edad del niño",
        childAgePlaceholder: "Edad",
        tripDateLabel: "Fecha aproximada de tu viaje a Bacalar",
        submitButton: "Crear equipo",
        submitting: "Creando...",
        errorFieldsRequired: "Todos los campos son requeridos",
        errorSaving: "Error al guardar el equipo"
      },
      checkoutForm: {
        summary: "Resumen de tu registro",
        description: "Registro para el Rally Tesoros de Bacalar",
        totalAmount: "Monto total",
        teamDetails: "Detalles del equipo",
        teamName: "Equipo",
        country: "País",
        players: "Jugadores",
        tripDate: "Fecha del viaje",
        proceedToPayment: "Proceder al pago",
        processing: "Procesando...",sessionError: "Error al crear la sesión de pago",
        paymentErrorRetry: "Error de pago. Por favor, inténtalo de nuevo",
        taxInfo: "IVA incluido si aplica",
        securePayment: "Pago seguro",
        stripeInfo: "Pago procesado por Stripe"
      },
      common: {
        loading: "Cargando...",
        error: "Error",
        success: "Éxito"
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;