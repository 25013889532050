import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo1 from '../assets/logo1.jpg';
import logo2 from '../assets/logo2.jpg';
import logo3 from '../assets/logo3.png';
import logo4 from '../assets/logo4.jpg';
import logo5 from '../assets/logo5.jpg';
import logo6 from '../assets/logo6.png';
import logo7 from '../assets/logo7.png';
import logo8 from '../assets/logo8.png';

const translations = {
  fr: {
    presentation: {
      title: "Le Rallye Trésors de Bacalar est une présentation de",
      sponsors: "Nos Commanditaires",
      followUs: "Suivez-nous sur",
      rights: "Tous droits réservés"
    },
    footer: {
      quickLinks: "Liens rapides",
      contact: "Contact",
      legal: "Informations légales",
      home: "Accueil",
      faq: "FAQ",
      leaderboard: "Classement",
      privacy: "Confidentialité",
      terms: "Conditions d'utilisation"
    }
  },
  en: {
    presentation: {
      title: "The Bacalar Treasure Rally is presented by",
      sponsors: "Our Sponsors",
      followUs: "Follow us on",
      rights: "All rights reserved"
    },
    footer: {
      quickLinks: "Quick Links",
      contact: "Contact",
      legal: "Legal",
      home: "Home",
      faq: "FAQ",
      leaderboard: "Leaderboard",
      privacy: "Privacy",
      terms: "Terms"
    }
  },
  es: {
    presentation: {
      title: "El Rally del Tesoro de Bacalar es presentado por",
      sponsors: "Nuestros Patrocinadores",
      followUs: "Síguenos en",
      rights: "Todos los derechos reservados"
    },
    footer: {
      quickLinks: "Enlaces Rápidos",
      contact: "Contacto",
      legal: "Legal",
      home: "Inicio",
      faq: "Preguntas Frecuentes",
      leaderboard: "Clasificación",
      privacy: "Privacidad",
      terms: "Términos"
    }
  }
};

const sponsors = [
  { id: 1, name: "Sponsor 1", image: logo1, url: "#" },
  { id: 2, name: "Sponsor 2", image: logo2, url: "#" },
  { id: 3, name: "Sponsor 3", image: logo3, url: "#" },
  { id: 4, name: "Sponsor 4", image: logo4, url: "#" },
  { id: 5, name: "Sponsor 5", image: logo5, url: "#" },
  { id: 6, name: "Sponsor 6", image: logo6, url: "#" },
  { id: 7, name: "Sponsor 7", image: logo7, url: "#" },
  { id: 8, name: "Sponsor 8", image: logo8, url: "#" }
];

const SailboatLogo = () => (
  <div className="w-20 h-20 transform hover:scale-105 transition-transform duration-300 mx-auto">
    <svg viewBox="200 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="sailGrad" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#60a5fa" stopOpacity="1" />
          <stop offset="100%" stopColor="#34d399" stopOpacity="1" />
        </linearGradient>
      </defs>
      <g transform="scale(1.2) translate(-50, -20)">
        <path id="sail" d="M300,35 C325,70 325,105 270,105 Q305,70 300,35" fill="url(#sailGrad)" stroke="#60a5fa" strokeWidth="2" />
        <path id="sail2" d="M320,50 C345,80 345,105 310,105 Q335,80 320,50" fill="white" opacity="0.9" stroke="#34d399" strokeWidth="2" />
        <path id="mast" d="M300,40 Q305,80 300,120" stroke="white" strokeWidth="2" />
        <path id="hull" d="M250,120 Q275,150 300,160 Q325,150 350,120 Q300,140 250,120" fill="white" opacity="0.9" stroke="#60a5fa" strokeWidth="2" />
        <path id="flag1" d="M300,35 Q310,30 315,20 Q310,25 300,20" fill="#34d399" />
        <path id="flag2" d="M320,50 Q330,45 335,35 Q330,40 320,35" fill="#60a5fa" />
        <circle id="window1" cx="280" cy="130" r="5" fill="white" stroke="#34d399" strokeWidth="2" />
        <circle id="window2" cx="300" cy="130" r="5" fill="white" stroke="#34d399" strokeWidth="2" />
        <circle id="window3" cx="320" cy="130" r="5" fill="white" stroke="#34d399" strokeWidth="2" />
      </g>
    </svg>
  </div>
);

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="bg-gradient-to-t from-[#0e1d35] to-[#1a2437] text-white border-t border-white/10 backdrop-blur-sm">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Section des commanditaires */}
        <div className="mb-12">
          <h2 className="text-xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
            {translations[i18n.language].presentation.sponsors}
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12">
            {sponsors.map((sponsor) => (
              <a
                key={sponsor.id}
                href={sponsor.url}
                target="_blank"
                rel="noopener noreferrer"
                className="aspect-video bg-white/5 rounded-lg flex items-center justify-center border border-white/10 p-4 hover:bg-white/10 transition-all duration-300"
              >
                <img
                  src={sponsor.image}
                  alt={sponsor.name}
                  className="w-full h-full object-contain opacity-80 hover:opacity-100 transition-opacity duration-300"
                />
              </a>
            ))}
          </div>
        </div>

        {/* Section de présentation */}
        <div className="text-center mb-12">
          <p className="text-lg mb-6">
            {translations[i18n.language].presentation.title}
            <a href="https://adorablesailing.com" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="text-blue-400 hover:text-emerald-400 ml-2 transition-colors">
              Adorable Sailing
            </a>
          </p>

          <h3 className="text-white/80 mb-4">{translations[i18n.language].presentation.followUs}</h3>
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            <a href="https://www.instagram.com/rallyebacalar" 
               target="_blank" 
               rel="noopener noreferrer"
               className="px-4 py-2 bg-white/5 rounded-lg hover:bg-white/10 transition-all text-blue-400 hover:text-emerald-400">
              @rallyebacalar
            </a>
            <a href="https://www.instagram.com/adorablesailing" 
               target="_blank" 
               rel="noopener noreferrer"
               className="px-4 py-2 bg-white/5 rounded-lg hover:bg-white/10 transition-all text-blue-400 hover:text-emerald-400">
              @adorablesailing
            </a>
            <a href="https://www.facebook.com/adorablesailing" 
               target="_blank" 
               rel="noopener noreferrer"
               className="px-4 py-2 bg-white/5 rounded-lg hover:bg-white/10 transition-all text-blue-400 hover:text-emerald-400">
              Facebook Adorable Sailing
            </a>
            <a href="https://www.facebook.com/profile.php?id=61566363025049" 
               target="_blank" 
               rel="noopener noreferrer"
               className="px-4 py-2 bg-white/5 rounded-lg hover:bg-white/10 transition-all text-blue-400 hover:text-emerald-400">
              Facebook Rallye Bacalar
            </a>
          </div>
        </div>

        {/* Section des colonnes */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {/* Colonne 1 - Liens importants */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-400">
              {translations[i18n.language].footer.quickLinks}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-blue-400 transition-colors duration-300">
                  {translations[i18n.language].footer.home}
                </Link>
              </li>
              <li>
                <Link to="/faq" className="hover:text-blue-400 transition-colors duration-300">
                  {translations[i18n.language].footer.faq}
                </Link>
              </li>
              <li>
                <Link to="/publicleaderboard" className="hover:text-blue-400 transition-colors duration-300">
                  {translations[i18n.language].footer.leaderboard}
                </Link>
              </li>
            </ul>
          </div>

          {/* Colonne 2 - Contact */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-400">
              {translations[i18n.language].footer.contact}
            </h3>
            <ul className="space-y-2">
              <li>
                <a href="mailto:contacto@adorablesailing.com" 
                   className="hover:text-blue-400 transition-colors duration-300">
                  contacto@adorablesailing.com
                </a>
              </li>
            </ul>
          </div>

          {/* Colonne 3 - Légal */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-400">
              {translations[i18n.language].footer.legal}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy" className="hover:text-blue-400 transition-colors duration-300">
                  {translations[i18n.language].footer.privacy}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="hover:text-blue-400 transition-colors duration-300">
                  {translations[i18n.language].footer.terms}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright et voilier */}
        <div className="mt-8 pt-8 border-t border-white/10 text-center">
          <SailboatLogo />
          <p className="mt-4 text-sm text-white/70">
            © 2024 Adorable Sailing. {translations[i18n.language].presentation.rights}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;