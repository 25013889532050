import React from 'react';
import { useParams } from 'react-router-dom';
import Level1 from '../games/Level1';
import Level2 from '../games/Level2';
import Level3 from '../games/Level3';
import Level4 from '../games/Level4';
import Level5 from '../games/Level5';
import Level6 from '../games/Level6';
import Level7 from '../games/Level7';
import Level8 from '../games/Level8';
import Level9 from '../games/Level9';
import Level10 from '../games/Level10';
import Level11 from '../games/Level11';

// ... importez les autres niveaux selon vos besoins

const LevelPage = () => {
  const { level } = useParams();
  const levelNumber = parseInt(level, 10);

  // Vérification du niveau valide
  if (isNaN(levelNumber) || levelNumber < 1 || levelNumber > 75) {
    return <div>Niveau non trouvé</div>;
  }

  // Mapping des composants de niveau
  const levels = {
    1: Level1,
    2: Level2,
    3: Level3,
    4: Level4,
    5: Level5,
    6: Level6,
    7: Level7,
    8: Level8,
    9: Level9,
    10: Level10,
    11: Level11,
    
 
    // ... ajoutez les autres niveaux
  };

  // Récupération du composant correspondant au niveau
  const LevelComponent = levels[levelNumber];

  if (!LevelComponent) {
    return <div>Niveau en construction</div>;
  }

  return <LevelComponent />;
};

export default LevelPage;