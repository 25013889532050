import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../firebase';
import { 
  doc, 
  collection, 
  getDoc, 
  onSnapshot, 
  updateDoc, 
  orderBy, 
  query 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { ChevronDown, ChevronUp, Edit2, Camera, Save, X } from 'lucide-react';
import LevelsList from '../components/LevelsList';

const translations = {
  fr: {
    dashboard: {
      title: "Tableau de bord",
      players: "joueur",
      playersPlural: "joueurs",
      levelProgression: "Progression des niveaux",
      virtualLevels: "Niveaux Virtuels", 
      cityLevels: "Dans les rues de Bacalar",
      boatLevels: "En voilier",
      stats: {
        totalPoints: "Total Points",
        basePoints: "Points de Base",
        bonusPoints: "Points Bonus",
        completedChallenges: "Défis Complétés"
      },
      messages: {
        title: "Messages de l'équipe",
        noMessages: "Aucun message",
        dateFormat: "DD/MM/YYYY HH:mm"
      },
      welcome: {
        title: "Bienvenue dans l'aventure !",
        message: "Félicitations ! Votre inscription est maintenant complétée. Vous pouvez commencer à jouer et à relever les défis.",
        start: "Prêt à commencer l'aventure ?",
        button: "C'est parti !"
      },
      profile: {
        editPlayers: "Modifier les joueurs",
        save: "Enregistrer",
        cancel: "Annuler",
        photoUpdated: "Photo mise à jour",
        photoError: "Erreur lors de la mise à jour",
        playersUpdated: "Liste des joueurs mise à jour",
        playersError: "Erreur lors de la mise à jour",
        playerName: "Nom du joueur",
        isChild: "Enfant",
        childAge: "Âge",
        addPlayer: "Ajouter un joueur"
      }
    }
  },
  en: {
    dashboard: {
      title: "Dashboard",
      players: "player",
      playersPlural: "players",
      levelProgression: "Level Progression",
      virtualLevels: "Virtual Levels",
      cityLevels: "In the streets of Bacalar", 
      boatLevels: "On Sailboat",
      stats: {
        totalPoints: "Total Points",
        basePoints: "Base Points",
        bonusPoints: "Bonus Points",
        completedChallenges: "Completed Challenges"
      },
      messages: {
        title: "Team Messages",
        noMessages: "No messages",
        dateFormat: "MM/DD/YYYY HH:mm"
      },
      welcome: {
        title: "Welcome to the adventure!",
        message: "Congratulations! Your registration is now complete. You can start playing and taking on challenges.",
        start: "Ready to start the adventure?",
        button: "Let's go!"
      },
      profile: {
        editPlayers: "Edit players",
        save: "Save",
        cancel: "Cancel",
        photoUpdated: "Photo updated",
        photoError: "Update error",
        playersUpdated: "Players list updated",
        playersError: "Error updating players",
        playerName: "Player name",
        isChild: "Child",
        childAge: "Age",
        addPlayer: "Add player"
      }
    }
  },
  es: {
    dashboard: {
      title: "Tablero",
      players: "jugador",
      playersPlural: "jugadores",
      levelProgression: "Progresión de niveles",
      virtualLevels: "Niveles Virtuales",
      cityLevels: "En las calles de Bacalar",
      boatLevels: "En velero",
      stats: {
        totalPoints: "Puntos Totales",
        basePoints: "Puntos Base",
        bonusPoints: "Puntos Bonus",
        completedChallenges: "Desafíos Completados"
      },
      messages: {
        title: "Mensajes del equipo",
        noMessages: "Sin mensajes",
        dateFormat: "DD/MM/YYYY HH:mm"
      },
      welcome: {
        title: "¡Bienvenido a la aventura!",
        message: "¡Felicitaciones! Tu registro está completo. Puedes comenzar a jugar y aceptar desafíos.",
        start: "¿Listo para comenzar la aventura?",
        button: "¡Vamos!"
      },
      profile: {
        editPlayers: "Editar jugadores",
        save: "Guardar",
        cancel: "Cancelar",
        photoUpdated: "Foto actualizada",
        photoError: "Error de actualización",
        playersUpdated: "Lista de jugadores actualizada",
        playersError: "Error al actualizar jugadores",
        playerName: "Nombre del jugador",
        isChild: "Niño",
        childAge: "Edad",
        addPlayer: "Agregar jugador"
      }
    }
  }
};

const Dashboard = () => {
  const [teamData, setTeamData] = useState(null);
  const [showWelcome, setShowWelcome] = useState(false);
  const [teamMessages, setTeamMessages] = useState([]);
  const [userLanguage, setUserLanguage] = useState('fr');
  const [showMessages, setShowMessages] = useState(true);
  const [editingPlayers, setEditingPlayers] = useState(false);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const fileInputRef = useRef(null);

  const t = (key) => {
    const keys = key.split('.');
    return keys.reduce((obj, key) => obj?.[key], translations[userLanguage]);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchTeamData(user.uid);
        subscribeToMessages(user.uid);
      } else {
        setTeamData(null);
        setTeamMessages([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleWelcomeClose = async () => {
    if (teamData && auth.currentUser) {
      const teamRef = doc(db, 'teams', auth.currentUser.uid);
      await updateDoc(teamRef, {
        welcomeMessageShown: true
      });
      setShowWelcome(false);
    }
  };

  const fetchTeamData = async (userId) => {
    const teamRef = doc(db, 'teams', userId);
    const snapshot = await getDoc(teamRef);
    if (snapshot.exists()) {
      const data = snapshot.data();
      setTeamData(data);
      setPlayers(data.players || []);
      setUserLanguage(data.language || 'fr');
      if (data.paymentStatus === 'completed' && !data.welcomeMessageShown) {
        setShowWelcome(true);
      }
    }
  };

  const subscribeToMessages = (userId) => {
    const messagesRef = collection(db, 'teams', userId, 'messages');
    const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));
    
    return onSnapshot(messagesQuery, (snapshot) => {
      const messages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setTeamMessages(messages);
    });
  };

  const handleProfilePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const userId = auth.currentUser.uid;
      const storageRef = ref(storage, `teamFlags/${userId}/${file.name}`);

      // AJOUT
      console.log("Fichier choisi :", file.name);

      await uploadBytes(storageRef, file);
      // AJOUT
      console.log("Upload terminé avec succès !");

      const photoURL = await getDownloadURL(storageRef);
      // AJOUT
      console.log("URL retournée par getDownloadURL :", photoURL);

      const teamRef = doc(db, 'teams', userId);
      await updateDoc(teamRef, {
        profileImageUrl: photoURL
      });
      // AJOUT
      console.log("Firestore mis à jour avec l'URL :", photoURL);

      await fetchTeamData(userId);
      // AJOUT
      console.log("Données rechargées, nouveau teamData :", teamData);

      setSuccess(t('dashboard.profile.photoUpdated'));
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Erreur:', error);
      setError(t('dashboard.profile.photoError'));
    } finally {
      setLoading(false);
    }
  };

  const addPlayer = () => {
    setPlayers([...players, { playerName: '', isChild: false, childAge: '' }]);
  };

  const removePlayer = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers.splice(index, 1);
    setPlayers(updatedPlayers);
  };

  const updatePlayer = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = {
      ...updatedPlayers[index],
      [field]: value
    };
    setPlayers(updatedPlayers);
  };

  const savePlayers = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const teamRef = doc(db, 'teams', auth.currentUser.uid);
      await updateDoc(teamRef, { players });
      setSuccess(t('dashboard.profile.playersUpdated'));
      setEditingPlayers(false);
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Erreur:', error);
      setError(t('dashboard.profile.playersError'));
    } finally {
      setLoading(false);
    }
  };

  if (!teamData) {
    return (
      <div className="min-h-screen bg-[#0e1d35] flex items-center justify-center">
        <div className="text-white text-xl font-medium animate-pulse">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0e1d35]">
      {showWelcome && (
        <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#1a2437] text-white p-8 rounded-xl max-w-lg mx-4 relative border border-white/10">
            <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-400 to-emerald-400 opacity-10 blur-xl -z-10 rounded-full"></div>
            <h2 className="text-3xl font-bold mb-6 text-center">
              <span className="bg-gradient-to-r from-blue-400 to-emerald-400 text-transparent bg-clip-text">
                {t('dashboard.welcome.title')}
              </span>
            </h2>
            <p className="text-lg mb-6 leading-relaxed text-white/90">
              {t('dashboard.welcome.message')}
            </p>
            <p className="text-lg mb-8 leading-relaxed text-white/90">
              {t('dashboard.welcome.start')}
            </p>
            <div className="text-center">
              <button
                onClick={handleWelcomeClose}
                className="px-8 py-4 bg-blue-400 rounded-lg text-white font-medium
                         hover:bg-emerald-400 transition-all duration-300 transform hover:scale-105"
              >
                {t('dashboard.welcome.button')}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 py-8 mt-8 md:mt-4">
        <div className="mb-8">
          <div className="relative py-8 text-center">
            <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-400 to-emerald-400 opacity-10 blur-xl -z-10 rounded-full"></div>
            <h1 className="text-4xl font-black text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
              {t('dashboard.title')}
            </h1>
          </div>
          
          <div className="text-center mb-8">
            <div className="relative inline-block group">
              {teamData.profileImageUrl && (
                <img
                  // AJOUT d'un paramètre pour éviter le cache
                  src={teamData.profileImageUrl + '?' + Date.now()}
                  alt={teamData.name}
                  className="w-24 h-24 rounded-full mx-auto mb-4 border-4 border-blue-400"
                />
              )}
              <button
                onClick={() => fileInputRef.current?.click()}
                className="absolute bottom-4 right-0 p-2 bg-emerald-400 rounded-full 
                         hover:bg-emerald-500 transition-colors duration-200"
              >
                <Camera className="w-4 h-4 text-white" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleProfilePhotoChange}
                accept="image/*"
                className="hidden"
              />
            </div>

            <h2 className="text-white text-xl font-medium mb-2">
              {teamData.name}
            </h2>

            {editingPlayers ? (
              <div className="space-y-4 max-w-lg mx-auto">
                {players.map((player, index) => (
                  <div key={index} className="flex flex-col gap-2 bg-[#1a2437] p-4 rounded-lg">
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        value={player.playerName}
                        onChange={(e) => updatePlayer(index, 'playerName', e.target.value)}
                        className="flex-1 bg-[#0e1d35] text-white px-3 py-2 rounded-lg 
                                 border border-white/10 focus:border-blue-400 outline-none"
                        placeholder={t('dashboard.profile.playerName')}
                      />
                      <button
                        onClick={() => removePlayer(index)}
                        className="p-2 text-red-400 hover:text-red-300"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                    
                    <div className="flex items-center gap-4">
                      <label className="flex items-center gap-2 text-white/80">
                        <input
                          type="checkbox"
                          checked={player.isChild}
                          onChange={(e) => updatePlayer(index, 'isChild', e.target.checked)}
                          className="rounded border-white/20 text-blue-400"
                        />
                        {t('dashboard.profile.isChild')}
                      </label>
                      
                      {player.isChild && (
                        <input
                          type="number"
                          value={player.childAge || ''}
                          onChange={(e) => updatePlayer(index, 'childAge', e.target.value)}
                          className="w-20 bg-[#0e1d35] text-white px-3 py-2 rounded-lg 
                                   border border-white/10 focus:border-blue-400 outline-none"
                          placeholder="Âge"
                        />
                      )}
                    </div>
                  </div>
                ))}

                <div className="flex gap-4 justify-center mt-4">
                  <button
                    onClick={savePlayers}
                    disabled={loading}
                    className="px-6 py-2 bg-emerald-400 text-white rounded-lg
                             hover:bg-emerald-500 transition-colors duration-200"
                  >
                    {loading ? '...' : t('dashboard.profile.save')}
                  </button>
                  <button
                    onClick={() => {
                      setPlayers(teamData.players || []);
                      setEditingPlayers(false);
                    }}
                    className="px-6 py-2 bg-gray-500 text-white rounded-lg
                             hover:bg-gray-600 transition-colors duration-200"
                  >
                    {t('dashboard.profile.cancel')}
                  </button>
                </div>

                <button
                  onClick={addPlayer}
                  className="w-full py-2 mt-2 bg-blue-400/20 text-blue-400 rounded-lg 
                           hover:bg-blue-400/30 transition-colors duration-200"
                >
                  {t('dashboard.profile.addPlayer')}
                </button>
              </div>
            ) : (
              <div className="relative">
                <div className="text-white/60">
                  {teamData.players?.length || 1} {teamData.players?.length > 1 
                    ? t('dashboard.playersPlural') 
                    : t('dashboard.players')}
                </div>
                <button
                  onClick={() => setEditingPlayers(true)}
                  className="absolute -right-8 top-0 p-2 text-white/60 
                           hover:text-white transition-colors duration-200"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
              </div>
            )}

            {(error || success) && (
              <div className={`mt-4 p-3 rounded-lg ${
                error ? 'bg-red-500/20 text-red-300' : 'bg-emerald-500/20 text-emerald-300'
              }`}>
                {error || success}
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
            <div className="bg-[#1a2437]/60 p-4 rounded-xl border border-white/10 backdrop-blur-sm">
              <div className="text-white/60 text-sm">{t('dashboard.stats.totalPoints')}</div>
              <div className="text-2xl font-bold text-blue-400">
                {teamData.points + (teamData.bonusPoints || 0)}
              </div>
            </div>
            <div className="bg-[#1a2437]/60 p-4 rounded-xl border border-white/10 backdrop-blur-sm">
              <div className="text-white/60 text-sm">{t('dashboard.stats.basePoints')}</div>
              <div className="text-2xl font-bold text-emerald-400">
                {teamData.points}
              </div>
            </div>
            <div className="bg-[#1a2437]/60 p-4 rounded-xl border border-white/10 backdrop-blur-sm">
              <div className="text-white/60 text-sm">{t('dashboard.stats.bonusPoints')}</div>
              <div className="text-2xl font-bold text-emerald-400">
                {teamData.bonusPoints || 0}
              </div>
            </div>
            <div className="bg-[#1a2437]/60 p-4 rounded-xl border border-white/10 backdrop-blur-sm">
              <div className="text-white/60 text-sm">{t('dashboard.stats.completedChallenges')}</div>
              <div className="text-2xl font-bold text-blue-400">
                {(teamData.usedLevels || []).length} / 112
              </div>
            </div>
          </div>

          {teamMessages.length > 0 && (
            <div className="mb-8">
              <button
                onClick={() => setShowMessages(!showMessages)}
                className="w-full flex items-center justify-between p-4 bg-[#1a2437]/60 
                         rounded-t-xl border border-white/10"
              >
                <h3 className="text-xl font-bold text-transparent bg-clip-text 
                           bg-gradient-to-r from-blue-400 to-emerald-400">
                  {t('dashboard.messages.title')}
                  <span className="text-white/60 text-base font-normal ml-2">
                    ({teamMessages.length})
                  </span>
                </h3>
                {showMessages ? (
                  <ChevronUp className="w-5 h-5 text-white/60" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-white/60" />
                )}
              </button>

              {showMessages && (
                <div className="bg-[#1a2437]/60 rounded-b-xl border-x border-b 
                            border-white/10 backdrop-blur-sm p-6">
                  <div className="space-y-4">
                    {teamMessages.map((message) => (
                      <div 
                        key={message.id} 
                        className="relative group p-4 rounded-lg bg-white/5 border 
                                 border-white/10 hover:bg-white/10 transition-all duration-300"
                      >
                        <div className="relative">
                          <div className="flex items-center gap-2 mb-2">
                            <span className={`px-2 py-1 rounded-full text-xs ${
                              message.type === 'info' ? 'bg-blue-400/20 text-blue-400' :
                              message.type === 'bonus' ? 'bg-emerald-400/20 text-emerald-400' :
                              'bg-yellow-400/20 text-yellow-400'
                            }`}>
                              {message.type}
                            </span>
                            {message.points && (
                              <span className="text-emerald-400 font-medium">
                                +{message.points} points
                              </span>
                            )}
                          </div>
                          <p className="text-white/90 mb-2">{message.content}</p>
                          <div className="text-sm text-white/60">
                            {message.timestamp?.toLocaleDateString(
                              userLanguage === 'en' ? 'en-US' : 'fr-FR',
                              {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="bg-[#1a2437]/60 rounded-xl border border-white/10 backdrop-blur-sm p-6">
            <LevelsList teamData={teamData} t={t} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
