import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { 
  collection, 
  addDoc,
  serverTimestamp, 
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const ADMIN_UID = 'TvkedO2qXOag5RSiWZifi7PuEW23';

const AdminMessages = () => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [message, setMessage] = useState('');
  const [points, setPoints] = useState('');
  const [type, setType] = useState('info');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Vérifier l'admin et charger les équipes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate('/');
        return;
      }

      try {
        // Vérifier le statut admin
        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        if (!adminDoc.exists() && user.uid !== ADMIN_UID) {
          navigate('/');
          return;
        }
        
        setIsAdmin(true);

        // Charger toutes les équipes
        const teamsSnapshot = await getDocs(collection(db, 'teams'));
        const teamsData = teamsSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setTeams(teamsData);
      } catch (error) {
        console.error('Erreur chargement équipes:', error);
        setError('Erreur lors du chargement des équipes');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Charger les messages quand une équipe est sélectionnée
  useEffect(() => {
    if (!selectedTeam) return;

    const messagesRef = collection(db, 'teams', selectedTeam, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setMessageHistory(messages);
    });

    return () => unsubscribe();
  }, [selectedTeam]);

  // Réinitialiser le formulaire
  const resetForm = () => {
    setMessage('');
    setPoints('');
    setType('info');
    setEditingMessage(null);
    setError('');
    setSuccess('');
  };

  // Mettre à jour les points d'une équipe
  const updateTeamPoints = async (teamId, pointsChange) => {
    const teamRef = doc(db, 'teams', teamId);
    const teamDoc = await getDoc(teamRef);
    
    if (!teamDoc.exists()) {
      throw new Error("L'équipe n'existe pas");
    }

    const currentPoints = teamDoc.data().points || 0;
    const currentBonusPoints = teamDoc.data().bonusPoints || 0;

    await updateDoc(teamRef, {
      bonusPoints: currentBonusPoints + pointsChange,
      points: currentPoints + pointsChange
    });
  };

  // Envoyer ou modifier un message
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading || !isAdmin) return;
    
    if (!selectedTeam || !message) {
      setError('Veuillez remplir tous les champs obligatoires');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const messageData = {
        content: message,
        timestamp: serverTimestamp(),
        type: type,
        adminId: auth.currentUser.uid,
      };

      if (points) {
        const numPoints = Number(points);
        if (isNaN(numPoints)) {
          throw new Error('Le nombre de points est invalide');
        }
        messageData.points = numPoints;

        // Mettre à jour les points de l'équipe
        await updateTeamPoints(selectedTeam, numPoints);
      }

      if (editingMessage) {
        // Si on modifie un message avec des points, on doit d'abord annuler les anciens points
        if (editingMessage.points) {
          await updateTeamPoints(selectedTeam, -editingMessage.points);
        }
        
        // Puis ajouter les nouveaux points si nécessaire
        if (points) {
          await updateTeamPoints(selectedTeam, Number(points));
        }

        const messageRef = doc(db, 'teams', selectedTeam, 'messages', editingMessage.id);
        await updateDoc(messageRef, messageData);
        setSuccess('Message modifié avec succès !');
      } else {
        await addDoc(collection(db, 'teams', selectedTeam, 'messages'), messageData);
        setSuccess('Message envoyé avec succès !');
      }

      resetForm();
      
    } catch (error) {
      console.error('Erreur:', error);
      setError(`Erreur: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Supprimer un message
  const handleDelete = async (messageId) => {
    if (!isAdmin || !window.confirm('Êtes-vous sûr de vouloir supprimer ce message ?')) return;

    try {
      const messageRef = doc(db, 'teams', selectedTeam, 'messages', messageId);
      const messageDoc = await getDoc(messageRef);
      
      if (!messageDoc.exists()) {
        throw new Error("Le message n'existe plus");
      }

      const messageData = messageDoc.data();
      
      // Si le message avait des points, les retirer
      if (messageData.points) {
        await updateTeamPoints(selectedTeam, -messageData.points);
      }

      await deleteDoc(messageRef);
      setSuccess('Message supprimé avec succès !');
      
    } catch (error) {
      console.error('Erreur suppression:', error);
      setError(`Erreur lors de la suppression: ${error.message}`);
    }
  };

  // Éditer un message
  const handleEdit = (msg) => {
    if (!isAdmin) return;
    setEditingMessage(msg);
    setMessage(msg.content);
    setType(msg.type);
    setPoints(msg.points?.toString() || '');
    setError('');
    setSuccess('');
  };

  if (!isAdmin) {
    return <div className="text-center p-6">Accès non autorisé</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
        Messages aux équipes
      </h2>
      
      <form onSubmit={handleSubmit} className="space-y-6 mb-8">
        {/* Sélection de l'équipe */}
        <div>
          <label className="block mb-2 text-white/80">Équipe</label>
          <select 
            value={selectedTeam}
            onChange={(e) => {
              setSelectedTeam(e.target.value);
              resetForm();
            }}
            className="w-full p-3 rounded-lg bg-[#1a2437] text-white border border-white/20 
                     focus:outline-none focus:border-blue-400"
            required
            disabled={loading}
          >
            <option value="">Sélectionner une équipe</option>
            {teams.map(team => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </div>

        {/* Type de message */}
        <div>
          <label className="block mb-2 text-white/80">Type de message</label>
          <select 
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full p-3 rounded-lg bg-[#1a2437] text-white border border-white/20 
                     focus:outline-none focus:border-blue-400"
            disabled={loading}
          >
            <option value="info">Information</option>
            <option value="bonus">Bonus</option>
            <option value="achievement">Réussite</option>
          </select>
        </div>

        {/* Points bonus */}
        <div>
          <label className="block mb-2 text-white/80">
            Points bonus (optionnel)
          </label>
          <input 
            type="number"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
            className="w-full p-3 rounded-lg bg-[#1a2437] text-white border border-white/20 
                     focus:outline-none focus:border-blue-400"
            placeholder="Nombre de points bonus"
            disabled={loading}
          />
        </div>

        {/* Message */}
        <div>
          <label className="block mb-2 text-white/80">Message</label>
          <textarea 
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-3 rounded-lg bg-[#1a2437] text-white border border-white/20 
                     focus:outline-none focus:border-blue-400"
            rows="4"
            required
            placeholder="Votre message..."
            disabled={loading}
          />
        </div>

        {/* Messages d'erreur et succès */}
        {error && (
          <div className="p-4 rounded-lg bg-red-500/20 text-red-300 border border-red-500/20">
            {error}
          </div>
        )}

        {success && (
          <div className="p-4 rounded-lg bg-emerald-500/20 text-emerald-300 border border-emerald-500/20">
            {success}
          </div>
        )}

        {/* Boutons */}
        <div className="flex gap-4">
          <button 
            type="submit"
            disabled={loading}
            className="flex-1 bg-blue-400 text-white py-4 px-6 rounded-lg 
                     hover:bg-emerald-400 transition-all duration-300 
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Envoi en cours...' : (editingMessage ? 'Modifier le message' : 'Envoyer le message')}
          </button>

          {editingMessage && (
            <button
              type="button"
              onClick={resetForm}
              className="px-6 py-4 bg-gray-500 text-white rounded-lg 
                       hover:bg-gray-600 transition-all duration-300"
              disabled={loading}
            >
              Annuler
            </button>
          )}
        </div>
      </form>

      {/* Historique des messages */}
      {selectedTeam && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4 text-white">
            Historique des messages
            {messageHistory.length > 0 && (
              <span className="text-white/60 text-base font-normal ml-2">
                ({messageHistory.length} message{messageHistory.length > 1 ? 's' : ''})
              </span>
            )}
          </h3>
          
          {messageHistory.length > 0 ? (
            <div className="space-y-4">
              {messageHistory.map((msg) => (
                <div 
                  key={msg.id}
                  className="p-4 rounded-lg border border-white/10 bg-[#1a2437]/60 hover:bg-[#1a2437]/80 transition-all duration-300"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div className="flex items-center gap-2">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        msg.type === 'info' ? 'bg-blue-400/20 text-blue-400' :
                        msg.type === 'bonus' ? 'bg-emerald-400/20 text-emerald-400' :
                        'bg-yellow-400/20 text-yellow-400'
                      }`}>
                        {msg.type}
                      </span>
                      {msg.points && (
                        <span className="text-emerald-400 font-medium">
                          +{msg.points} points
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleEdit(msg)}
                        className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                      >
                        Modifier
                      </button>
                      <button
                        onClick={() => handleDelete(msg.id)}
                        className="text-red-400 hover:text-red-300 transition-colors duration-200"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <p className="text-white/90 whitespace-pre-wrap">{msg.content}</p>
                  <div className="mt-2 text-sm text-white/60">
                    Envoyé le {msg.timestamp?.toLocaleDateString()} à {msg.timestamp?.toLocaleTimeString()}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-white/60">
              Aucun message pour cette équipe
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminMessages;