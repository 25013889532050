// Level3.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase.jsx';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from 'firebase/firestore';
import Confetti from 'react-confetti';
import ReactDOM from 'react-dom';

const Level3 = () => {
  const navigate = useNavigate();
  const [passwordInput, setPasswordInput] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [language, setLanguage] = useState('fr'); // Par défaut en français
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const correctPassword = 'losaventureros';
  const pointsToAdd = 500;
  const redirectUrl = '/niveau/7';
  const currentLevel = 'level6';

  const translations = {
    fr: {
      continueButton: 'Continuer',
      levelNumber: 'NIVEAU 006',
      missionTitle: 'Mission Recherche',
      prompt: `Bacalar Excursion : Investigation
Sur le site Web de Adorable Sailing, quel est le nom de l'excursion qui dure 5 heures ?`,
      placeholder: 'Lettres minuscules et sans espace',
      validateButton: 'Valider',
      correctPassword: 'losaventureros',
      incorrectAnswer: 'Réponse incorrecte. Veuillez réessayer.',
      successMessage:
        'Félicitations ! Vous avez réussi et votre équipe gagne 500 points supplémentaires. Cliquez sur Continuer pour passer au niveau suivant.',
      alreadyCompletedMessage:
        'Vous avez déjà complété ce niveau. Les points ne seront pas ajoutés à nouveau. Cliquez sur Continuer pour passer au niveau suivant.',
      userNotFound: "Aucun document d'équipe trouvé pour l'utilisateur.",
      errorOccurred: 'Une erreur est survenue. Veuillez réessayer.',
      userNotLoggedIn: 'Utilisateur non connecté.',
      replayButton: 'Réessayer',
    },
    en: {
      continueButton: 'Continue',
      levelNumber: 'LEVEL 006',
      missionTitle: 'Search Mission',
      prompt: `Bacalar Excursion: Investigation
      On the Adorable Sailing website, what is the name of the excursion that lasts 5 hours?`,
      placeholder: 'Lowercase letters and no spaces',
      
      validateButton: 'Submit',
      correctPassword: 'losaventureros',
      incorrectAnswer: 'Incorrect answer. Please try again.',
      successMessage:
        'Congratulations! You succeeded and your team earns an additional 500 points. Click Continue to move to the next level.',
      alreadyCompletedMessage:
        'You have already completed this level. Points will not be added again. Click Continue to move to the next level.',
      userNotFound: 'No team document found for the user.',
      errorOccurred: 'An error occurred. Please try again.',
      userNotLoggedIn: 'User not logged in.',
      replayButton: 'Retry',
    },
    es: {
      continueButton: 'Continuar',
      levelNumber: 'NIVEL 006',
      missionTitle: 'Misión de Búsqueda',
      prompt: `Excursión en Bacalar: Investigación
      En el sitio web de Adorable Sailing, ¿cuál es el nombre de la excursión que dura 5 horas?`,
      placeholder: 'Letras minúsculas y sin espacios',
      
      validateButton: 'Enviar',
      correctPassword: 'losaventureros',
      incorrectAnswer: 'Respuesta incorrecta. Por favor, inténtalo de nuevo.',
      successMessage:
        '¡Felicidades! Has tenido éxito y tu equipo gana 500 puntos adicionales. Haz clic en Continuar para pasar al siguiente nivel.',
      alreadyCompletedMessage:
        'Ya has completado este nivel. Los puntos no se agregarán de nuevo. Haz clic en Continuar para pasar al siguiente nivel.',
      userNotFound: 'No se encontró ningún documento de equipo para el usuario.',
      errorOccurred: 'Ocurrió un error. Por favor, inténtalo de nuevo.',
      userNotLoggedIn: 'Usuario no conectado.',
      replayButton: 'Reintentar',
    },
  };

  // Initialisation de l'authentification et de la langue
  useEffect(() => {
    const handleAuth = async (user) => {
      if (user) {
        setCurrentUser(user);
        const userId = user.uid;
        const teamsCollection = collection(db, 'teams');
        const q = query(teamsCollection, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const teamDoc = querySnapshot.docs[0];
          const teamData = teamDoc.data();
          setLanguage(teamData.language || 'fr');
        } else {
          setLanguage('fr');
        }
      } else {
        setCurrentUser(null);
        setLanguage('fr'); // Langue par défaut si utilisateur non connecté
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuth);
    return () => unsubscribe();
  }, []);

  const submitForm = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (passwordInput === translations[language].correctPassword) {
      if (currentUser) {
        try {
          const userId = currentUser.uid;
          const teamsCollection = collection(db, 'teams');
          const q = query(teamsCollection, where('userId', '==', userId));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const teamDoc = querySnapshot.docs[0];
            const teamData = teamDoc.data();

            if (teamData.usedLevels && teamData.usedLevels.includes(currentLevel)) {
              setErrorMessage(translations[language].alreadyCompletedMessage);
              setShowContinueButton(true); // Affiche le bouton même si déjà complété
            } else {
              let updatedPoints = teamData.points || 0;
              updatedPoints += pointsToAdd;

              const teamDocRef = doc(db, 'teams', teamDoc.id);

              await updateDoc(teamDocRef, {
                points: updatedPoints,
                usedLevels: arrayUnion(currentLevel),
              });

              setSuccessMessage(translations[language].successMessage);
              setShowContinueButton(true); // Affiche le bouton après succès

              // Afficher les confettis
              setShowConfetti(true);
              setTimeout(() => {
                setShowConfetti(false); // Arrêter les confettis après 5 secondes
              }, 5000);
            }
          } else {
            setErrorMessage(translations[language].userNotFound);
          }
        } catch (error) {
          console.error('Erreur lors de la mise à jour des points:', error);
          setErrorMessage(translations[language].errorOccurred);
        }
      } else {
        setErrorMessage(translations[language].userNotLoggedIn);
      }
    } else {
      setErrorMessage(translations[language].incorrectAnswer);
    }

    setIsSubmitting(false);
  };

  const handleInputChange = (e) => {
    setPasswordInput(e.target.value);
    setErrorMessage('');
    setSuccessMessage('');
    setShowContinueButton(false); // Réinitialise l'affichage du bouton
  };

  const handleContinue = () => {
    navigate(redirectUrl); // Navigue vers le niveau suivant
  };

  return (
    <div className="min-h-screen p-6 text-white relative">
      {showConfetti && (
  <div className="fixed inset-0" style={{ zIndex: 50 }}>
    <Confetti
      width={window.innerWidth}
      height={window.innerHeight}
      recycle={false}
      numberOfPieces={200}
    />
  </div>
)}

      <div className="relative">
        <h1 className="text-8xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
          {translations[language].levelNumber}
        </h1>
        <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
      </div>

      <h2 className="text-2xl font-bold text-center mb-12 relative">
        <span className="bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
          {translations[language].missionTitle}
        </span>
        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-full"></div>
      </h2>

     

      <div className="max-w-md mx-auto">
        <div className="mb-8 text-center backdrop-blur-sm bg-white/5 p-6 rounded-xl">
          {translations[language].prompt.split('\n').map((line, index) => (
            <p
              key={index}
              className={`${index === 0 ? 'font-semibold text-lg mb-4' : 'text-white/90'}`}
            >
              {line}
            </p>
          ))}
        </div>

        <div className="backdrop-blur-sm bg-[#0e1d35]/40 p-8 rounded-xl shadow-xl border border-white/10">
          <input
            type="text"
            id="password"
            className="w-full p-4 rounded-lg bg-[#1a2437]/60 text-white mb-6 border border-white/10 focus:border-emerald-400/50 focus:ring-2 focus:ring-emerald-400/20 transition-all duration-300 placeholder-white/50"
            placeholder={translations[language].placeholder}
            value={passwordInput}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <button
            onClick={submitForm}
            disabled={isSubmitting}
            className="w-full py-4 bg-blue-400 text-white rounded-lg hover:bg-blue-500 transition-all duration-300 ease-in-out border border-white/10 backdrop-blur-sm transform hover:scale-[1.02] hover:shadow-lg active:scale-[0.98] disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {translations[language].validateButton}
          </button>

          {errorMessage && (
            <div className="mt-4 p-4 rounded-lg bg-red-500/20 text-red-300 backdrop-blur-sm border border-red-500/20">
              {errorMessage}
            </div>
          )}

          {successMessage && (
            <div className="mt-4 p-4 rounded-lg bg-green-500/20 text-green-300 backdrop-blur-sm border border-green-500/20">
              {successMessage}
            </div>
          )}

          {showContinueButton && (
            <button
              onClick={handleContinue}
              className="mt-6 w-full py-4 bg-emerald-400 text-white rounded-lg hover:bg-emerald-500 transition-all duration-300 ease-in-out border border-white/10 backdrop-blur-sm transform hover:scale-[1.02] hover:shadow-lg active:scale-[0.98]"
            >
              {translations[language].continueButton}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Level3;
