import React, { useState, useEffect } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';
import TeamCreation from '../components/TeamCreation';
import CheckoutForm from '../components/CheckoutForm';

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const db = getFirestore();

  // États
  const [currentStep, setCurrentStep] = useState('auth');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Vérification de l'état initial
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setEmail(user.email);
        
        try {
          const teamRef = doc(db, 'teams', user.uid);
          const teamDoc = await getDoc(teamRef);
          
          if (teamDoc.exists()) {
            const team = teamDoc.data();
            
            if (team.paymentStatus === 'pending') {
              setTeamData(team);
              setCurrentStep('payment');
            } else if (team.paymentStatus === 'completed') {
              navigate('/tableaubord');
            } else {
              setCurrentStep('team');
            }
          } else {
            setCurrentStep('team');
          }
        } catch (error) {
          console.error('Erreur vérification équipe:', error);
          setError(t('auth.verificationError'));
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate, db, t]);

  // Gestion du retour de paiement Stripe
  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (sessionId && auth.currentUser && teamData) {
      handlePaymentVerification(sessionId);
    }
  }, [searchParams, teamData]);

  // Connexion Google
  const handleGoogleSignup = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setEmail(result.user.email);
      setCurrentStep('team');
    } catch (error) {
      setError(t('auth.googleError'));
      console.error('Erreur Google:', error);
    } finally {
      setLoading(false);
    }
  };

  // Inscription email/password
  const handleEmailSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      setCurrentStep('team');
    } catch (error) {
      setError(t('auth.emailError'));
      console.error('Erreur email:', error);
    } finally {
      setLoading(false);
    }
  };

  // Création de l'équipe
  const handleTeamCreation = async (formData) => {
    setLoading(true);
    try {
      const userId = auth.currentUser.uid;
      const teamRef = doc(db, 'teams', userId);

      const team = {
        ...formData,
        captainEmail: email,
        createdAt: new Date(),
        currentLevel: 1,
        currentStage: 'Virtuel',
        usedLevels: [],
        bonusPoints: 0,
        points: 0,
        emailVerified: auth.currentUser.emailVerified,
        userId: userId,
        paymentStatus: 'pending'
      };

      await setDoc(teamRef, team);
      setTeamData(team);
      setCurrentStep('payment');
    } catch (error) {
      setError(t('teamCreation.errorSaving'));
      console.error('Erreur création équipe:', error);
    } finally {
      setLoading(false);
    }
  };

  // Vérification du paiement
  const handlePaymentVerification = async (sessionId) => {
    setLoading(true);
    try {
      const response = await fetch("https://us-central1-rallye-bacalar.cloudfunctions.net/verifyPayment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionId: sessionId.split('?')[0],
          userId: auth.currentUser.uid
        }),
      });

      const data = await response.json();
      
      if (data.success) {
        await updateDoc(doc(db, 'teams', auth.currentUser.uid), {
          paymentStatus: 'completed',
          paymentDate: new Date()
        });
        navigate('/tableaubord');
      } else {
        setError(t('payment.verificationFailed'));
        setCurrentStep('payment');
      }
    } catch (error) {
      setError(t('payment.verificationError'));
      console.error('Erreur vérification:', error);
    } finally {
      setLoading(false);
    }
  };

  // Rendu des étapes
  const renderCurrentStep = () => {
    if (loading) {
      return (
        <div className="text-center py-8 mt-8 md:mt-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
        </div>
      );
    }

    switch (currentStep) {
      case 'auth':
        return (
          <div className="space-y-6">
            <button
              onClick={handleGoogleSignup}
              className="w-full py-4 bg-white text-gray-800 rounded-lg hover:bg-gray-100 transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-center gap-2"
              disabled={loading}
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                />
                <path
                  fill="currentColor"
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                />
                <path
                  fill="currentColor"
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                />
                <path
                  fill="currentColor"
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                />
              </svg>
              {t('auth.login.googleButton')}
            </button>

            <div className="relative text-center">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-white/10"></div>
              </div>
              <div className="relative">
                <span className="px-4 text-white/60 bg-[#1a2437]">{t('auth.login.or')}</span>
              </div>
            </div>

            <form onSubmit={handleEmailSignup} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-white/80 mb-2">{t('auth.login.emailPlaceholder')}</label>
                <input
                  id="email"
                  type="email"
                  placeholder={t('auth.login.emailPlaceholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-3 rounded-lg bg-[#0e1d35] text-white border border-white/20 focus:outline-none focus:border-blue-400 placeholder-white/50"
                  disabled={loading}
                />
                {!email && <span className="text-red-400 text-sm mt-1">{t('auth.login.emailRequired')}</span>}
              </div>

              <div>
                <label htmlFor="password" className="block text-white/80 mb-2">{t('auth.login.passwordLabel')}</label>
                <input
                  id="password"
                  type="password"
                  placeholder={t('auth.login.passwordPlaceholder')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-4 rounded-lg bg-[#0e1d35]/60 text-white border border-white/10 focus:border-emerald-400/50 focus:ring-2 focus:ring-emerald-400/20"
                  disabled={loading}
                />
                {!password && <span className="text-red-400 text-sm mt-1">{t('auth.login.passwordRequired')}</span>}
              </div>

              <div className="flex items-center text-white/80">
                <input type="checkbox" className="mr-2 rounded bg-[#0e1d35]/60 border-white/10" />
                {t('auth.login.rememberMe')}
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-emerald-400 text-white py-4 px-6 rounded-lg hover:bg-emerald-500 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed border border-white/10 backdrop-blur-sm transform hover:scale-[1.02]"
              >
                {t('auth.emailSignup')}
              </button>

              <div className="text-right">
                <a href="/mot-de-passe-oublie" className="text-emerald-400 hover:text-emerald-300 text-sm">
                  {t('auth.login.forgotPassword')}
                </a>
              </div>
            </form>
          </div>
        );

      case 'team':
        return (
          <>
            <div className="mb-6 bg-[#1a2437]/60 border border-white/10 text-white/80 px-6 py-4 rounded-lg backdrop-blur-sm">
              <p className="text-center">{t('auth.login.welcomeBack', { email })}</p>
              <p className="text-center text-sm mt-1">{t('auth.login.continueRegistration')}</p>
            </div>
            <TeamCreation onSubmit={handleTeamCreation} email={email} />
          </>
        );

      case 'payment':
        return <CheckoutForm email={email} teamData={teamData} />;

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen p-6 text-white">
      <div className="relative">
        <h1 className="text-3xl font-black text-center mb-2 mt-8 md:mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400 opacity-80">
          {t('register.title')}
        </h1>
        <div className="absolute -top-4 -left-4 right-0 bottom-0 bg-gradient-to-r from-blue-500 to-emerald-500 opacity-10 blur-xl -z-10 rounded-full"></div>
      </div>

      <div className="max-w-2xl mx-auto mt-12">
        <div className="backdrop-blur-sm bg-[#1a2437]/60 p-8 rounded-xl border border-white/10">
          {error && (
            <div className="mb-6 p-4 rounded-lg bg-red-500/20 text-red-300 backdrop-blur-sm border border-red-500/20">
              {error}
            </div>
          )}
          {renderCurrentStep()}
        </div>
      </div>
    </div>
  );
};

export default Register;